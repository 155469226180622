/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import BaseObservable from "../observer/BaseObservable";
import Organization from "./Organization";
import PolicyGroup from "./PolicyGroup";
import AuthWrapper from "../auth/authWrapper";

export interface UserParameters {
  id: string;
  username: string;
  policies: string[];
  homeOrganization: string | Organization;
}

export interface UserObserver {
  onOrganizationsChange?: (organizations: Organization[], user: User) => void;

  onPolicyGroupsChange?: (groups: PolicyGroup[], user: User) => void;

  onDelete?: (user: User) => void;
}

export default abstract class User extends BaseObservable<UserObserver> {
  protected readonly id: string;
  protected readonly homeOrganizationId: string;
  protected readonly policies: string[];
  protected username: string;
  protected homeOrganization?: Organization;

  protected constructor(parameters: UserParameters) {
    super();
    this.id = parameters.id;
    this.username = parameters.username;
    this.policies = parameters.policies;

    if (typeof parameters.homeOrganization === "string") {
      this.homeOrganizationId = parameters.homeOrganization;
    } else {
      this.homeOrganizationId = parameters.homeOrganization.getId();
      this.homeOrganization = parameters.homeOrganization;
    }
  }

  public getId(): string {
    return this.id;
  }

  public getUsername(): string {
    return this.username;
  }

  public getHomeOrganizationId(): string {
    return this.homeOrganizationId;
  }

  public getPolicies(): string[] {
    return this.policies;
  }

  public async isCurrentUser(): Promise<boolean> {
    const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();
    return this.id === claims?.userId;
  }

  public abstract getHomeOrganization(): Promise<Organization>;

  public abstract getOrganizations(): Promise<Organization[]>;

  public abstract hasGrants(organizationId: string, ...actions: string[]): Promise<boolean>;

  public abstract hasPolicyGroupNamed(policyGroupName: string, organizationId?: string): Promise<boolean>;

  public abstract belongsToPolicyGroup(policyGroupId: string): Promise<boolean>;

  public abstract getPolicyGroups(organizationId?: string): Promise<PolicyGroup[]>;

  public abstract delete(): Promise<void>;
  
  public static alphabeticUserOrdering(a: User, b: User): number {
    return a.getUsername().localeCompare(b.getUsername());
  }
}
