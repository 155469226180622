/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import HyperIcon from "../../../assets/router-24px.svg";
import { ReferenceHW } from "../ReferenceHW/ReferenceHW";
import { HyperHWState } from "./HyperHWState";
import { HyperHWStateProperties } from "./HyperHWStateProperties";
import { Attribute } from "../../../data/device/Attribute";
import { HyperHWData } from "./HyperHWData";
import { SyntheticDataGenerator } from "../../../data/data/DataHolder";
import { Maybe } from "../../../types/aliases";
import { linearRegression, linearRegressionLine, standardDeviation } from "simple-statistics";
import { Data } from "../../../data/data/Data";
import { isDefined } from "../../../utils/types";

// TODO: this is for testing, REMOVE LATER
const FAKE_ATTRIBUTES: Record<string, number> = {
  maxFatigue: 10,
  // half a year ago
  installationDate: Date.now() - 180 * 24 * 60 * 60 * 1000,
  // year
  maxAgeHours: 356 * 24,
};

type DataMapper = (d: Data | Partial<HyperHWData>, i: number) => Partial<HyperHWData>;
type FatigueData = Array<Data & {fatigue?: number}>;
interface FatigueGrowthStatistics {
  getEstimatedFatigue: (time: number) => number;
  fatigueStandardDeviation: number;
}

export class HyperHW extends ReferenceHW<HyperHWData, HyperHWState> {
  public static type = "Hyper";

  public constructor(deviceId: string, attributes?: Attribute[]) {
    super({
      deviceId,
      attributes,
      type: HyperHW.type,
    });
  }

  public getIcon(): string {
    return HyperIcon;
  }

  public createState(timestamp?: number, reported?: Partial<HyperHWStateProperties>, desired?: Partial<HyperHWStateProperties>):
  HyperHWState {
    return new HyperHWState(this.getId(), new HyperHWStateProperties(reported ?? {}), new HyperHWStateProperties(desired ?? {}), timestamp);
  }

  protected async createSyntheticDataGenerator(): Promise<Maybe<SyntheticDataGenerator<HyperHWData>>> {
    const maxFatigue = this.getNumericAttribute("maxFatigue");
    const installationTime = this.getNumericAttribute("installationDate");
    const maxAge = this.getNumericAttribute("maxAgeHours") * 60 * 60 * 1000;
    
    if (!maxFatigue || !installationTime || !maxAge) {
      console.error(`Device '${this.getId()}' is missing critical attributes: ${{ maxFatigue, installationTime, maxAge }}`);
      return;
    }

    return (data: FatigueData, sampleRange): HyperHWData[] => {
      const startTime = Date.now();
      const newData: Partial<HyperHWData>[] = data.map(datum => ({
        ...datum,
        maxFatigue,
        plannedFatigue: this.getPlannedFatigue(datum.timestamp),
      }));

      // TODO: this is for testing - remove when actual fatigue data is provided
      for (let i = 0; i < newData.length / 2; i++) {
        const d = newData[i];
        d.fatigue = this.getPlannedFatigue(d.timestamp!) * (1 - (Math.random() - 0.5) / 10000);
      }

      const fatigueGrowthStatistics = HyperHW.getFatigueGrowthStatistics(newData);
      this.updateFatiguelessDataWithProjections(newData, fatigueGrowthStatistics);

      const projectionData = this.addProjectedDataPoints(data, sampleRange.end + 100000, fatigueGrowthStatistics);
      newData.push(...projectionData);
      console.log("Time taken", Date.now() - startTime);
      return newData as HyperHWData[];
    };
  }

  private updateFatiguelessDataWithProjections(newData: Partial<HyperHWData>[], fatigueGrowthStatistics?: FatigueGrowthStatistics): void {
    const lastFatiguedPointIndexReverse = [...newData].reverse().findIndex(datum => datum.fatigue);

    if (lastFatiguedPointIndexReverse > 0 && fatigueGrowthStatistics) {
      const lastFatiguedPointIndex = newData.length - lastFatiguedPointIndexReverse - 1;
      const mapper = this.createProjectionMapper(fatigueGrowthStatistics);
      const newElements = newData.slice(lastFatiguedPointIndex).map(mapper);
      newData.splice(lastFatiguedPointIndex, newElements.length, ...newElements);
    }
  }

  private addProjectedDataPoints(data: FatigueData, timeRangeEnd: number, fatigueGrowthStatistics?: FatigueGrowthStatistics): Partial<HyperHWData>[] {
    if (!fatigueGrowthStatistics) return [];
    const lastTimestamp = data[data.length - 1].timestamp;
    const sampleDeltaTime = Math.ceil((lastTimestamp - data[0].timestamp) / data.length);
    const timeLeftInTimeWindow = timeRangeEnd - lastTimestamp;

    if (timeLeftInTimeWindow < sampleDeltaTime * 2) {
      return [];
    }
    const mapper = this.createProjectionMapper(fatigueGrowthStatistics, {
      startTimestamp: lastTimestamp,
      sampleDeltaTime,
    });
    const dataPointCount = Math.floor(timeLeftInTimeWindow / sampleDeltaTime);
    return new Array(dataPointCount)
      .fill(data[data.length - 1])
      .map(mapper);
  }

  private createProjectionMapper(fatigueGrowthStatistics: FatigueGrowthStatistics, sampleTiming?: {startTimestamp: number; sampleDeltaTime: number}): DataMapper {
    const { getEstimatedFatigue, fatigueStandardDeviation } = fatigueGrowthStatistics;

    return (dataPoint, i): Partial<HyperHWData> => {
      const timestamp = sampleTiming ? sampleTiming.startTimestamp + sampleTiming.sampleDeltaTime * (i + 1) : dataPoint.timestamp!;
      const projectedFatigue = getEstimatedFatigue(timestamp);
      return {
        ...dataPoint,
        fatigue: undefined,
        timestamp,
        projectedFatigue,
        plannedFatigue: this.getPlannedFatigue(timestamp),
        maxProjectedFatigue: projectedFatigue + fatigueStandardDeviation,
        minProjectedFatigue: projectedFatigue - fatigueStandardDeviation,
      };
    };
  }

  private getPlannedFatigue(timestamp: number): number {
    const maxFatigue = this.getNumericAttribute("maxFatigue");
    const installationTime = this.getNumericAttribute("installationDate");
    const maxAge = this.getNumericAttribute("maxAgeHours") * 60 * 60 * 1000;
    return (timestamp - installationTime) * (maxFatigue / maxAge);
  }

  private getNumericAttribute(name: string): number {
    const value = this.getAttribute(name);

    if (value) {
      const parsed = Number(value);
      return Number.isNaN(parsed) ? 0 : parsed;
    }
    // return 0;
    return FAKE_ATTRIBUTES[name] ?? 0;
  }

  private static getFatigueGrowthStatistics(data: Partial<HyperHWData>[]): Maybe<FatigueGrowthStatistics> {
    const dataWithFatigue = data.filter(datum => isDefined(datum.fatigue)) as Array<Data & {fatigue: number}>;
    if (dataWithFatigue.length < 2) return;
    const fatigueLinearRegression = linearRegression(dataWithFatigue.map(datum => [datum.timestamp, datum.fatigue]));
    const getEstimatedFatigue = linearRegressionLine(fatigueLinearRegression);
    // since the fatigue is a growing value and not an entirely bounded statistical phenomenon, will use the distance
    // of the fatigue value from the estimated linear regression for calculating standardDeviation
    // otherwise the standard deviation will be affected by the growth rate of the fatigue
    const fatigueStandardDeviation = standardDeviation(dataWithFatigue.map(datum => getEstimatedFatigue(datum.timestamp) - datum.fatigue));
    return {
      getEstimatedFatigue: linearRegressionLine(fatigueLinearRegression),
      fatigueStandardDeviation,
    };
  }
}
