/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { Button, Dialog, List, Typography } from "@material-ui/core";
import React, { Component } from "react";

const ACCEPT_TEXT = "Accept";
const CANCEL_TEXT = "Cancel";

interface Props {
  title: string;
  open: boolean;
  onAccept: () => void;
  onCancel: () => void;
  disableAccept?: false | boolean;
  acceptButtonText?: typeof ACCEPT_TEXT | string;
  cancelButtonText?: typeof CANCEL_TEXT | string;
}

/**
 * Simple "form" style dialog, where
 * <FormDialog>
 *   <ChildComponent1 />
 *   <ChildComponent2 />
 * </FormDialog>
 *
 * are placed within a <List>
 */
export default class FormDialog extends Component<Props> {

  public constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <Dialog
        onClose={this.props.onCancel}
        open={this.props.open}
      >
        <div className="popup-inner">
          <Typography variant="h6">{this.props.title}</Typography>
          <div className="popup-body">
            <List style={{ margin: "2rem" }}>
              {this.props.children}
              <div className="buttons-container">
                <Button
                  variant="contained"
                  color="primary"
                  className="button left"
                  onClick={this.props.onAccept}
                  disabled={this.props.disableAccept ?? false}
                >
                  {this.props.acceptButtonText ?? ACCEPT_TEXT}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="button"
                  onClick={this.props.onCancel}
                >
                  {this.props.cancelButtonText ?? CANCEL_TEXT}
                </Button>
              </div>
            </List>
          </div>
        </div>
      </Dialog>
    );
  }
}
