/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { RouteComponentPropsParams } from "../../types/routerprops";
import Device from "../../data/device/Device";
import DeviceGroup from "../../data/device/DeviceGroup";
import SearchBar from "../ui/search-bar";
import ArrowBack from "../../assets/arrow-back-24px.svg";
import ArrowForward from "../../assets/arrow-forward-24px.svg";
import ClientProperties from "../../data/clientSpecific/ClientProperties";
import { getDisplayName } from "../../data/utils/utils";
import DeviceList from "../device-browsing/device-list";
import { Maybe } from "../../types/aliases";
import ResourceSelector, { ResourceSelectorObserver } from "../../state/ResourceSelector";
import DeviceTree from "../device-browsing/device-tree";
import { getCaseInsensitiveSearchFilter } from "../device-browsing/helpers/search-filter";
import { isEmpty } from "../../utils/validation";

interface Props extends RouteComponentProps<RouteComponentPropsParams> {
  groups?: DeviceGroup[];
}

interface State {
  searchFilter: string;
  drawerOpen: boolean;
  selectedDevice?: Device;
}

class DeviceDrawer extends Component<Props, State> implements ResourceSelectorObserver {

  public constructor(props: Props) {
    super(props);
    this.state = {
      searchFilter: "",
      drawerOpen: false,
    };
  }

  public onSelectedDeviceChanged(device?: Device): void {
    this.setState({ selectedDevice: device });
  }

  public componentDidMount(): void {
    ResourceSelector.getInstance().addObserver(this);

    if (!this.props.match.params.id) {
      this.toggleDrawer();
    }
  }

  public async componentDidUpdate(prevProps: Props): Promise<void> {
    if (prevProps.match.params.id !== this.props.match.params.id || isEmpty(this.props.match.params)) {
      await ResourceSelector.getInstance().setCurrentDevice(this.props.match.params.id);
    }

    if (this.props.match.params.id && !this.state.selectedDevice) {
      await ResourceSelector.getInstance().setCurrentDevice(this.props.match.params.id);
    }
  }

  public componentWillUnmount(): void {
    ResourceSelector.getInstance().removeObserver(this);
  }

  private getDisplayName = (): Maybe<string> => {
    if (this.state.selectedDevice) {
      return getDisplayName(this.state.selectedDevice);
    }
  };

  private toggleDrawer = (): void => {
    this.setState((prevState: State) => ({
      drawerOpen: !prevState.drawerOpen,
    }));
  };

  private onSearchTriggered(searchString: string): void {
    console.log("onSearchTriggered: " + searchString);
    this.setState({ searchFilter: searchString });
  }

  private renderListBrowserMode(): JSX.Element {
    return (
      <DeviceList
        searchFilter={this.state.searchFilter}
      />
    );
  }

  private renderTreeBrowserMode(): JSX.Element {
    return (
      <DeviceTree
        groups={this.props.groups}
        searchFilter={getCaseInsensitiveSearchFilter(this.state.searchFilter)}
        editMode={false}
      />
    );
  }

  public render(): JSX.Element {
    return (
      <div className="iot-device-list-container col-sm-3 col-xsm-5">
        <Button onClick={this.toggleDrawer}>
          <img src={ArrowForward} alt="forward arrow" />
        </Button>
        <span>{this.getDisplayName()}</span>
        <Drawer
          open={this.state.drawerOpen}
          classes={{ paper: "col-xlg-3 col-sm-4 col-xsm-9 iot-drawer" }}
          onClose={this.toggleDrawer}
        >
          <div className="iot-drawer-list">
            <div className="iot-drawer-list-header">
              <Button onClick={this.toggleDrawer}>
                <img src={ArrowBack} alt="back arrow" />
              </Button>
              <span className="iot-drawer-list-header-title">{ClientProperties.DEVICE_DRAWER_TITLE}</span>
            </div>
            <React.Fragment>
              {this.state.searchFilter.startsWith("$") ? this.renderListBrowserMode() : this.renderTreeBrowserMode()}
              <SearchBar
                searchString={this.state.searchFilter}
                onSearchTriggered={(searchString: string): void => this.onSearchTriggered(searchString)}
                className="tree-search-bar-container"
              />
            </React.Fragment>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default withRouter(DeviceDrawer);
