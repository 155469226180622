/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { IconButton, Table, TableCell, TableHead, TableRow } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { Component, Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { RouteComponentPropsParams } from "../../../../types/routerprops";
import Device from "../../../../data/device/Device";
import DeviceGroup from "../../../../data/device/DeviceGroup";
import SearchBar from "../../../ui/search-bar";
import ErrorDialog from "../../../ui/error-dialog";
import ClientProperties from "../../../../data/clientSpecific/ClientProperties";
import Loader from "../../../ui/loader";
import AddDevicePopup from "./add-device-popup";
import { Maybe } from "../../../../types/aliases";
import ResourceSelector, { ResourceSelectorObserver } from "../../../../state/ResourceSelector";
import DeviceTree from "../../../device-browsing/device-tree";
import { getCaseInsensitiveSearchFilter } from "../../../device-browsing/helpers/search-filter";
import { getDisplayName } from "../../../../data/utils/utils";
import { isEmpty } from "../../../../utils/validation";

interface Props extends RouteComponentProps<RouteComponentPropsParams> {
  groups?: DeviceGroup[];
  triggerGroupsUpdate: () => void;
}

interface State {
  searchFilter: string;
  drawerOpen: boolean;
  tabValue: number;
  showAddDevicePopup: boolean;
  loading: boolean;
  selectedDevice?: Device;
  parentGroup?: DeviceGroup;
  errorMsg?: string;
}

class DeviceManagement extends Component<Props, State> implements ResourceSelectorObserver {

  public constructor(props: Props) {
    super(props);
    this.state = {
      searchFilter: "",
      drawerOpen: false,
      tabValue: 0,
      showAddDevicePopup: false,
      loading: false,
    };
  }

  public onSelectedDeviceChanged(device?: Device): void {
    this.setState({ selectedDevice: device });
  }

  public componentDidMount(): void {
    ResourceSelector.getInstance().addObserver(this);

    if (!this.props.match.params.id) {
      this.toggleDrawer();
    }
  }

  public async componentDidUpdate(prevProps: Props): Promise<void> {
    if (prevProps.match.params.id !== this.props.match.params.id || isEmpty(this.props.match.params)) {
      await ResourceSelector.getInstance().setCurrentDevice(this.props.match.params.id);
    }

    if (this.props.match.params.id && !this.state.selectedDevice) {
      await ResourceSelector.getInstance().setCurrentDevice(this.props.match.params.id);
    }
  }

  public componentWillUnmount(): void {
    ResourceSelector.getInstance().removeObserver(this);
  }

  private getDisplayName = (): Maybe<string> => {
    if (this.state.selectedDevice) {
      return getDisplayName(this.state.selectedDevice);
    }
  };

  private onSearchTriggered(searchString: string): void {
    console.log("onSearchTriggered: " + searchString);
    this.setState({ searchFilter: searchString });
  }

  private handleClosePopup = (): void => {
    // TODO: If popups needed in sequence, then split this
    this.setState({
      showAddDevicePopup: false,
      parentGroup: undefined,
      errorMsg: undefined,
    });
  };

  private handleAddDeviceIconClick = (): void => {
    this.setState({
      parentGroup: this.props.groups?.[0],
      showAddDevicePopup: true,
    });
  };

  private toggleDrawer = (): void => {
    this.setState((prevState: State) => ({
      drawerOpen: !prevState.drawerOpen,
    }));
  };

  private renderAddDevicePopup(): Maybe<JSX.Element> {
    if (this.state.parentGroup) {
      return (
        <AddDevicePopup
          open={this.state.showAddDevicePopup}
          targetGroup={this.state.parentGroup}
          onClose={(success): void => {
            this.handleClosePopup();

            if (success) {
              this.props.triggerGroupsUpdate();
            }
          }}
        />
      );
    }
  }

  private renderPopups(): Maybe<JSX.Element> {
    if (this.state.errorMsg) {
      return (
        <ErrorDialog
          errorMsg={this.state.errorMsg}
          onClose={this.handleClosePopup}
        />
      );
    } else {
      return this.renderAddDevicePopup();
    }
  }

  private renderTreeBrowserMode(): JSX.Element {
    return (
      <Fragment>
        <SearchBar
          searchString={this.state.searchFilter}
          onSearchTriggered={(searchString: string): void => this.onSearchTriggered(searchString)}
          className="admin-search-bar-container"
        />
        <DeviceTree
          groups={this.props.groups}
          searchFilter={getCaseInsensitiveSearchFilter(this.state.searchFilter)}
          editMode={true}
        />
      </Fragment>
    );
  }

  private renderDeviceBrowserMode(): JSX.Element {
    if (this.state.loading) {
      return <Loader />;
    } else {
      return this.renderTreeBrowserMode();
    }
  }

  private renderAddDeviceButton(): Maybe<JSX.Element> {
    if (this.props.groups && !this.state.loading) {
      return (
        <IconButton
          title="Add device"
          onClick={this.handleAddDeviceIconClick}
          style={{ backgroundColor: "#e6e6e6", marginLeft: "3rem", marginTop: "1rem" }}
          size="small"
        >
          <AddIcon />
        </IconButton>
      );
    }
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        {this.renderPopups()}
        <div className="admin-device-list-container">
          <span>{this.getDisplayName()}</span>
          <div className="admin-device-list">
            <Table className="org-groups-table">
              <TableHead>
                <TableRow>
                  <TableCell>Groups / {ClientProperties.DEVICE_DRAWER_TITLE}</TableCell>
                </TableRow>
              </TableHead>
            </Table>
            {this.renderDeviceBrowserMode()}
            {this.renderAddDeviceButton()}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(DeviceManagement);
