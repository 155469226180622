/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, ReactNode } from "react";
import ErrorDialog from "../../../ui/error-dialog";
import Loader from "../../../ui/loader";
import Organization from "../../../../data/organization/Organization";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import PolicyGroup from "../../../../data/organization/PolicyGroup";

interface Props {
  organization: Organization;
}

interface State {
  policyGroups?: PolicyGroup[];
  loading: boolean;
  errorMsg?: string;
}

const ROW_COL_COUNT = 1;
export default class Permissions extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    return this.updatePolicies();
  }

  public async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    if (prevProps.organization.getId() !== this.props.organization.getId()) {
      return this.updatePolicies();
    }
  }

  public async updatePolicies(): Promise<void> {
    this.setState({ loading: true });
    this.setState({
      policyGroups: await this.props.organization.getPolicyGroups(),
      loading: false,
    });
  }

  private renderPolicyGroupRows(): ReactNode {
    return this.state.policyGroups?.map((policyGroup) => {
      return (
        <TableRow key={policyGroup.getId()}>
          <TableCell>
            <Typography color="textPrimary">{policyGroup.getName()}</Typography>
          </TableCell>
        </TableRow>
      );
    });
  }

  private renderError(): ReactNode {
    if (this.state.errorMsg) {
      return (
        <TableRow>
          <TableCell colSpan={ROW_COL_COUNT} align="center">
            <ErrorDialog
              errorMsg={this.state.errorMsg}
              onClose={(): void => this.setState({ errorMsg: undefined })}
            />
          </TableCell>
        </TableRow>
      );
    }
  }

  private renderLoader(): ReactNode {
    if (this.state.loading) {
      return (
        <TableRow>
          <TableCell colSpan={ROW_COL_COUNT} align="center">
            <Loader size="small" />
          </TableCell>
        </TableRow>
      );
    }
  }

  public render(): JSX.Element {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={ROW_COL_COUNT}>Organization&apos;s Policy Groups</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.renderPolicyGroupRows()}
          {this.renderError()}
          {this.renderLoader()}
        </TableBody>
      </Table>
    );
  }
}
