/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { List, Switch, TextField } from "@material-ui/core";
import React from "react";
import { ColorResult } from "react-color";
import CompactPicker from "react-color/lib/components/compact/Compact";
import { SuperHW } from "../../../../client/devices/SuperHW/SuperHW";
import Loader from "../../../ui/loader";
import SettingsListItem from "../settings-list-item";
import SettingsControls from "./settings-controls";
import { Maybe, Nullable } from "../../../../types/aliases";
import DeviceState from "../../../../data/device/DeviceState";
import { DeviceStateProperties } from "../../../../data/device/DeviceStateProperties";
import { SuperHWState } from "../../../../client/devices/SuperHW/SuperHWState";
import { HyperHWStateProperties } from "../../../../client/devices/HyperHW/HyperHWStateProperties";
import { HyperHWState, HyperIntervalTypes } from "../../../../client/devices/HyperHW/HyperHWState";
import { HyperHW } from "../../../../client/devices/HyperHW/HyperHW";

interface Props {
  deviceType: string;
  deviceState: DeviceState<DeviceStateProperties>;
  closeSettings: () => void;
}

interface State {
  changesMade: boolean;
  displayColorPicker: boolean;
  ledEnabled: boolean;
  ledColor: string;
  displayName: string;
  deviceLocation: string;
  noModemSleep: boolean;
  updateInterval: number;
  measurementInterval: number;
  firmwareVersion: string;
  resetCount: number;
}

type Color = { r: number; g: number; b: number; a: number };

export default class SettingsPageGeneral extends React.Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      changesMade: false,
      displayColorPicker: false,
      ledEnabled: this.stateAsSuper?.ledEnabled || false,
      ledColor: this.stateAsSuper?.ledColor || "#000000",
      displayName: this.props.deviceState.displayName || "",
      deviceLocation: this.props.deviceState.deviceLocation || "",
      noModemSleep: this.stateAsHyper?.noModemSleep || false,
      updateInterval: HyperHWState.toValidInterval(this.stateAsHyper?.updateInterval, HyperIntervalTypes.UPDATE),
      measurementInterval: HyperHWState.toValidInterval(this.stateAsHyper?.measurementInterval, HyperIntervalTypes.MEASURE),
      firmwareVersion: this.stateAsSuper?.firmwareVersion || "N/A",
      resetCount: this.stateAsSuper?.resetCount || 0,
    };
  }
  
  private get stateAsSuper(): Partial<SuperHWState> {
    return this.props.deviceState;
  }

  private get stateAsHyper(): Partial<HyperHWStateProperties> {
    return this.props.deviceState;
  }

  private handleCancel = (): void => {
    this.props.deviceState.revert();
    this.props.closeSettings();
  };

  private saveDeviceSettings = async (): Promise<void> => {
    this.setState({ changesMade: false });
    await this.props.deviceState.store();
  };

  private toggleColorPicker = (): void => {
    this.setState((prevState: State) => ({ displayColorPicker: !prevState.displayColorPicker }));
  };

  private ledStateChanged = (): void => {
    if (SettingsPageGeneral.stateIsSuper(this.props.deviceState) && this.props.deviceState.ledEnabled !== null) {
      this.props.deviceState.ledEnabled = !this.props.deviceState.ledEnabled;
      this.setState({ ledEnabled: this.props.deviceState.ledEnabled, changesMade: true });
    }
  };

  private ledColorChanged = (color: ColorResult): void => {
    if (SettingsPageGeneral.stateIsSuper(this.props.deviceState) && this.props.deviceState.ledColor !== null) {
      this.props.deviceState.ledColor = color.hex;
      this.setState({ ledColor: color.hex, changesMade: true });
    }
  };

  private displayNameChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newName = event.currentTarget.value;

    if (this.props.deviceState.displayName !== null) {
      this.props.deviceState.displayName = newName;
      this.setState({ displayName: newName, changesMade: true });
    }
  };

  private deviceLocationChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newLocation = event.currentTarget.value;

    this.props.deviceState.deviceLocation = newLocation;
    this.setState({ deviceLocation: newLocation, changesMade: true });
  };

  private noModemSleepChanged = (): void => {
    if (SettingsPageGeneral.stateIsHyper(this.props.deviceState) && this.stateAsHyper.noModemSleep != null) {
      const enabled = !this.stateAsHyper.noModemSleep;
      this.stateAsHyper.noModemSleep = enabled;
      this.setState({ noModemSleep: enabled, changesMade: true });
    }
  };

  private updateIntervalChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = Number(event.currentTarget.value);

    if (SettingsPageGeneral.stateIsHyper(this.props.deviceState)
      && this.stateAsHyper.updateInterval !== null) {
      this.stateAsHyper.updateInterval = newValue;
      this.setState({ updateInterval: newValue, changesMade: true });
    }
  };

  private measurementIntervalChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = Number(event.currentTarget.value);

    if (SettingsPageGeneral.stateIsHyper(this.props.deviceState)
      && this.stateAsHyper.measurementInterval !== null) {
      this.stateAsHyper.measurementInterval = newValue;
      this.setState({ measurementInterval: newValue, changesMade: true });
    }
  };

  private getRgbaLedColor(): Color {
    let color: Color = {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    };

    if (this.state.ledColor !== null) {
      color = {
        r: parseInt(this.state.ledColor.slice(1, 3), 16),
        g: parseInt(this.state.ledColor.slice(3, 5), 16),
        b: parseInt(this.state.ledColor.slice(5, 7), 16),
        a: 1,
      };
    }
    return color;
  }

  private renderColorPicker(): Maybe<JSX.Element> {
    if (this.state.displayColorPicker) {
      return (
        <div className="popover" onClick={this.toggleColorPicker}>
          <CompactPicker
            color={this.state.ledColor}
            onChange={this.ledColorChanged}
          />
        </div>
      );
    }
  }

  private getLoader(key: string): Maybe<JSX.Element> {
    // TODO: better state handling in settings
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (this.props.deviceState.beingApplied(key as any)) {
      return (
        <Loader
          size="small"
          topBottomPadding="0"
          leftRightPadding="0.2rem"
        />
      );
    }
  }

  public render(): Nullable<JSX.Element> {
    if (!this.props.deviceState) {
      console.error("Settings general page does not have device state");
      return null;
    }
    const color = this.getRgbaLedColor();
    const isSuper = this.props.deviceType === SuperHW.type;
    const showModemControls = this.props.deviceType === HyperHW.type;
    return (
      <List>
        <SettingsListItem label="Display name">
          <TextField
            value={this.state.displayName}
            onChange={this.displayNameChanged}
          />
          {this.getLoader("displayName")}
        </SettingsListItem>
        <SettingsListItem label="Device location">
          <TextField
            value={this.state.deviceLocation}
            onChange={this.deviceLocationChanged}
          />
          {this.getLoader("deviceLocation")}
        </SettingsListItem>        
        {showModemControls && <SettingsListItem label="Disable modem sleep">
          <Switch
            edge="end"
            onChange={this.noModemSleepChanged}
            checked={this.state.noModemSleep}
            color="primary"
          />
          {this.getLoader("noModemSleep")}
        </SettingsListItem>}
        {showModemControls && <SettingsListItem label="Update interval">
          <TextField
            value={this.state.updateInterval}
            inputMode="numeric"
            onChange={this.updateIntervalChanged}
          />
          {this.getLoader("updateInterval")}
        </SettingsListItem>}
        {showModemControls && <SettingsListItem label="Measurement interval">
          <TextField
            value={this.state.measurementInterval}
            inputMode="numeric"
            onChange={this.measurementIntervalChanged}
          />
          {this.getLoader("measurementInterval")}
        </SettingsListItem>}
        {isSuper && <SettingsListItem label="LED on/off">
          <Switch
            edge="end"
            onChange={this.ledStateChanged}
            checked={this.state.ledEnabled}
            color="primary"
          />
          {this.getLoader("ledEnabled")}
        </SettingsListItem>}
        {isSuper && <SettingsListItem label="LED color">
          <div className="swatch" onClick={this.toggleColorPicker}>
            <div
              className="color"
              style={{
                background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
              }}
            />
          </div>
          {this.getLoader("ledColor")}
        </SettingsListItem>}
        {this.renderColorPicker()}
        <SettingsListItem label="Firmware version">
          {this.state.firmwareVersion}
        </SettingsListItem>
        <SettingsListItem label="Reset count">{this.state.resetCount}</SettingsListItem>
        <SettingsControls
          changesMade={this.state.changesMade}
          actionButtonText="Apply"
          onSave={this.saveDeviceSettings}
          onCancel={this.handleCancel}/>
      </List>
    );
  }

  private static stateIsSuper(state: unknown): state is SuperHWState {
    return state instanceof SuperHWState;
  }

  private static stateIsHyper(state: unknown): state is HyperHWState {
    return state instanceof HyperHWState;
  }
}
