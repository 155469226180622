/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import BaseObservable from "../observer/BaseObservable";
import User from "./User";
import PolicyGroup from "./PolicyGroup";
import { Maybe } from "../../types/aliases";

export interface OrganizationParameters {
  id: string;
  name: string;
  parentOrganizationId?: string;
  organizationUid: string;
}

export interface CreateOrganizationParameters {
  name: string;
}

export interface CreateUserParameters {
  username: string;
  resendInvitation?: boolean;
}

// allows implementors implement a subset of the listening methods
// this managing the interface implementations easier
export interface OrganizationObserver {
  onNameChange?: (organization: Organization) => void;

  onUsersChange?: (usersList: User[], organization: Organization) => void;

  onPolicyGroupsChange?: (groups: PolicyGroup[], organization: Organization) => void;

  onChildrenChange?: (children: Organization[], organization: Organization) => void;

  onDeleted?: (organization: Organization) => void;
}

export default abstract class Organization extends BaseObservable<OrganizationObserver> {
  protected readonly id: string;
  protected readonly parentId?: string;
  protected name: string;
  protected readonly organizationUid: string;

  protected constructor(params: OrganizationParameters) {
    super();
    this.id = params.id;
    this.name = params.name;
    this.parentId = params.parentOrganizationId;
    this.organizationUid = params.organizationUid;
  }

  public getId(): string {
    return this.id;
  }

  public getName(): string {
    return this.name;
  }

  public getUid(): string {
    return this.organizationUid;
  }

  public getParentOrganizationId(): Maybe<string> {
    return this.parentId;
  }

  public abstract createOrganization(parameters: CreateOrganizationParameters): Promise<Organization>;

  public abstract getChildOrganizations(): Promise<Organization[]>;
  
  public abstract getParentOrganization(): Promise<Maybe<Organization>>;

  public abstract getUsers(): Promise<User[]>;

  public abstract changeName(newName: string): Promise<void>;

  public abstract createUser(parameters: CreateUserParameters): Promise<User>;

  public abstract addUser(user: User): Promise<void>;

  public abstract removeUser(user: User): Promise<void>;

  public abstract getPolicyGroups(): Promise<PolicyGroup[]>;

  // TODO: rest of the policy groups API

  public abstract delete(): Promise<void>;
}
