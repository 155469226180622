/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { ChangeEvent, Component, Fragment } from "react";
import Loader from "../../ui/loader";
import { Maybe } from "../../../types/aliases";
import AuthWrapper from "../../../data/auth/authWrapper";
import { Button, TextField, IconButton, InputAdornment } from "@material-ui/core";
import VisibilityOnIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

export interface Props {
}

interface State {
  isLoadingPassword: boolean;
  loggedInUserName: string;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string; 
  beErrorMessage: string;
  successMessage: string;
  showOldPassword: boolean;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
}

export default class PasswordForm extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);

    this.state = {
      isLoadingPassword: false,
      loggedInUserName: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      beErrorMessage: "",
      successMessage: "",
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
    };
  }

  public componentDidMount(): void {
    this.getloggedInUserName();
  }

  private async getloggedInUserName(): Promise<void> {
    const loggedInUserName = await AuthWrapper.getCurrentAuthenticatedUsername();
    this.setState({ loggedInUserName: loggedInUserName });
  }

  private renderLoaderPassword = (): Maybe<JSX.Element> => {
    if (this.state.isLoadingPassword) {
      return <Loader />;
    }
  };

  // This is the UI where an existing user´s old and new password are asked. 
  private renderInputs(): JSX.Element {
    const isEnabled = this.state.newPassword.length > 0 && this.state.confirmPassword.length > 0;
    return (
      <Fragment>
        <form className="user-fields">
          <TextField
            className="text-field"
            label="Old password"
            type={this.state.showOldPassword ? "text" : "password"}
            autoComplete="current-password"
            margin="normal"
            variant="outlined"
            defaultValue={this.state.oldPassword}
            onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void =>
              this.setState({ oldPassword: event.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={this.toggleOldPasswordVisibility}
                  >
                    {this.state.showOldPassword ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className="text-field"
            label="New password"
            type={this.state.showNewPassword ? "text" : "password"}
            autoComplete="current-password"
            margin="normal"
            variant="outlined"
            defaultValue={this.state.newPassword}
            onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void =>
              this.setState({ newPassword: event.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={this.toggleNewPasswordVisibility}
                  >
                    {this.state.showNewPassword ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className="text-field"
            label="Confirm new password"
            type={this.state.showConfirmPassword ? "text" : "password"}
            autoComplete="current-password"
            margin="normal"
            variant="outlined"
            defaultValue={this.state.confirmPassword}
            onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void =>
              this.setState({ confirmPassword: event.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={this.toggleConfirmPasswordVisibility}
                  >
                    {this.state.showConfirmPassword ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>
        <div className="user-buttons">
          <Button
            disabled={!isEnabled}
            variant="contained"
            color="primary"
            onClick={this.newPasswordSpellCheckAndSubmit}
            className="button"
          >
                        Confirm and Change Password
          </Button>
        </div>
      </Fragment>
    );
  }

  private toggleOldPasswordVisibility = (): void => {
    this.setState((prevState: State) => ({ showOldPassword: !prevState.showOldPassword }));
  };  

  private toggleNewPasswordVisibility = (): void => {
    this.setState((prevState: State) => ({ showNewPassword: !prevState.showNewPassword }));
  };  

  private toggleConfirmPasswordVisibility = (): void => {
    this.setState((prevState: State) => ({ showConfirmPassword: !prevState.showConfirmPassword }));
  }; 

  private newPasswordSpellCheckAndSubmit = (): void => {
    if (this.state.newPassword && this.state.confirmPassword && this.state.newPassword === this.state.confirmPassword) {
      console.log ("Confirm and Change Password pressed. Passwords match. Proceeding to check the old password.");
      // Clear previous messages from the UI before continuing:
      this.setState({ 
        beErrorMessage: "",
        successMessage: "", 
      });
      this.handleChangePassword();
    }
  };

  // Existing user: After pressing "Confirm and Change Password", have the old password checked from the backend, then the new password saved:
  public async handleChangePassword(): Promise<void> {
    try {
      this.setState({ isLoadingPassword: true });
      await AuthWrapper.checkOldPasswordAndSubmitNewPassword(this.state.loggedInUserName, this.state.oldPassword, this.state.newPassword);
      this.setState({
        isLoadingPassword: false,
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        beErrorMessage: "",
        successMessage: "Your password has been successfully changed!",
      });
    } catch (error) {
      console.error(error);
      let beErrorMessage = "";
      
      switch (error.toString()) {  // cases mapped based on authWrapper error messages:
        case "Error: Attempt limit exceeded, please try after some time.":
          beErrorMessage = "LimitExceededException. You have tried too many times within a short period of time. Please try again later.";
          break;
        case "Error: Incorrect username or password.":
          beErrorMessage = "NotAuthorizedException. Incorrect username/password combination. Probably incorrect old password. Please check and try again.";
          break;
        case "Error: 1 validation error detected: Value at 'previousPassword' failed to satisfy constraint: Member must have length greater than or equal to 6":
          beErrorMessage = "InvalidParameterException. Old password does not conform to policy. The old password that you provided is not long enough.";
          break;
        case "Error: 2 validation errors detected: Value at 'previousPassword' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'proposedPassword' failed to satisfy constraint: Member must have length greater than or equal to 6":
          beErrorMessage = "InvalidParameterException. Both Old and New passwords do not conform to policy. The old and new passwords that you provided are not long enough.";
          break;
        case "Error: Network error":
          beErrorMessage = "NetworkError. There was an error with the network. Please check your network connection and try again.";
          break;
        case "Error: Password did not conform with policy: Password must have numeric characters":
          beErrorMessage = "InvalidPasswordException. Password did not conform with policy. Password must have numeric characters.";
          break;
        case "Error: Password did not conform with policy: Password must have lowercase characters":
          beErrorMessage = "InvalidPasswordException. Password did not conform with policy. Password must have lowercase characters.";
          break;
        case "Error: 1 validation error detected: Value at 'proposedPassword' failed to satisfy constraint: Member must have length greater than or equal to 6":
          beErrorMessage = "InvalidParameterException. Password does not conform to policy. Password that you provided is not long enough.";
          break;
        case "Error: Password did not conform with policy: Password not long enough":
          beErrorMessage = "InvalidPasswordException. Password did not conform with policy. Password that you provided is not long enough.";
          break;
        case "Error: Invalid session for the user, session is expired.":
          beErrorMessage = "NotAuthorizedException. Invalid session for the user, session is expired. Please refresh the browser and try again.";
          break;
        default:
          beErrorMessage = "General Backend Error, Unknown/Unusual Error, or Multiple Errors. Unable to perform action, please try again. If this problem persists, contact your admin.";
      }
      this.setState({
        isLoadingPassword: false,
        successMessage: "",
        beErrorMessage: beErrorMessage,
      });
    }
  }


  private renderNewPasswordSpellCheckMessage(): Maybe<JSX.Element> {
    if (this.state.newPassword.length > 0 && this.state.confirmPassword.length > 0) { 
      if (this.state.newPassword !== this.state.confirmPassword) {
        return (
          <div className="user-pwCheckText">
                    Passwords you entered don´t match. Check spelling.
          </div>
        );
      } else {        
        return (
          <div className="user-pwCheckText">
                    Passwords match.
          </div>
        );
      }
    } else {
      return (
        <div className="user-pwCheckText">
                  &nbsp; 
        </div>
      );
    }
  }

  private renderChangePasswordMessage = (): JSX.Element => {
    if (this.state.successMessage) {
      return (
        <div className="user-successtext">
                  Success! {this.state.successMessage}
        </div>
      );
    } else if (this.state.beErrorMessage) {
      return (
        <div className="user-errortext">
                  An error occured: {this.state.beErrorMessage}
        </div>
      );
    } else {
      return (
        <div className="user-successtext">
                  &nbsp; 
        </div>
      );
    }
  };

  public render(): JSX.Element { 
    return (
      <Fragment>
        <h3 className="user-header">
                    Enter Old password and New password
        </h3>

        {this.renderInputs()}
        {this.renderNewPasswordSpellCheckMessage()}
        {this.renderLoaderPassword()}
        {this.renderChangePasswordMessage()}
      </Fragment >
    );
  }
}
