/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component, Fragment } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import BackendFactory from "../BackendFactory";
import AuthWrapper from "../data/auth/authWrapper";
import OtaManager from "../data/ota/otaManager";
import DeviceGroup from "../data/device/DeviceGroup";
import Device, { DeviceObserver } from "../data/device/Device";
import EventsRepository from "../data/events/EventsRepository";
import ResourceSelector, { ResourceSelectorObserver } from "../state/ResourceSelector";
import DeviceDrawer from "../components/drawers/device-drawer";
import EventView from "../components/event-view";
import StatisticsView from "../components/statistics-view";
import AdminView from "../components/admin-view";
import HistoryView from "../components/history-view";
import StatusView from "../components/status-view";
import UserView from "../components/user-view";
import { Maybe } from "../types/aliases";

interface Props {
}

interface State {
  groups?: DeviceGroup[];
  devices?: Device[];
}

export default class Body extends Component<Props, State> implements DeviceObserver, ResourceSelectorObserver {
  
  public constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public async componentDidMount(): Promise<void> {
    if (await AuthWrapper.isCurrentUserAuthenticated()) {
      ResourceSelector.getInstance().addObserver(this);
      await this.getGroups();
      await this.getDevices();
      await EventsRepository.getInstance().init();
      await OtaManager.getInstance().init();
    }
  }

  public componentWillUnmount(): void {
    this.removeDeviceStateObservers();
    ResourceSelector.getInstance().removeObserver(this);
    EventsRepository.getInstance().uninit();
    OtaManager.getInstance().uninit();
    this.setState({
      devices: undefined,
      groups: undefined,
    });
  }

  public onSelectedDeviceChanged(device?: Device): void {
    console.log(`onSelectedDeviceChanged(${device?.getId()})`);
  }

  public async onSelectedGroupChanged(group?: DeviceGroup): Promise<void> {
    console.log(`onSelectedGroupChanged(${group?.getId()})`);

    if (group && !this.state.groups?.includes(group)) {
      const groupDevices = await group.getDevices();
      this.registerDeviceStateObservers(groupDevices);
      const deviceSet = new Set(this.state.devices ?? []);
      groupDevices.forEach(device => deviceSet.add(device));
      
      this.setState({ devices: [...deviceSet] });
    }
  }

  private registerDeviceStateObservers(devices?: Device[]): void {
    const devs = devices ?? this.state.devices ?? [];
    devs.forEach(device => device.addObserver(this));
  }

  private removeDeviceStateObservers(): void {
    this.state.devices?.forEach((device: Device) => {
      device.removeObserver(this);
    });
  }

  private async getGroups(): Promise<void> {
    const groups: DeviceGroup[] = await BackendFactory.getBackend().getRootDeviceGroups();
    console.log(`found ${groups.length} groups`);
    this.setState({ groups });
  }

  private async getDevices(): Promise<void> {
    if (this.state.groups && this.state.groups.length > 0) {
      // fetch devices for all root groups, and use those as the "base" devices
      // TODO: ignore unassigned_devices?
      const allGroupDevices = await Promise.all(this.state.groups.map(group => group.getDevices()));
      // combine new devices and old devices into single device array
      const devices = new Set([...allGroupDevices.flat(), ...(this.state.devices ?? [])]);
      this.setState(
        {
          devices: [...devices],
        },
        () => this.registerDeviceStateObservers(),
      );
    } else {
      this.setState({ devices: [] });
    }
  }

  public onDeviceStateUpdate(device: Device): void {
    console.log(`onDeviceStateUpdate ${device.getId()}`);
    let devices: Maybe<Device[]>;

    if (this.state.devices) {
      devices = this.state.devices.slice();
      const deviceIndex = devices.findIndex(dev => dev.getId() === device.getId());

      // should device devices update, if the state of a single device updates?
      if (deviceIndex > -1) {
        devices[deviceIndex] = device;
      }
    } else {
      devices = [device];
    }
    this.setState({ devices });
  }

  private triggerGroupsUpdate = async (): Promise<void> => {
    await this.getGroups();
    await this.getDevices();
  };

  private renderHistoryView = (): JSX.Element => {
    return (
      <Fragment>
        <DeviceDrawer
          groups={this.state.groups}
        />
        <HistoryView />
      </Fragment>
    );
  };

  private renderStatusView = (): JSX.Element => {
    return (
      <Fragment>
        <DeviceDrawer
          groups={this.state.groups}
        />
        <StatusView
          devices={this.state.devices}
        />
      </Fragment>
    );
  };

  private renderStatisticsView = (): JSX.Element => {
    return (
      <Fragment>
        <DeviceDrawer
          groups={this.state.groups}
        />
        <StatisticsView />
      </Fragment>
    );
  };

  private renderEventsView = (): JSX.Element => {
    return (
      <Fragment>
        <DeviceDrawer
          groups={this.state.groups}
        />
        <EventView />
      </Fragment>
    );
  };

  private renderUserView = (): JSX.Element => {
    return (
      <UserView />
    );
  };

  private renderAdminView = (): JSX.Element => {
    return (
      <AdminView
        groups={this.state.groups}
        legacyBrowserMode={false}
        triggerGroupsUpdate={this.triggerGroupsUpdate}
      />
    );
  };

  private redirectToRoot = (): JSX.Element => {
    return <Redirect to="/status" />;
  };

  public render(): JSX.Element {
    return (
      <section className="body-container col-sm-12 col-xsm-12">
        <Switch>
          <Route
            exact={true}
            path="/history/:id?"
            render={this.renderHistoryView}
          />
          <Route
            exact={true}
            path="/status/:id?"
            render={this.renderStatusView}
          />
          <Route
            exact={true}
            path="/statistics/:id?"
            render={this.renderStatisticsView}
          />
          <Route
            exact={true}
            path="/events/:id?"
            render={this.renderEventsView}
          />
          <Route
            exact={true}
            path="/user"
            render={this.renderUserView}
          />
          <Route
            exact={true} 
            path="/admin"
            render={this.renderAdminView}
          />
          <Route
            exact={false}
            path="/"
            render={this.redirectToRoot}
          />
        </Switch>
      </section >
    );
  }
}
