/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Service } from "../backend/AppSyncClientProvider";
import Device from "./Device";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import AuthWrapper from "../auth/authWrapper";
import { Maybe } from "../../types/aliases";
import { DevicesStatesUpdateFeedDocument } from "../../generated/gqlDevice";

export default class ShadowSubscriptionManager {

  private static instance: ShadowSubscriptionManager;

  private devices: Device[] = [];
  private subscriptions: ZenObservable.Subscription[] = [];

  public static getInstance(): ShadowSubscriptionManager {
    if (!ShadowSubscriptionManager.instance) {
      ShadowSubscriptionManager.instance = new ShadowSubscriptionManager();
    }
    return this.instance;
  }

  public addDevice(device: Device): void {
    if (this.devices && this.devices.indexOf(device) === -1) {
      this.devices.push(device);

      if (this.devices.length === 1) {
        // TODO: Fix any type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.subscribe().catch((reason: any) => console.error("Failed to subscribe", reason));
      }
    }
  }

  public removeDevice(device: Device): void {
    const observerIndex = this.devices.indexOf(device);

    if (observerIndex !== -1) {
      if (this.devices.length === 1) {
        while (this.subscriptions.length > 0) {
          this.subscriptions.pop()?.unsubscribe();
        }
      }
      this.devices.splice(observerIndex, 1);
    }
  }

  private findDevice(deviceId: string): Maybe<Device> {
    return this.devices.find((device: Device) => deviceId === device.getId());
  }

  private async subscribe(): Promise<void> {
    (await AuthWrapper.getCurrentAuthenticatedUserClaims())?.canSee.forEach((canSee: string) => 
      this.subscriptions.push(this.subscribeWithIdentity(canSee)));
  }

  private subscribeWithIdentity(identity: string): ZenObservable.Subscription {
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
    return client.subscribe(
      DevicesStatesUpdateFeedDocument,
      {
        receiver: identity,
      },
    ).subscribe({
      // TODO: Fix any type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: (error: any): void => {
        if (error.errorMessage === "AMQJS0008I Socket closed.") {
          console.log("Reconnecting socket");
          this.subscriptions.push(this.subscribeWithIdentity(identity));
        }
        console.error("State subscription: " + error);
      },
      next: (response): void => {
        const { deviceId, reported, desired, timestamp } = response.data?.devicesStatesUpdateFeed ?? {};

        if (deviceId) {
          const reportedProps = reported ? JSON.parse(reported) : undefined;
          const desiredProps = desired ? JSON.parse(desired) : undefined;
          const device = this.findDevice(deviceId) ?? undefined;

          if (device) {
            device.setState(timestamp ?? undefined, reportedProps, desiredProps);
          }
        } else {
          console.warn(`Skipped state update for state ${reported} since it had no timestamp`);
        }
      },
    });
  }
}
