/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Checkbox } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import AuthWrapper from "../../data/auth/authWrapper";
import { SessionSet } from "../../data/data/SessionSet";
import Device from "../../data/device/Device";
import ErrorDialog from "../ui/error-dialog";
import Loader from "../ui/loader";
import TimeWrapper from "../../data/time/TimeWrapper";
import TimeRangePicker from "../ui/time-range-picker";
import DropdownSelection from "../ui/dropdown-selection";
import { Maybe } from "../../types/aliases";
import BackendFactory from "../../BackendFactory";
import ResourceSelector, { ResourceSelectorObserver } from "../../state/ResourceSelector";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

interface QsParams {
  url: string;
  container: HTMLElement;
  width: string;
  height: string;
  parameters: {
    selectedDevice?: string;
    startTime?: number;
    endTime?: number;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type QuickSightDashboard = any;

interface Props {
}

interface State {
  startTimestamp: number;
  endTimestamp: number;
  isLoading: boolean;
  onlySelectedDevice: boolean;
  selection?: number;
  selectedDevice?: Device;
  sessionSet?: SessionSet;
  errorMsg?: string;
}

const ALL_DEVICES_KEY = "[ALL]";

export default class StatisticsView extends Component<Props, State> implements ResourceSelectorObserver {

  private dashboard: QuickSightDashboard = null;

  public constructor(props: Props) {
    super(props);
    const endTimestamp = Date.now();
    const startTimestamp = TimeWrapper.getTimestampXUnitsAgo(endTimestamp, 1, "month");
    this.state = {
      startTimestamp,
      endTimestamp,
      isLoading: true,
      onlySelectedDevice: true,
    };
  }

  public async componentDidMount(): Promise<void> {
    ResourceSelector.getInstance().addObserver(this);
    await this.createDashboard();
  }

  private async createDashboard(): Promise<void> {
    try {
      const openIdToken = await AuthWrapper.getOpenIdToken();

      if (!openIdToken) {
        throw new Error("No open id token");
      }

      const dashboardId = process.env.REACT_APP_DEFAULT_QUICKSIGHT_DASHBOARD_ID;

      if (!dashboardId) {
        throw new Error("Dashboard id not set");
      }
      const embedUrl = await BackendFactory.getBackend().getQsEmbedUrl(openIdToken, dashboardId);

      if (!embedUrl) {
        throw new Error("Did not receive embedding url");
      }

      const containerDiv = document.getElementById("dashboardContainer");

      if (!containerDiv) {
        throw new Error("Could not find container");
      }
      const params: QsParams = {
        url: embedUrl,
        container: containerDiv,
        width: "100%",
        height: "98%",
        parameters: {
          selectedDevice: this.state.selectedDevice ? this.state.selectedDevice.getId() : ALL_DEVICES_KEY,
          startTime: this.state.startTimestamp,
          endTime: this.state.endTimestamp,
        },
      };
      this.dashboard = QuickSightEmbedding.embedDashboard(params);
      this.dashboard.on("error", this.onDashboardError);
      this.dashboard.on("load", this.onDashboardLoad);
      this.setState({ isLoading: false });
    } catch (error) {
      console.error("createDashboard", error);
      this.setState({ errorMsg: "Failed to create dashboard", isLoading: false });
    }
  }

  public async componentDidUpdate(prevProps: Props, prevState: State): Promise<void> {
    const deviceChanged = this.state.selectedDevice != null &&
            (prevState.selectedDevice == null || prevState.selectedDevice.getId() !==
                this.state.selectedDevice.getId());

    if (deviceChanged && this.state.onlySelectedDevice) {
      this.updateDashboardDevice(this.state.selectedDevice!.getId());
    }
  }

  public componentWillUnmount(): void {
    ResourceSelector.getInstance().removeObserver(this);
  }

  public onSelectedDeviceChanged(device?: Device): void {
    this.setState({ selectedDevice: device });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onDashboardLoad = (payload: any): void => {
    console.log("onDashboardLoad", payload);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onDashboardError = (payload: any): void => {
    console.error("onDashboardError", payload);
    this.setState({ errorMsg: "Dashboard loading failed" });
  };

  private onOnlySelectedDeviceChange = (event: React.FormEvent<HTMLInputElement>): void => {
    this.setState({ onlySelectedDevice: event.currentTarget.checked });
    let id = ALL_DEVICES_KEY;

    if (event.currentTarget.checked && this.state.selectedDevice) {
      id = this.state.selectedDevice.getId();
    }
    this.updateDashboardDevice(id);
  };

  private updateDashboardTimestamps(start: number, end: number): void {
    if (this.dashboard) {
      this.dashboard.setParameters({
        startTime: start,
        endTime: end,
      });
    }
  }

  private updateDashboardDevice(deviceId: string): void {
    if (this.dashboard) {
      this.dashboard.setParameters({
        selectedDevice: deviceId,
      });
    }
  }

  private setTimeRange = async (startTimestamp: number, endTimestamp: number): Promise<void> => {
    this.setState({
      startTimestamp,
      endTimestamp,
    });
    this.updateDashboardTimestamps(startTimestamp, endTimestamp);
  };

  private static getTimestampsBySelection(selection: number): Maybe<{
    startTimestamp: number;
    endTimestamp: number;
  }> {
    switch (selection) {
      case 0:
        return {
          startTimestamp: TimeWrapper.getStartOfXDaysAgo(0),
          endTimestamp: Date.now(),
        };
      case 1:
        return {
          startTimestamp: TimeWrapper.getStartOfXDaysAgo(1),
          endTimestamp: TimeWrapper.getStartOfXDaysAgo(0),
        };
      case 2:
        return {
          startTimestamp: TimeWrapper.getTimestampXDaysAgo(7),
          endTimestamp: Date.now(),
        };
      case 3:
        return {
          startTimestamp: TimeWrapper.getTimestampXDaysAgo(14),
          endTimestamp: Date.now(),
        };
      case 4:
        return {
          startTimestamp: TimeWrapper.getTimestampXDaysAgo(30),
          endTimestamp: Date.now(),
        };
    }
  }

  private setSelection = (selection?: number): void => {
    if (selection != null) {
      const queryArguments = StatisticsView.getTimestampsBySelection(selection);

      if (queryArguments) {
        this.setState({
          selection,
          startTimestamp: queryArguments.startTimestamp,
          endTimestamp: queryArguments.endTimestamp,
        });
        this.updateDashboardTimestamps(queryArguments.startTimestamp, queryArguments.endTimestamp);
      }
    } else {
      this.setState({
        selection,
      });
    }
  };

  private isDisabled = (): boolean => {
    return this.state.selection !== null;
  };

  private getToolbar(): JSX.Element {
    return (
      <Fragment>
        <div className="live-control-container">
          <span id="only-selected-device-label">Only selected device</span>
          <Checkbox
            checked={this.state.onlySelectedDevice}
            onChange={this.onOnlySelectedDeviceChange}
            color="primary"
            className="only-selected-device-checkbox"
          />
        </div>
        <DropdownSelection
          label="Period"
          selectionList={["Today", "Yesterday", "Last 7 days", "Last 14 days", "Last 30 days"]}
          emptySelectionItem="Custom"
          onSelected={this.setSelection}
        />
        <TimeRangePicker
          startTimestamp={this.state.startTimestamp}
          endTimestamp={this.state.endTimestamp}
          onTimeRangeSelect={this.setTimeRange}
          disabled={this.isDisabled()}
        />
      </Fragment>
    );
  }

  private handleCloseErrorNote = (): void => {
    this.setState({ errorMsg: undefined });
  };

  private renderDashboard(): JSX.Element {
    const suffix = this.state.isLoading ? ".hidden" : "";
    const className = `iot-content-container${suffix} col-sm-12 col-xsm-12`;

    if (this.state.isLoading) {
      return (
        <React.Fragment>
          <div id="dashboardContainer" className={className}/>
          <Loader />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div id="dashboardContainer" className={className}/>
        </React.Fragment>
      );
    }
  }

  private renderErrorNote(): Maybe<JSX.Element> {
    if (this.state.errorMsg) {
      return (
        <ErrorDialog
          errorMsg={this.state.errorMsg}
          onClose={this.handleCloseErrorNote}
        />
      );
    }
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        <div className="iot-tool-container col-sm-8 col-xsm-7">
          {this.getToolbar()}
        </div>
        {this.renderErrorNote()}
        {this.renderDashboard()}
      </Fragment>
    );
  }
}
