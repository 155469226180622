/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Button } from "@material-ui/core";
import React, { FunctionComponent, PropsWithChildren, ReactElement } from "react";

interface Props {
  changesMade: boolean;
  actionButtonText: string;
  onSave: () => void;
  onCancel: () => void;
}

const SettingsControls: FunctionComponent<Props> = (props: PropsWithChildren<Props>): ReactElement => {
  return (
    <div className="buttons-container">
      <Button
        variant="contained"
        color="primary"
        disabled={!props.changesMade}
        className="button left"
        onClick={props.onSave}
      >
        {props.actionButtonText}
      </Button>
      <Button
        variant="contained"
        color="primary"
        className="button"
        onClick={props.onCancel}
      >
        Close
      </Button>
    </div>
  );
};

export default SettingsControls;
