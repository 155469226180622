/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import Button from "@material-ui/core/Button";
import React, { Component, Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RouteComponentPropsParams } from "../../../types/routerprops";
import { ClientProperties, StatusEntry } from "../../../data/clientSpecific/ClientProperties";
import { getDisplayName } from "../../../data/utils/utils";
import DeviceSettingsButton from "../../device-settings/device-settings-button";
import { Data } from "../../../data/data/Data";
import Device from "../../../data/device/Device";
import { Box, Grid } from "@material-ui/core";

interface Props extends RouteComponentProps<RouteComponentPropsParams> {
  popupData: Data;
  selectedDevice: Device;
}

class StatusPopup extends Component<Props> {

  public constructor(props: Props) {
    super(props);
  }

  private openHistory = (): void => {
    this.props.history.push("/history/" + this.props.selectedDevice.getId());
  };

  private static convertToLocalTime(timestamp: number): string {
    return new Date(timestamp).toLocaleString();
  }

  private getTableBody(): JSX.Element[] {
    const entries: StatusEntry[] = ClientProperties.getOverlayPopupEntries(this.props.popupData);
    return entries.map((e: StatusEntry) => {
      return <tr key={e.title}><td><b>{e.title}</b></td><td>{e.value}</td></tr>;
    });
  }

  private renderStatusBody(): JSX.Element {
    return (
      <div className="status-container">
        <p>{StatusPopup.convertToLocalTime(this.props.popupData.timestamp)}</p>
        <table>
          <tbody>
            {this.getTableBody()}
          </tbody>
        </table>
        {this.renderButtons()}
      </div>
    );
  }

  private renderButtons(): JSX.Element {
    return (
      <Box mb={2}>
        <Grid container justify="center">
          <Grid item>
            <DeviceSettingsButton
              device={this.props.selectedDevice}
              isIcon={false}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className="button"
              onClick={this.openHistory}
            >
              History
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        <div className="status-popup-container">
          <div className="status-popup-header">
            <img className="device-icon" alt="" src={this.props.selectedDevice.getIcon()} />
            {getDisplayName(this.props.selectedDevice)}
          </div>
          <div className="status-popup-body">
            {this.renderStatusBody()}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(StatusPopup);
