/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Button } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import AuthWrapper from "../../data/auth/authWrapper";
import PasswordForm from "./components/password-form";
import AttributeForm from "./components/attribute-form";

export interface Props {
}

interface State {
}

export default class UserView extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);

    this.state = {
    };  
  }

  private renderLogOutButton = (): JSX.Element => {
    return (
      <div className="user-buttons">
        <Button
          variant="contained"
          color="primary"
          onClick={this.logOut}
          className="logoutbutton"
        >
          Log Out
        </Button>
      </div>
    ); 
  };

  private logOut = async (): Promise<void> => {
    if (!this.alertWithConfirm()) {
      console.log("LogOut cancelled by the user.");
      return;
    } else {
      console.log("Logging out the user.");
      await AuthWrapper.logOut();
    }
  };

  public alertWithConfirm (): boolean {
    // TODO: replace with Material Dialog
    const alertConfirmation = window.confirm("You are about to log out. Proceed?");
    
    if (alertConfirmation){
      console.log("OK pressed on Alert Confirmation Popup.");
      return true;
    } else {
      console.log("Cancel pressed on Alert Confirmation Popup.");
      return false;
    }
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        <section className="user-page-container"> 

          <section className="user-container" >
            <div className="user-content" >
              <div style={{ position: "absolute", top: "3%" }}>
                <AttributeForm />
                <PasswordForm />
                <h3 className="user-header">
                  Do you want to log out?
                </h3>
                {this.renderLogOutButton()}
              </div>
            </div>
          </section >

        </section>
      </Fragment >
    );
  }
}
