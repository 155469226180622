/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Button, Grid, List } from "@material-ui/core";
import React, { ReactNode } from "react";
import OtaManager from "../../../../data/ota/otaManager";
import { OtaState, OtaUpdate, OtaUpdateState } from "../../../../data/ota/otaTypes";
import { OtaUpdateListener } from "../../../../data/ota/otaUpdateListener";
import SettingsListItem from "../settings-list-item";
import OtaPackageInformationItem from "./ota-package-information-item";
import { OtaPageProps } from "./ota-page-props";
import OtaProgressInformation from "./ota-progress-information";
import { ReferenceHWState } from "../../../../client/devices/ReferenceHW/ReferenceHWState";
import { ReferenceHWStateProperties } from "../../../../client/devices/ReferenceHW/ReferenceHWStateProperties";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const semverCompare = require("semver/functions/compare");

interface Props extends OtaPageProps {
  otaManager: OtaManager;
}

interface State {
  availableUpdates: OtaUpdate[];
  selectedOtaPackage?: string;
  currentOtaUpdate?: OtaUpdateState;
}

export default class SettingsPageOtaInjectable extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      availableUpdates: [],
      selectedOtaPackage: props.deviceState.otaId ?? undefined,
    };
  }

  public componentDidMount(): void {
    this.props.otaManager.addListener(this.otaListener);
    this.updateAvailableOTAs(true).then();
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.deviceState !== prevProps.deviceState) {
      this.setState({
        selectedOtaPackage: this.props.deviceState.otaId ?? undefined,
      });
      this.updateAvailableOTAs(true).then();
    }
  }

  public componentWillUnmount(): void {
    this.props.otaManager.removeListener(this.otaListener);
  }

  private isOtaOngoing = (): boolean => {
    return !!this.state.currentOtaUpdate && this.state.currentOtaUpdate.updateState === OtaState.InProgress;
  };

  private updateAvailableOTAs = async (force?: boolean): Promise<void> => {
    const updates = await this.props.otaManager.getOtaUpdates(force);
    console.log("selected ota package is " + this.state.selectedOtaPackage);
    this.setState({
      availableUpdates: updates
        .filter((update: OtaUpdate) => update.enabled)
        .sort((a, b) => semverCompare(b.firmwareVersion, a.firmwareVersion)),
    });
  };

  private handleSelectOta = (id: string): void => {
    this.setState({ selectedOtaPackage: id });
  };

  private startInstall = (): void => {
    const ota = this.state.selectedOtaPackage;

    if (!ota) {
      return;
    }

    if (!this.isOtaOngoing()) {
      this.props.otaManager.triggerDeviceOtaUpdate(this.props.deviceState.deviceId, ota).then();
    }
  };

  private cancelInstall = (): void => {
    if (this.state.currentOtaUpdate) {
      this.props.otaManager.cancelDeviceOtaUpdate(this.state.currentOtaUpdate.deviceId).then();
    }
  };

  private otaListener: OtaUpdateListener = {
    onOtaUpdateState: (state: OtaUpdateState): void => {
      if (state.deviceId === this.props.deviceState.deviceId) {
        this.setState({ currentOtaUpdate: state });
      }
    },
  };

  private getInstallButton(): JSX.Element {
    return (
      <Button
        disabled={!this.state.selectedOtaPackage}
        variant="contained"
        color="primary"
        className="button left"
        onClick={this.startInstall}
      >
        Install OTA
      </Button>
    );
  }

  private getCancelButton(): ReactNode {
    return (
      <Button
        variant="contained"
        className="button left"
        onClick={this.cancelInstall}
      >
        Cancel OTA
      </Button>
    );
  }

  private renderSectionHeader(text: string): ReactNode {
    return (<h4>{text}</h4>);
  }
  
  private renderOtaDeviceInformation(): ReactNode {
    const firmwareVersion = (this.props.deviceState as ReferenceHWState<ReferenceHWStateProperties>).firmwareVersion ?? "N/A"; 
    return (
      <List>
        <SettingsListItem label={"Firmware version"}>
          {firmwareVersion}
        </SettingsListItem>
        <SettingsListItem label={"OTA id"}>
          {this.props.deviceState.otaId || "N/A"}
        </SettingsListItem>
      </List>
    );
  }

  private renderOtaProgressInformation(): ReactNode {
    return this.state.currentOtaUpdate
      ? (<OtaProgressInformation update={this.state.currentOtaUpdate}/>)
      : (<p>No installation in progress. Select a package to install!</p>);
  }

  private renderUpdatePackageListing(): ReactNode {
    if (!this.state.availableUpdates || this.state.availableUpdates.length === 0) {
      return <p>No update packages available!</p>;
    }
    // force contents to be scrollable - otherwise the whole popup will be scrollable.
    return <div style={{ height: "300px", overflowY: "auto" }}>
      {this.state.availableUpdates.map((update: OtaUpdate): JSX.Element => {
        return <OtaPackageInformationItem
          key={update.otaId}
          item={update}
          selected={this.state.selectedOtaPackage === update.otaId}
          onClick={(): void => this.handleSelectOta(update.otaId)}
        />;
      })}
    </div>;
  }

  public render(): ReactNode {
    return (
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6}>
          {this.renderSectionHeader("Device Firmware Information")}
          {this.renderOtaDeviceInformation()}
          {this.renderSectionHeader("OTA Update Process")}
          {this.renderOtaProgressInformation()}
        </Grid>
        <Grid item={true} xs={6}>
          {this.renderSectionHeader("Available OTA Packages")}
          {this.renderUpdatePackageListing()}
        </Grid>
        <Grid item={true} xs={12}>
          {
            !this.isOtaOngoing() ? this.getInstallButton() : this.getCancelButton()
          }
        </Grid>
      </Grid>
    );
  }
}
