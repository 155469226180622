/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component } from "react";
import AuthWrapper from "../data/auth/authWrapper";
import LoginForm from "../components/login/login-form";
import CompleteAccountCreationForm from "../components/login/complete-account-creation-form";
import ForgotPasswordForm from "../components/login/forgot-password-form";
import { isValidEmail } from "../utils/validation";

interface State {
  password: string;
  username: string;
  newPassword: string;
  confirmPassword: string;
  phase: string;
  verificationCode: string;
  // TODO: Fix any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any;
  loader: boolean;
  // TODO: Fix any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  passwordError: boolean;
  usernameError: boolean;
}

interface Props {
}

export default class LoginContent extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      confirmPassword: "",
      loader: false,
      newPassword: "",
      password: "",
      phase: "",
      user: {},
      username: "",
      verificationCode: "",
      passwordError: false,
      usernameError: false,
      beErrorMessage: "",
    };
  }

  private userInput(key: string, value: string): void {
    this.setState({
      [key]: value,
      usernameError: false,
      passwordError: false,
    });
  }

  private renderInputFields(): JSX.Element {
    switch (this.state.phase) {
      case "confirmPassword":
        console.log ("login-content phase: ", this.state.phase);
        return (
          <CompleteAccountCreationForm
            loader={this.state.loader}
            onCompletePassword={(): Promise<void> => this.completePassword()}
            userInput={(key: string, value: string): void => this.userInput(key, value)}
            confirmPassword={this.state.confirmPassword}
            newPassword={this.state.newPassword}
            beErrorMessage={this.state.beErrorMessage}
          />
        );
      case "forgotPassword":
        console.log ("login-content phase: ", this.state.phase);
        return (
          <ForgotPasswordForm
            loader={this.state.loader}
            submitNewPasswordAndCheckVerificationCode={(): Promise<void> => this.submitNewPasswordAndCheckVerificationCode()}
            userInput={(key: string, value: string): void => this.userInput(key, value)}
            confirmPassword={this.state.confirmPassword}
            newPassword={this.state.newPassword}
            verificationCode={this.state.verificationCode}
            beErrorMessage={this.state.beErrorMessage}
            directAccessToMainLogInView={(): Promise<void> => this.directAccessToMainLogInView()}
          />
        );
      default:
        return (
          <LoginForm
            loader={this.state.loader}
            logIn={(): Promise<void> => this.logIn()}
            userInput={(key: string, value: string): void => this.userInput(key, value)}
            username={this.state.username}
            password={this.state.password}
            passwordError={this.state.passwordError}
            usernameError={this.state.usernameError}
            forgotPassword={(): Promise<void> => this.forgotPassword()}
            beErrorMessage={this.state.beErrorMessage}
            directAccessToForgotPasswordView={(): Promise<void> => this.directAccessToForgotPasswordView()}
          />
        );
    }
  }

  // New user: After pressing "Log In with new password", have the new password saved, and log in: 
  public async completePassword(): Promise<void> {
    try {
      this.setState({ loader: true });
      await AuthWrapper.completeNewPassword(this.state.user, this.state.newPassword);
    } catch (error) {
      console.error(error);

      switch (error.toString()) {  // cases mapped based on authWrapper error messages:
        case "Error: Password does not conform to policy: Password must have numeric characters":
          this.setState({
            loader: false,
            beErrorMessage: "InvalidPasswordException. Password does not conform to policy. Password must have numeric characters.", 
          });
          break;
        case "Error: Password does not conform to policy: Password must have lowercase characters":
          this.setState({
            loader: false,
            beErrorMessage: "InvalidPasswordException. Password does not conform to policy. Password must have lowercase characters.", 
          });
          break;
        case "Error: 1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
          this.setState({
            loader: false,
            beErrorMessage: "InvalidParameterException. Password does not conform to policy. Password that you provided is not long enough.",
          });
          break;
        case "Error: Password does not conform to policy: Password not long enough":
          this.setState({
            loader: false,
            beErrorMessage: "InvalidPasswordException. Password does not conform to policy. Password that you provided is not long enough.", 
          });
          break;
        case "Error: Invalid session for the user, session is expired.":
          this.setState({
            loader: false,
            beErrorMessage: "NotAuthorizedException. Invalid session for the user, session is expired. Please refresh the browser and try again.", 
          });
          break;
        default:
          this.setState({
            loader: false,
            beErrorMessage: "General Backend Error, Unknown/Unusual Error, or Multiple Errors. Unable to perform action, please try again. If this problem persists, contact your admin.",
          });
      }
    }
  }

  // Existing user: After pressing "Confirm and Log In", have the verification code checked from the backend, then the new password saved, and finally log in:
  public async submitNewPasswordAndCheckVerificationCode(): Promise<void> {
    try {
      this.setState({ loader: true });
      await AuthWrapper.checkCodeAndSubmitNewPassword(this.state.username, this.state.verificationCode, this.state.newPassword);
      await AuthWrapper.logIn(this.state.username, this.state.newPassword);
    } catch (error) {
      console.error(error);

      switch (error.toString()) {  // cases mapped based on authWrapper error messages:
        case "Error: Attempt limit exceeded, please try after some time.":
          this.setState({
            loader: false,
            beErrorMessage: "LimitExceededException. You have tried too many times within a short period of time. Please try again later.",
          });
          break;
        case "Error: Username/client id combination not found.":
          this.setState({
            loader: false,
            beErrorMessage: "UserNotFoundException. User does not exist. We could not find a user with the email you provided. Please check the email address.",
          });
          break;
        case "Error: Invalid verification code provided, please try again.":
          this.setState({
            loader: false,
            beErrorMessage: "CodeMismatchException. Invalid verification code provided, please check and try again.",
          });
          break;
        case "Error: Invalid code provided, please request a code again.":
          this.setState({
            loader: false,
            beErrorMessage: "ExpiredCodeException. Invalid code provided: code expired. Please request a new verification code.",
          });
          break;
        case "Error: Network error":
          this.setState({
            loader: false,
            beErrorMessage: "NetworkError. There was an error with the network. Please check your network connection and try again.",
          });
          break;
        case "Error: Password does not conform to policy: Password must have numeric characters":
          this.setState({
            loader: false,
            beErrorMessage: "InvalidPasswordException. Password does not conform to policy. Password must have numeric characters.", 
          });
          break;
        case "Error: Password does not conform to policy: Password must have lowercase characters":
          this.setState({
            loader: false,
            beErrorMessage: "InvalidPasswordException. Password does not conform to policy. Password must have lowercase characters.", 
          });
          break;
        case "Error: 1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
          this.setState({
            loader: false,
            beErrorMessage: "InvalidParameterException. Password does not conform to policy. Password that you provided is not long enough.",
          });
          break;
        case "Error: Password does not conform to policy: Password not long enough":
          this.setState({
            loader: false,
            beErrorMessage: "InvalidPasswordException. Password does not conform to policy. Password that you provided is not long enough.", 
          });
          break;
        case "Error: Invalid session for the user, session is expired.":
          this.setState({
            loader: false,
            beErrorMessage: "NotAuthorizedException. Invalid session for the user, session is expired. Please refresh the browser and try again.", 
          });
          break;
        default:
          this.setState({
            loader: false,
            beErrorMessage: "General Backend Error, Unknown/Unusual Error, or Multiple Errors. Unable to perform action, please try again. If this problem persists, contact your admin.",
          });
      }
    }
  }

  public alertWithConfirm (): boolean {
    const alertConfirmation = window.confirm("Verification code will be sent to your email. If you don´t find it in your inbox, please check your junk mail folder before requesting a new one.");

    if (alertConfirmation){
      console.log("OK pressed on Alert Confirmation Popup.");
      return true;
    } else {
      console.log("Cancel pressed on Alert Confirmation Popup.");
      return false;
    }
  }

  // Existing user: After pressing "Forgot Password? Send me a Verification Code.", check if the given email format is valid, and if yes, continue: 
  public async forgotPassword(): Promise<void> {
    console.log("Forgot Password pressed!");

    if (!isValidEmail(this.state.username)) {
      console.log("Email format not valid. Please check.");
      this.setState({ beErrorMessage: "Cannot continue: Email format is not valid. Please check." });
      return;
    } else {
      // Before sending the verification code, request "OK" from the user on Alert Popup:
      if (!this.alertWithConfirm()) {
        console.log("Cancelled. Returning to main log in page.");
        this.setState({ beErrorMessage: "" }); // Clear possible previous error messages from UI
        return;
      } else {
        console.log("Sending the verification code to email ", this.state.username, " next.");

        try {
          this.setState({ loader: true });
          await AuthWrapper.forgotPassword(this.state.username);
          // Bring up the UI for entering the verification code and new password: 
          this.setState({
            loader: false,
            phase: "forgotPassword",
            username: this.state.username,
            beErrorMessage: "", // Clear possible previous error messages from UI
          });
        } catch (error) {
          console.error(error);

          switch (error.code) { // cases mapped based on authWrapper error codes:
            case "LimitExceededException":
              this.setState({ 
                loader: false,
                beErrorMessage: "Error occurred: LimitExceededException. You have tried too many times within a short period of time. Please try again later.",
              });
              break;
            case "NetworkError":
              this.setState({ 
                loader: false,
                beErrorMessage: "Error occurred: NetworkError. There was an error with the network. Please check your network connection and try again.",
              });
              break;
            case "UserNotFoundException":
              this.setState({
                loader: false,
                usernameError: true,
                passwordError: false,
                beErrorMessage: "Error occurred: UserNotFoundException. User does not exist. We could not find a user with the email you provided. Please check the email address.",
              });
              break;
            case "NotAuthorizedException":
              this.setState({
                loader: false,
                usernameError: false,
                passwordError: true,
                beErrorMessage: "Error occurred: NotAuthorizedException. User password cannot be reset in the current state. Are you a new user and you tried to request a new password/verification code? You need to enter your temporary password in the text box above to continue. If you did not receive an email with your temporary password, please contact your admin.", 
              });
              break;  
            default:
              this.setState({ 
                loader: false,
                beErrorMessage: "Error occurred: General Backend Error, Unknown/Unusual Error, or Multiple Errors. Unable to perform action, please try again. If this problem persists, contact your admin.",
              });
          }
        }
      }
    }
  }
  
  // Existing user: After pressing "I already have a Verification Code (enter code here).", check if the given email format is valid, and if yes, change UI:
  public async directAccessToForgotPasswordView(): Promise<void> {
    if (!isValidEmail(this.state.username)) {
      console.log("Email format not valid. Please check.");
      this.setState({ beErrorMessage: "Cannot continue: Email format is not valid. Please check." });
      return;
    } else {
      // Bring up the UI for entering the verification code and new password: 
      this.setState({
        loader: false,
        phase: "forgotPassword",
        username: this.state.username,
        beErrorMessage: "", // Clear possible previous error messages from UI
      });
      console.log("User is: ", this.state.username, " (typed address, not authenticated)");
    }
  }

  // Existing user: After pressing "Back to Main Log In Page ", change UI:
  public async directAccessToMainLogInView(): Promise<void> {
    // Bring up the main Log In UI: 
    this.setState({
      loader: false,
      phase: "",
      username: this.state.username,
      beErrorMessage: "", // Clear possible previous error messages from UI
    });
  }
  
  public async logIn(): Promise<void> {
    if (this.state.username === "" && this.state.password === "") {
      // Do nothing
    } else {
      try {
        this.setState({ loader: true });
        const user = await AuthWrapper.logIn(this.state.username, this.state.password);

        switch (user.challengeName) {
          // Bring up the UI for entering and confirming the new password: 
          case "NEW_PASSWORD_REQUIRED":
            this.setState({
              loader: false,
              phase: "confirmPassword",
              user,
              beErrorMessage: "", // Clear possible previous error messages from UI
            });
            break;
        }
      } catch (error) {
        console.error(error);
        this.setState({ loader: false });

        switch (error.message) { // cases mapped based on authWrapper error messages:
          case "Username cannot be empty":
            console.log("Error occurred: AuthError. Username cannot be empty. Please check and try again.");
            this.setState({
              usernameError: true,
            });
            break;
          case "Empty password":
            console.log("Error occurred: Empty Password. InvalidPasswordException. Password cannot be empty. Please check and try again.");
            this.setState({
              passwordError: true,
            });
            break;
          case "User does not exist.":
            this.setState({
              usernameError: true,
              passwordError: false,
              beErrorMessage: "Error occurred: UserNotFoundException. User does not exist. We could not find a user with the email you provided. Please check the email address.",
            });
            break;
          case "Incorrect username or password.":
            this.setState({
              usernameError: true,
              passwordError: true,
              beErrorMessage: "Error occurred: NotAuthorizedException. Incorrect username/password combination. Probably incorrect password. Please check and try again.",
            });
            break;
          case "Temporary password has expired and must be reset by an administrator.":
            this.setState({
              usernameError: false,
              passwordError: true,
              beErrorMessage: "Error occurred: NotAuthorizedException. Temporary password has expired and must be reset by an administrator.",
            });
            break;
          default:
            this.setState({
              usernameError: false,
              passwordError: false,
              beErrorMessage: "Error occurred: General Backend Error, Unknown/Unusual Error, or Multiple Errors. Unable to perform action, please try again. If this problem persists, contact your admin.",
            });
            break;
        }
      }
    }
  }

  public render(): JSX.Element {
    return (
      <section className="login-container col-md-12">
        <div className="login-content col-lg-4 col-md-6 col-sm-6 col-xsm-8">
          {this.renderInputFields()}
        </div>
      </section >
    );
  }

}
