/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { ChangeEvent, Component, Fragment } from "react";
import Loader from "../ui/loader";
import { Maybe } from "../../types/aliases";
import { Button, TextField, IconButton, InputAdornment } from "@material-ui/core";
import VisibilityOnIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

export interface Props {
  loader: boolean;
  newPassword: string;
  confirmPassword: string;
  userInput: (inputId: string, value: string) => void;
  verificationCode: string;
  // TODO: Fix any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitNewPasswordAndCheckVerificationCode: any;
  beErrorMessage: string;
  directAccessToMainLogInView: () => void;
}

interface State {
  showNewPassword: boolean;
  showConfirmPassword: boolean;
}

export default class ForgotPasswordForm extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      showNewPassword: false,
      showConfirmPassword: false,
    };
  }

  private renderLoader = (): Maybe<JSX.Element> => {
    if (this.props.loader) {
      return <Loader />;
    }
  };

  // This is the UI where an existing user´s verification code is asked, and a new password is entered and confirmed before the Log In. 
  private renderInputs(): JSX.Element {
    const isEnabled = this.props.verificationCode.length > 0 && this.props.newPassword.length > 0 && this.props.confirmPassword.length > 0;
    return (
      <Fragment>
        <div className="login-fields">
          <TextField
            className="text-field"
            label="Code from email"
            type="text"
            autoComplete="current-password"
            margin="normal"
            variant="outlined"
            defaultValue={this.props.verificationCode}
            onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>):
            void => this.props.userInput("verificationCode", event.target.value)}
            fullWidth={true}
          />
          <TextField
            className="text-field"
            label="New password"
            type={this.state.showNewPassword ? "text" : "password"}
            autoComplete="current-password"
            margin="normal"
            variant="outlined"
            defaultValue={this.props.newPassword}
            onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>):
            void => this.props.userInput("newPassword", event.target.value)}
            fullWidth={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    className="visibility-icon"
                    edge="end"
                    onClick={this.toggleNewPasswordVisibility}
                  >
                    {this.state.showNewPassword ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className="text-field"
            label="Confirm new password"
            type={this.state.showConfirmPassword ? "text" : "password"}
            autoComplete="current-password"
            margin="normal"
            variant="outlined"
            defaultValue={this.props.confirmPassword}
            onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>):
            void => this.props.userInput("confirmPassword", event.target.value)}
            fullWidth={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    className="visibility-icon"
                    edge="end"
                    onClick={this.toggleConfirmPasswordVisibility}
                  >
                    {this.state.showConfirmPassword ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="login-buttons">
          <Button
            disabled={!isEnabled}
            variant="contained"
            color="primary"
            onClick={this.newPasswordSpellCheckAndSubmit}
            className="button"
          >
            Confirm and Log In
          </Button>
        </div>
      </Fragment>
    );
  }

  private toggleNewPasswordVisibility = (): void => {
    this.setState((prevState: State) => ({ showNewPassword: !prevState.showNewPassword }));
  };  

  private toggleConfirmPasswordVisibility = (): void => {
    this.setState((prevState: State) => ({ showConfirmPassword: !prevState.showConfirmPassword }));
  };  

  private newPasswordSpellCheckAndSubmit = (): void => {
    if (this.props.newPassword.length === 0 && this.props.confirmPassword.length === 0) {
      return;
    }
    else if (this.props.newPassword.length > 0 && this.props.confirmPassword.length > 0) {
      if (this.props.newPassword !== this.props.confirmPassword) {
        console.log ("Confirm and Log In pressed. Passwords don´t match. Cannot continue.");
         
      } else {
        console.log ("Confirm and Log In pressed. Passwords match. Proceeding to check the verification code.");
        this.props.submitNewPasswordAndCheckVerificationCode(); // Input for login-content 
      }
    }
    else {
      console.log ("Passwords don´t match. Cannot continue.");
    }
  };

  private renderNewPasswordSpellCheckMessage(): Maybe<JSX.Element> {
    if (this.props.newPassword.length > 0 && this.props.confirmPassword.length > 0) { 
      if (this.props.newPassword !== this.props.confirmPassword) {
        return (
          <div className="login-pwCheckText">
            Passwords you entered don´t match. Check spelling.
          </div>
        );
      } else if (this.props.newPassword === this.props.confirmPassword) {
        return (
          <div className="login-pwCheckText">
            Passwords match.
          </div>
        );
      }
    }
  }

  private backToMainLogInPage(): JSX.Element {
    return (
      <Fragment>
        <div className="login-textSmall" onClick={(): void => this.props.directAccessToMainLogInView()}>
          <br />
          Back to Main Log In Page
        </div> 
      </Fragment>
    );
  }

  private renderBeErrorMessage = (): Maybe<JSX.Element> => {
    if (this.props.beErrorMessage) {
      return (
        <div className="login-errortext">
          An error occurred: {this.props.beErrorMessage}
        </div>
      );
    } 
  };

  public render(): JSX.Element { 
    return (
      <Fragment>
        <div className="login-header">
          Enter Verification code and New password
        </div>
        {this.renderLoader()}
        {this.renderInputs()}
        {this.renderNewPasswordSpellCheckMessage()}
        {this.backToMainLogInPage()}
        {this.renderBeErrorMessage()}
      </Fragment >
    );
  }
}
