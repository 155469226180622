/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { FunctionComponent, PropsWithChildren, ReactElement } from "react";
import { OtaState, OtaUpdateState } from "../../../../data/ota/otaTypes";
import LinearProgressWithLabel from "../../../ui/linear-progress-with-label";
import { Nullable } from "../../../../types/aliases";

interface Props {
  update?: OtaUpdateState;
}

const OtaProgressInformation: FunctionComponent<Props> = (props: PropsWithChildren<Props>): Nullable<ReactElement> => {
  if (!props.update) {
    return null;
  }

  switch (props.update.updateState) {
    case OtaState.Idle:
      return (<p>Pending OTA update of package {props.update.updateFirmwareVersion}</p>);
    case OtaState.InProgress:
      return (
        <React.Fragment>
          <p>Installation of {props.update.updateFirmwareVersion} in progress:</p>
          <LinearProgressWithLabel value={props.update?.progressPercent ?? 0}/>
        </React.Fragment>
      );
    case OtaState.Done:
      return (
        <p>
          Completed OTA update of package {props.update.updateFirmwareVersion} (code: {props.update.resultCode})
        </p>
      );
    case OtaState.Cancelled:
      return (
        <p>
          Cancelled OTA update of package {props.update.updateFirmwareVersion} (code: {props.update.resultCode})
        </p>
      );
  }
  return null;
};

export default OtaProgressInformation;
