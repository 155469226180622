/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { Fragment, ReactNode } from "react";
import DeviceState from "../../../../data/device/DeviceState";
import { Maybe } from "../../../../types/aliases";
import SettingsControls from "../settings-page-general/settings-controls";
import SettingsPageMeasurementPoint from "./settings-page-measurement-point";
import { v4 as uuidv4 } from "uuid";
import { HyperHWState } from "../../../../client/devices/HyperHW/HyperHWState";
import { DeviceStateProperties } from "../../../../data/device/DeviceStateProperties";
import { MeasurementPoint, MeasurementUnit } from "../../../../client/devices/HyperHW/HyperHWStateProperties";

interface Props {
  deviceState: DeviceState<DeviceStateProperties>;
  closeSettings: () => void;
}

interface State {
  changesMade: boolean;
  hasDuplicateIds: boolean;
  hasEmptyIds: boolean;
  hasEmptyNames: boolean;
  hasInvalidConversionMultipliers: boolean;
  hasInvalidTares: boolean;
  measurementPointRows: MeasurementPointRow[];
  rowKeysForDuplicateIds: string[];
}

interface MeasurementPointRow {
  measurementPoint: MeasurementPoint;
  rowKey: string;
}

export default class SettingsPageMeasurementPoints extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    //if state is updated while on page, changes to measurement points will be lost and they are fetched again from props
    //this is okay as the popup will be closed anyway on refresh
    const measurementPointRows = this.extractMeasurementPointsFromProps();
    this.state = {
      measurementPointRows: measurementPointRows,
      changesMade: false,
      hasDuplicateIds: false,
      hasEmptyNames: false,
      hasEmptyIds: false,
      hasInvalidConversionMultipliers: false,
      hasInvalidTares: false,
      rowKeysForDuplicateIds: [],
    };
  }

  public componentDidMount() : void {
    this.checkValidity(this.state.measurementPointRows);
  }

  private extractMeasurementPointsFromProps = (): MeasurementPointRow[] => {
    const measurementPoints: MeasurementPoint[] = this.stateAsHyper.measurementPoints ?? [];
    const measurementPointRows: MeasurementPointRow[] = 
      measurementPoints.map(point => ({ measurementPoint: { inputId: point.inputId, name: point.name, 
        unit: point.unit, conversionMultiplier: point.conversionMultiplier, tare: point.tare }, rowKey: uuidv4() }));  
    return measurementPointRows;
  };

  private get stateAsHyper(): Partial<HyperHWState> {
    return this.props.deviceState;
  }

  private handleCancel = (): void => {
    this.props.deviceState.revert();
    this.props.closeSettings();
  };

  private saveMeasurementPointSettings = async (): Promise<void> => {
    this.setState({ changesMade: false });
    this.stateAsHyper.measurementPoints = this.state.measurementPointRows.map(row => row.measurementPoint);
    await this.props.deviceState.store();
  };

  private handleAddMeasurementPoint = (): void => {
    console.log("Adding new measurement point");
    const newMeasurementPointRows = [...this.state.measurementPointRows];
    newMeasurementPointRows.push({ measurementPoint: { inputId: "", name: "", unit: MeasurementUnit.Celsius, conversionMultiplier: 1.0, tare: 0 }, rowKey: uuidv4() });
    this.setState({ measurementPointRows: newMeasurementPointRows });
    this.checkValidity(newMeasurementPointRows);
  };

  private handleRemoveMeasurementPoint = (rowKey: string): void => {
    const indexToRemove = this.state.measurementPointRows.findIndex(item => item.rowKey === rowKey);

    if (indexToRemove < 0) {
      console.log("Could not find measurement point to remove");
      return;
    }
    console.log(`Removing measurement point from index ${indexToRemove}`);
    const newMeasurementPointRows = [...this.state.measurementPointRows];
    newMeasurementPointRows.splice(indexToRemove, 1);
    this.setState({ measurementPointRows: newMeasurementPointRows });
    this.checkValidity(newMeasurementPointRows);
  };

  private handleIdChanged = (rowKey: string, newId: string): void => {
    const clonedMeasurementPointsAndIndex = this.getClonedMeasurementPointsAndIndexToUpdate(rowKey);

    if (clonedMeasurementPointsAndIndex) {
      console.log(`Updating ID for measurement point from index ${clonedMeasurementPointsAndIndex.indexToUpdate}, new ID: ${newId}`);
      clonedMeasurementPointsAndIndex.array[clonedMeasurementPointsAndIndex.indexToUpdate].measurementPoint.inputId = newId;
      this.setState({ measurementPointRows: clonedMeasurementPointsAndIndex.array });
      this.checkValidity(clonedMeasurementPointsAndIndex.array);
    }       
  };

  private handleNameChanged = (rowKey: string, newName: string): void => {
    const clonedMeasurementPointsAndIndex = this.getClonedMeasurementPointsAndIndexToUpdate(rowKey);

    if (clonedMeasurementPointsAndIndex) {
      console.log(`Updating name for measurement point from index ${clonedMeasurementPointsAndIndex.indexToUpdate}, new name: ${newName}`);
      clonedMeasurementPointsAndIndex.array[clonedMeasurementPointsAndIndex.indexToUpdate].measurementPoint.name = newName;
      this.setState({ measurementPointRows: clonedMeasurementPointsAndIndex.array });
      this.checkValidity(clonedMeasurementPointsAndIndex.array);
    }    
  };

  private handleConversionMultiplierChanged = (rowKey: string, newConversionMultiplier: number): void => {
    const clonedMeasurementPointsAndIndex = this.getClonedMeasurementPointsAndIndexToUpdate(rowKey);

    if (clonedMeasurementPointsAndIndex) {
      console.log(`Updating conversion multiplier for measurement point from index ${clonedMeasurementPointsAndIndex.indexToUpdate}, new multiplier: ${newConversionMultiplier}`);
      clonedMeasurementPointsAndIndex.array[clonedMeasurementPointsAndIndex.indexToUpdate].measurementPoint.conversionMultiplier = newConversionMultiplier;
      this.setState({ measurementPointRows: clonedMeasurementPointsAndIndex.array });
      this.checkValidity(clonedMeasurementPointsAndIndex.array);
    }
  };

  private handleTareChanged = (rowKey: string, newTare: number): void => {
    const clonedMeasurementPointsAndIndex = this.getClonedMeasurementPointsAndIndexToUpdate(rowKey);

    if (clonedMeasurementPointsAndIndex) {
      console.log(`Updating tare for measurement point from index ${clonedMeasurementPointsAndIndex.indexToUpdate}, new tare: ${newTare}`);
      clonedMeasurementPointsAndIndex.array[clonedMeasurementPointsAndIndex.indexToUpdate].measurementPoint.tare = newTare;
      this.setState({ measurementPointRows: clonedMeasurementPointsAndIndex.array });
      this.checkValidity(clonedMeasurementPointsAndIndex.array);
    }
  };

  private handleUnitChanged = (rowKey: string, newUnit: MeasurementUnit): void => {
    const clonedMeasurementPointsAndIndex = this.getClonedMeasurementPointsAndIndexToUpdate(rowKey);

    if (clonedMeasurementPointsAndIndex) {
      console.log(`Updating unit for measurement point from index ${clonedMeasurementPointsAndIndex.indexToUpdate}, new unit: ${newUnit}`);
      clonedMeasurementPointsAndIndex.array[clonedMeasurementPointsAndIndex.indexToUpdate].measurementPoint.unit = newUnit;
      this.setState({ measurementPointRows: clonedMeasurementPointsAndIndex.array });
      this.checkValidity(clonedMeasurementPointsAndIndex.array);
    }
  };
  
  private getClonedMeasurementPointsAndIndexToUpdate = (rowKey: string): Maybe<{ array: MeasurementPointRow[]; indexToUpdate: number }> => {
    const indexToUpdate = this.state.measurementPointRows.findIndex(item => item.rowKey === rowKey);

    if (indexToUpdate < 0) {
      console.log("Could not find measurement point to update");
      return undefined;
    }
    const newMeasurementPointRows = [...this.state.measurementPointRows];
    const newMeasurementPointRow = {
      ...newMeasurementPointRows[indexToUpdate], 
    };
    newMeasurementPointRows[indexToUpdate] = newMeasurementPointRow;
    return { array: newMeasurementPointRows, indexToUpdate: indexToUpdate };
  };

  private checkValidity(newMeasurementPointRows: MeasurementPointRow[]): void {
    let hasEmptyIds = false;
    let hasEmptyNames = false;
    let hasInvalidConversionMultipliers = false;
    let hasInvalidTares = false;
    const seen = new Set();
    const rowKeysForDuplicateIds: string[] = [];
    newMeasurementPointRows.forEach(row => {
      hasEmptyIds ||= row.measurementPoint.inputId === "";
      hasEmptyNames ||= row.measurementPoint.name === "";
      hasInvalidConversionMultipliers ||= (row.measurementPoint.conversionMultiplier <= 0 || row.measurementPoint.conversionMultiplier === undefined);
      hasInvalidTares ||= row.measurementPoint.tare === undefined;
      
      if (seen.size === seen.add(row.measurementPoint.inputId).size) {
        rowKeysForDuplicateIds.push(row.rowKey);
      }
    });

    this.setState({
      hasDuplicateIds: rowKeysForDuplicateIds.length > 0,
      hasEmptyIds: hasEmptyIds,
      hasEmptyNames: hasEmptyNames,
      hasInvalidConversionMultipliers: hasInvalidConversionMultipliers,
      hasInvalidTares: hasInvalidTares,
      changesMade: true,
      rowKeysForDuplicateIds: rowKeysForDuplicateIds,
    });
  }

  private getEmptyArrayText(): Maybe<JSX.Element> {
    if (this.state.measurementPointRows.length === 0) {
      return <h4>No measurement points defined. Add the first one by clicking the + button below.</h4>;
    }
  }

  private getDuplicateIdsText(): Maybe<JSX.Element> {
    if (this.state.hasDuplicateIds) {
      return <h4 style={{ color: "red" }}>Measurement point IDs need to be unique!</h4>;
    }
  }

  private getEmptyIdText(): Maybe<JSX.Element> {
    if (this.state.hasEmptyIds) {
      return <h4 style={{ color: "red" }}>Measurement point ID cannot be empty!</h4>;
    }
  }

  private getEmptyNameText(): Maybe<JSX.Element> {
    if (this.state.hasEmptyNames) {
      return <h4 style={{ color: "red" }}>Measurement point name cannot be empty!</h4>;
    }
  }

  private getInvalidConversionMultiplierText(): Maybe<JSX.Element> {
    if (this.state.hasInvalidConversionMultipliers) {
      return <h4 style={{ color: "red" }}>Conversion multiplier needs to be a positive number!</h4>;
    }
  }  

  private getInvalidTareText(): Maybe<JSX.Element> {
    if (this.state.hasInvalidTares) {
      return <h4 style={{ color: "red" }}>Tare cannot be empty!</h4>;
    }
  }  

  public render(): ReactNode {
    return (
      <Fragment>
        {
          this.state.measurementPointRows.map((row) => {
            return (
              <SettingsPageMeasurementPoint key={row.rowKey}
                inputId={row.measurementPoint.inputId}
                name={row.measurementPoint.name}
                unit={row.measurementPoint.unit}
                conversionMultiplier={row.measurementPoint.conversionMultiplier}
                tare={row.measurementPoint.tare}
                rowKey={row.rowKey}
                isDuplicateId={this.state.rowKeysForDuplicateIds.includes(row.rowKey)}
                onRemove={this.handleRemoveMeasurementPoint}
                onIdChanged={this.handleIdChanged}
                onNameChanged={this.handleNameChanged}
                onConversionMultiplierChanged={this.handleConversionMultiplierChanged} 
                onUnitChanged={this.handleUnitChanged} 
                onTareChanged={this.handleTareChanged} />
            );
          })
        }
        {this.getEmptyArrayText()}
        {this.getDuplicateIdsText()}
        {this.getEmptyIdText()}
        {this.getEmptyNameText()}
        {this.getInvalidConversionMultiplierText()}
        {this.getInvalidTareText()}
        <IconButton
          title="Add new measurement point"
          onClick={this.handleAddMeasurementPoint}>
          <AddIcon />
        </IconButton>
        <SettingsControls
          changesMade={this.state.changesMade && !this.state.hasDuplicateIds && !this.state.hasEmptyIds && !this.state.hasEmptyNames && !this.state.hasInvalidConversionMultipliers && !this.state.hasInvalidTares}
          actionButtonText="Apply"
          onSave={this.saveMeasurementPointSettings}
          onCancel={this.handleCancel} />
      </Fragment>
    );
  }
}
