/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import AWSBackend from "./data/backend/AWSBackend";
import Backend from "./data/backend/Backend";
import OrganizationBackend from "./data/organization/OrganizationBackend";
import { AWSOrganizationBackend } from "./data/organization/AWSOrganizationBackend";

export default class BackendFactory {
  private static genericBackend: Backend;
  private static organizationBackend: OrganizationBackend;
  
  public static getBackend(): Backend {
    if (!BackendFactory.genericBackend) {
      BackendFactory.genericBackend = new AWSBackend();
    }
    return BackendFactory.genericBackend;
  }
  
  public static getOrganizationBackend(): OrganizationBackend {
    if (!BackendFactory.organizationBackend) {
      BackendFactory.organizationBackend = new AWSOrganizationBackend();
    }
    return BackendFactory.organizationBackend;
  }
}
