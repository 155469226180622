/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import BaseObservable from "../observer/BaseObservable";
import { Data } from "./Data";
import { DataHolder, GeneratorConfig } from "./DataHolder";
import { PromiseSemaphore } from "../utils/PromiseSemaphore";

export interface DataObserver<TData extends Data = Data> {
  onDataUpdate(subject: DataSet<TData>): void;
}

export interface DataSetOptions<TData extends Data> {
  syntheticDataGeneratorConfig?: GeneratorConfig<TData>;
}

export abstract class DataSet<TData extends Data = Data> extends BaseObservable<DataObserver<TData>> {
  private readonly syntheticDataGeneratorConfig?: GeneratorConfig<TData>;

  protected readonly fetchSemaphore = new PromiseSemaphore(() => this.fetchData());
  protected dataHolder: DataHolder<TData>;

  protected constructor(options: DataSetOptions<TData> = {}) {
    super();
    this.syntheticDataGeneratorConfig = options.syntheticDataGeneratorConfig;
    this.dataHolder = DataHolder.create([], this.syntheticDataGeneratorConfig);
  }

  public abstract getId(): string;

  public setData(data: TData[]): void {
    this.dataHolder = DataHolder.create(data, this.syntheticDataGeneratorConfig);
  }

  public getData(): TData[] {
    return this.dataHolder.data;
  }

  // TODO:  improve performance for injecting singular data point
  //        the current implementation is for plastering arrays together.
  public appendData(data: TData): void {
    this.dataHolder.addData([data]);
    this.notifyAction(observer => observer.onDataUpdate(this));
  }

  public async initializeData(): Promise<void> {
    return this.fetchSemaphore.guard();
  }

  public abstract getType(): string;

  public abstract getStartTimestamp(): number;

  public abstract getEndTimestamp(): number;

  protected abstract fetchData(): Promise<void>;
}

export default DataSet;
