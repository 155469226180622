/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import Map from "ol/Map";
import OSM from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import Icon from "ol/style/Icon";
import Style from "ol/style/Style";
import View from "ol/View";
import React, { Component } from "react";
import { Data } from "../../../data/data/Data";
import Marker from "../../../assets/baseline-place-24px.svg";
import { Nullable } from "../../../types/aliases";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const proj = require("ol/proj");

interface Props {
  selectedLocation: Nullable<number[]>;
  mapsData: Nullable<Data[]>;
}
interface State { }

const DEFAULT_CENTER: [number, number] = [0, 0];
const ZOOM_DEFAULT = 2;
const ZOOM_VALUE = 11;

export default class IoTMap extends Component<Props, State> {
  // REFACTOR: Think of extracting map functionality behind interface so that
  // we can easily swap the map provider.
  private map!: Map;
  private selectedMarkerLayer?: VectorLayer;
  private mapPointsLayer?: VectorLayer;

  public componentDidMount(): void {
    this.renderMap();

    if (this.props.mapsData && this.props.mapsData.length > 0) {
      this.renderMapPointMarkers();

      if (this.props.selectedLocation && this.props.selectedLocation.length !== 0) {
        this.renderSelectedMarker();
        this.renderOverlay();
      }
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.mapsData !== this.props.mapsData) {
      if (this.mapPointsLayer) {
        this.map.removeLayer(this.mapPointsLayer);
        this.mapPointsLayer = undefined;
      }
      this.renderMapPointMarkers();
    }

    if (prevProps.selectedLocation !== this.props.selectedLocation && this.props.selectedLocation !== null &&
            this.props.selectedLocation.length !== 0) {
      if (this.selectedMarkerLayer) {
        this.map.removeLayer(this.selectedMarkerLayer);
        this.selectedMarkerLayer = undefined;
      }
      this.renderSelectedMarker();
      this.renderOverlay();
    }

    if (this.props.selectedLocation === null && prevProps.selectedLocation !== this.props.selectedLocation && this.selectedMarkerLayer) {
      this.map.removeLayer(this.selectedMarkerLayer);
      this.selectedMarkerLayer = undefined;
    }
  }

  private renderSelectedMarker(): void {
    const iconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src: Marker,
      }),
    });
    const markers: Feature[] = [];

    if (this.props.selectedLocation && this.props.selectedLocation[0] && this.props.selectedLocation[1]) {
      const iconFeature = new Feature({
        geometry: new Point(proj.fromLonLat([this.props.selectedLocation[0],
          this.props.selectedLocation[1]])),
        properties: this.props.selectedLocation,
        type: "marker",
      });

      iconFeature.setStyle(iconStyle);
      markers.push(iconFeature);
      this.renderLayer(markers, false);
    }
  }

  private renderMapPointMarkers(): void {
    const iconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src: Marker,
      }),
    });
    const markers: Feature[] = [];
    const iconFeature = new Feature({});

    if (this.props.mapsData) {
      // TODO: Fix any type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.props.mapsData.forEach((item: any) => {
        if (item.latitude && item.longitude) {
          const iconFeature2 = new Feature({
            geometry: new Point(proj.fromLonLat([item.longitude,
              item.latitude])),
          });
          iconFeature.setStyle(iconStyle);
          markers.push(iconFeature2);
        }
      });
    }

    this.renderLayer(markers, true);
  }

  private renderLayer(features: Feature[], mapPoints: boolean): void {
    if (this.map !== null) {
      const vectorSource = new VectorSource({
        features,
      });
      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });

      if (mapPoints) {
        this.mapPointsLayer = vectorLayer;
      } else {
        this.selectedMarkerLayer = vectorLayer;
      }

      this.map.addLayer(vectorLayer);
    }
  }

  private renderMap(): void {
    const tileLayer = new TileLayer({
      source: new OSM(),
    });

    this.map = new Map({
      layers: [tileLayer],
      target: "map",
      view: new View({
        center: DEFAULT_CENTER,
        extent: proj.transformExtent([180, -90, -180, 90], "EPSG:4326", "EPSG:3857"),
        minZoom: ZOOM_DEFAULT,
        zoom: ZOOM_DEFAULT,
      }),
    });
  }

  private renderOverlay(): void {
    const currentZoomValue = this.map.getView().getZoom();

    if (this.props.selectedLocation !== null && this.props.selectedLocation.length !== 0) {
      if (this.map !== null) {
        const coordinates = proj.fromLonLat([this.props.selectedLocation[0],
          this.props.selectedLocation[1]]);
        const targetZoomValue = currentZoomValue > ZOOM_DEFAULT ? currentZoomValue : ZOOM_VALUE;
        this.map.getView().animate({ center: coordinates }, { zoom: targetZoomValue });
      }
    }
  }

  public render(): JSX.Element {
    return (
      <div id="map" />
    );
  }
}
