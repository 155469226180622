/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { ChangeEvent, Component } from "react";
import { Maybe } from "../../types/aliases";

type Selection = string | number | boolean;

interface Props {
  label?: string;
  disabled?: boolean;
  selectionList: Selection[];
  emptySelectionItem?: string;
  onSelected: (selection?: number) => void;
  currentSelection?: number;
}

interface State {
  selected?: string;
}

export default class DropdownSelection extends Component<Props, State> {
  
  public constructor(props: Props) {
    super(props);
    this.state = {
      selected: this.props.currentSelection != null ? this.props.selectionList[this.props.currentSelection].toString() : undefined,
    };
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.currentSelection !== this.props.currentSelection) {
      this.setState({
        selected: this.props.currentSelection != null ? this.props.selectionList[this.props.currentSelection].toString() : undefined,
      });
    }
  }

  private getLabel(): Maybe<JSX.Element> {
    if (this.props.label) {
      return (
        <InputLabel shrink={true}>
          {this.props.label}
        </InputLabel>
      );
    }
  }

  private getValue(): string {
    if (this.state.selected) {
      return this.state.selected;
    }
    return "";
  }

  private getSelectionList(): Maybe<JSX.Element[]> {
    if (this.props.selectionList && this.props.selectionList.length > 0) {
      return this.props.selectionList.map((selection: Selection) => {
        return (
          <MenuItem key={selection.toString()} value={selection.toString()}>{selection}</MenuItem>
        );
      });
    }
  }

  private getEmptySelection(): Maybe<JSX.Element> {
    if (this.props.emptySelectionItem) {
      return (
        <MenuItem value="">
          {this.props.emptySelectionItem}
        </MenuItem>
      );
    }
  }

  private onSelection = (event: ChangeEvent<{ name?: string; value: unknown }>): void => {
    let index: Maybe<number> = undefined;
    const value = (event.target?.value as Selection)?.toString() ?? undefined;

    if (value != null) {
      const foundIndex = this.props.selectionList.indexOf(value);
      index = foundIndex >= 0 ? foundIndex : undefined;
    }
    this.setState({ selected: value });
    this.props.onSelected(index);
  };

  public render(): JSX.Element {
    return (
      <div className="dropdown-selection">
        <FormControl className="dropdown-selection-input">
          {this.getLabel()}
          <Select
            value={this.getValue()}
            onChange={this.onSelection}
            disabled={this.props.disabled}
            displayEmpty={true}
          >
            {this.getEmptySelection()}
            {this.getSelectionList()}
          </Select>
        </FormControl>
      </div>
    );
  }
}
