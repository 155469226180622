/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Service } from "../backend/AppSyncClientProvider";
import { LatestData } from "./LatestData";
import { DataUtil } from "./Data";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import AuthWrapper from "../auth/authWrapper";
import { Maybe } from "../../types/aliases";
import { DevicesMeasurementsUpdateFeedDocument } from "../../generated/gqlData";

// REFACTOR: Could LatestDataSubscriptionManager and DataSubscriptionManager be
// combined through Generics?
export default class LatestDataSubscriptionManager {

  private static instance: LatestDataSubscriptionManager;

  private subscribedData: LatestData[] = [];
  private subscriptions: ZenObservable.Subscription[] = [];

  public static getInstance(): LatestDataSubscriptionManager {
    if (!LatestDataSubscriptionManager.instance) {
      LatestDataSubscriptionManager.instance = new LatestDataSubscriptionManager();
    }
    return this.instance;
  }

  public subscribeData(dataSet: LatestData): void {
    if (this.subscribedData && this.subscribedData.indexOf(dataSet) === -1) {
      this.subscribedData.push(dataSet);

      if (this.subscribedData.length === 1) {
        // TODO: Fix any type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.subscribe().catch((reason: any) => console.error("Failed to subscribe", reason));
      }
    }
  }

  public removeSubscription(dataSet: LatestData): void {
    const observerIndex = this.subscribedData.indexOf(dataSet);

    if (observerIndex !== -1) {
      if (this.subscribedData.length === 1) {
        while (this.subscriptions.length > 0) {
          this.subscriptions.pop()?.unsubscribe();
        }
      }
      this.subscribedData.splice(observerIndex, 1);
    }
  }

  private findDataSet(deviceId: string): Maybe<LatestData> {
    return this.subscribedData.find((latestData: LatestData) => deviceId === latestData.getId());
  }

  private async subscribe(): Promise<void> {
    const canSees: string[] = (await AuthWrapper.getCurrentAuthenticatedUserClaims())?.canSee ?? [];
    canSees.forEach((canSee: string) => {
      this.subscriptions.push(this.subscribeWithIdentity(canSee));
    });
  }

  private subscribeWithIdentity(identity: string): ZenObservable.Subscription {
    console.log("LatestData subs for " + identity);
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DATA);
    return client.subscribe(
      DevicesMeasurementsUpdateFeedDocument,
      {
        receiver: identity,
      },
    ).subscribe({
      // TODO: Fix any type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: (error: any): void => {
        if (error.errorMessage === "AMQJS0008I Socket closed.") {
          console.log("Reconnecting socket");
          this.subscriptions.push(this.subscribeWithIdentity(identity));
        }
        console.error(error);
      },
      next: (update): void => {
        if (update.data?.devicesMeasurementsUpdateFeed) {
          const dataItem = DataUtil.parseDataFragment(update.data.devicesMeasurementsUpdateFeed.item);

          if (dataItem) {
            const dataSet = this.findDataSet(dataItem.deviceId);

            if (dataSet) {
              dataSet.setData(dataItem);
            }
          }
        }
      },
    });
  }
}
