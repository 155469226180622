import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSJSON: string;
}


export interface Mutation {
  __typename?: 'Mutation';
  organizationsCreate?: Maybe<Organization>;
  organizationsDelete?: Maybe<Result>;
  organizationsUpdate?: Maybe<Organization>;
  organizationsUsersAdd?: Maybe<Result>;
  organizationsUsersRemove?: Maybe<Result>;
  policyGroupsCreate?: Maybe<PolicyGroup>;
  policyGroupsDelete?: Maybe<Result>;
  policyGroupsUpdate?: Maybe<PolicyGroup>;
  policyGroupsUsersAdd?: Maybe<Result>;
  policyGroupsUsersRemove?: Maybe<Result>;
  usersCreate?: Maybe<User>;
  usersDelete?: Maybe<Result>;
  usersUpdate?: Maybe<User>;
}


export interface MutationOrganizationsCreateArgs {
  payload: OrganizationCreatePayload;
}


export interface MutationOrganizationsDeleteArgs {
  organizationId: Scalars['ID'];
}


export interface MutationOrganizationsUpdateArgs {
  payload: OrganizationUpdatePayload;
}


export interface MutationOrganizationsUsersAddArgs {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
}


export interface MutationOrganizationsUsersRemoveArgs {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
}


export interface MutationPolicyGroupsCreateArgs {
  payload: PolicyGroupCreatePayload;
}


export interface MutationPolicyGroupsDeleteArgs {
  policyGroupId: Scalars['ID'];
}


export interface MutationPolicyGroupsUpdateArgs {
  payload: PolicyGroupUpdatePayload;
}


export interface MutationPolicyGroupsUsersAddArgs {
  policyGroupId: Scalars['ID'];
  userId: Scalars['ID'];
}


export interface MutationPolicyGroupsUsersRemoveArgs {
  policyGroupId: Scalars['ID'];
  userId: Scalars['ID'];
}


export interface MutationUsersCreateArgs {
  payload: UserCreatePayload;
}


export interface MutationUsersDeleteArgs {
  userId: Scalars['ID'];
}


export interface MutationUsersUpdateArgs {
  payload: UserUpdatePayload;
}

export interface Organization {
  __typename?: 'Organization';
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationId?: Maybe<Scalars['ID']>;
  organizationUid: Scalars['String'];
}

export interface OrganizationCreatePayload {
  name: Scalars['String'];
  parentOrganizationId: Scalars['ID'];
}

export interface OrganizationList {
  __typename?: 'OrganizationList';
  nextToken?: Maybe<Scalars['ID']>;
  organizations: Array<Scalars['ID']>;
}

export interface OrganizationUpdatePayload {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
}

export interface PolicyGrant {
  __typename?: 'PolicyGrant';
  organizationId: Scalars['ID'];
  policies: Array<Scalars['String']>;
}

export interface PolicyGrantList {
  __typename?: 'PolicyGrantList';
  grants: Array<PolicyGrant>;
}

export interface PolicyGroup {
  __typename?: 'PolicyGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  policies: Array<Scalars['String']>;
}

export interface PolicyGroupCreatePayload {
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  policies: Array<Scalars['String']>;
}

export interface PolicyGroupList {
  __typename?: 'PolicyGroupList';
  groups: Array<Scalars['ID']>;
  nextToken?: Maybe<Scalars['ID']>;
}

export interface PolicyGroupUpdatePayload {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  policies?: Maybe<Array<Scalars['String']>>;
}

export interface Query {
  __typename?: 'Query';
  organizationsGet?: Maybe<Organization>;
  organizationsOrganizationsList?: Maybe<OrganizationList>;
  organizationsPolicyGroupsList?: Maybe<PolicyGroupList>;
  organizationsUsersList?: Maybe<UserList>;
  policyGroupsGet?: Maybe<PolicyGroup>;
  policyGroupsUsersList?: Maybe<UserList>;
  usersGet?: Maybe<User>;
  usersGrantsList?: Maybe<PolicyGrantList>;
  usersPolicyGroupsList?: Maybe<PolicyGroupList>;
}


export interface QueryOrganizationsGetArgs {
  organizationId: Scalars['ID'];
}


export interface QueryOrganizationsOrganizationsListArgs {
  nextToken?: Maybe<Scalars['ID']>;
  organizationId: Scalars['ID'];
}


export interface QueryOrganizationsPolicyGroupsListArgs {
  nextToken?: Maybe<Scalars['ID']>;
  organizationId: Scalars['ID'];
}


export interface QueryOrganizationsUsersListArgs {
  nextToken?: Maybe<Scalars['ID']>;
  organizationId: Scalars['ID'];
}


export interface QueryPolicyGroupsGetArgs {
  policyGroupId: Scalars['ID'];
}


export interface QueryPolicyGroupsUsersListArgs {
  nextToken?: Maybe<Scalars['ID']>;
  policyGroupId: Scalars['ID'];
}


export interface QueryUsersGetArgs {
  userId: Scalars['ID'];
}


export interface QueryUsersGrantsListArgs {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
}


export interface QueryUsersPolicyGroupsListArgs {
  nextToken?: Maybe<Scalars['ID']>;
  userId: Scalars['ID'];
}

export interface Result {
  __typename?: 'Result';
  failureReason?: Maybe<Scalars['String']>;
  result: ResultType;
}

export enum ResultType {
  Failure = 'FAILURE',
  NotExist = 'NOT_EXIST',
  Ok = 'OK'
}

export interface Schema {
  __typename?: 'Schema';
  mutation?: Maybe<Mutation>;
  query?: Maybe<Query>;
}

export interface User {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  policies: Array<Scalars['String']>;
}

export interface UserCreatePayload {
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  policies: Array<Scalars['String']>;
  resendInvitation: Scalars['Boolean'];
}

export interface UserList {
  __typename?: 'UserList';
  nextToken?: Maybe<Scalars['ID']>;
  users: Array<Scalars['ID']>;
}

export interface UserUpdatePayload {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  policies?: Maybe<Array<Scalars['String']>>;
}

export type OrganizationFieldsFragment = { __typename?: 'Organization', id: string, name: string, organizationId?: Maybe<string>, organizationUid: string };

export type PolicyGroupFieldsFragment = { __typename?: 'PolicyGroup', id: string, policies: Array<string>, name: string, organizationId: string };

export type UserFieldsFragment = { __typename?: 'User', id: string, name: string, policies: Array<string>, organizationId: string };

export type ResultFieldsFragment = { __typename?: 'Result', result: ResultType, failureReason?: Maybe<string> };

export type OrganizationsGetQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type OrganizationsGetQuery = { __typename?: 'Query', organizationsGet?: Maybe<(
    { __typename?: 'Organization' }
    & OrganizationFieldsFragment
  )> };

export type OrganizationsOrganizationsListQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  nextToken?: Maybe<Scalars['ID']>;
}>;


export type OrganizationsOrganizationsListQuery = { __typename?: 'Query', organizationsOrganizationsList?: Maybe<{ __typename?: 'OrganizationList', organizations: Array<string>, nextToken?: Maybe<string> }> };

export type OrganizationsPolicyGroupsListQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  nextToken?: Maybe<Scalars['ID']>;
}>;


export type OrganizationsPolicyGroupsListQuery = { __typename?: 'Query', organizationsPolicyGroupsList?: Maybe<{ __typename?: 'PolicyGroupList', groups: Array<string>, nextToken?: Maybe<string> }> };

export type OrganizationsUsersListQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  nextToken?: Maybe<Scalars['ID']>;
}>;


export type OrganizationsUsersListQuery = { __typename?: 'Query', organizationsUsersList?: Maybe<{ __typename?: 'UserList', users: Array<string>, nextToken?: Maybe<string> }> };

export type PolicyGroupsGetQueryVariables = Exact<{
  policyGroupId: Scalars['ID'];
}>;


export type PolicyGroupsGetQuery = { __typename?: 'Query', policyGroupsGet?: Maybe<{ __typename?: 'PolicyGroup', id: string, policies: Array<string>, name: string, organizationId: string }> };

export type PolicyGroupsUsersListQueryVariables = Exact<{
  policyGroupId: Scalars['ID'];
  nextToken?: Maybe<Scalars['ID']>;
}>;


export type PolicyGroupsUsersListQuery = { __typename?: 'Query', policyGroupsUsersList?: Maybe<{ __typename?: 'UserList', users: Array<string>, nextToken?: Maybe<string> }> };

export type UsersGetQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UsersGetQuery = { __typename?: 'Query', usersGet?: Maybe<{ __typename?: 'User', id: string, name: string, policies: Array<string>, organizationId: string }> };

export type UsersGrantsListQueryVariables = Exact<{
  userId: Scalars['ID'];
  organizationId: Scalars['ID'];
}>;


export type UsersGrantsListQuery = { __typename?: 'Query', usersGrantsList?: Maybe<{ __typename?: 'PolicyGrantList', grants: Array<{ __typename?: 'PolicyGrant', organizationId: string, policies: Array<string> }> }> };

export type UsersPolicyGroupsListQueryVariables = Exact<{
  userId: Scalars['ID'];
  nextToken?: Maybe<Scalars['ID']>;
}>;


export type UsersPolicyGroupsListQuery = { __typename?: 'Query', usersPolicyGroupsList?: Maybe<{ __typename?: 'PolicyGroupList', groups: Array<string>, nextToken?: Maybe<string> }> };

export type OrganizationsCreateMutationVariables = Exact<{
  payload: OrganizationCreatePayload;
}>;


export type OrganizationsCreateMutation = { __typename?: 'Mutation', organizationsCreate?: Maybe<(
    { __typename?: 'Organization' }
    & OrganizationFieldsFragment
  )> };

export type OrganizationsDeleteMutationVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type OrganizationsDeleteMutation = { __typename?: 'Mutation', organizationsDelete?: Maybe<(
    { __typename?: 'Result' }
    & ResultFieldsFragment
  )> };

export type OrganizationsUpdateMutationVariables = Exact<{
  payload: OrganizationUpdatePayload;
}>;


export type OrganizationsUpdateMutation = { __typename?: 'Mutation', organizationsUpdate?: Maybe<(
    { __typename?: 'Organization' }
    & OrganizationFieldsFragment
  )> };

export type OrganizationsUsersAddMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type OrganizationsUsersAddMutation = { __typename?: 'Mutation', organizationsUsersAdd?: Maybe<(
    { __typename?: 'Result' }
    & ResultFieldsFragment
  )> };

export type OrganizationsUsersRemoveMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type OrganizationsUsersRemoveMutation = { __typename?: 'Mutation', organizationsUsersRemove?: Maybe<(
    { __typename?: 'Result' }
    & ResultFieldsFragment
  )> };

export type PolicyGroupsCreateMutationVariables = Exact<{
  payload: PolicyGroupCreatePayload;
}>;


export type PolicyGroupsCreateMutation = { __typename?: 'Mutation', policyGroupsCreate?: Maybe<(
    { __typename?: 'PolicyGroup' }
    & PolicyGroupFieldsFragment
  )> };

export type PolicyGroupsDeleteMutationVariables = Exact<{
  policyGroupId: Scalars['ID'];
}>;


export type PolicyGroupsDeleteMutation = { __typename?: 'Mutation', policyGroupsDelete?: Maybe<(
    { __typename?: 'Result' }
    & ResultFieldsFragment
  )> };

export type PolicyGroupsUpdateMutationVariables = Exact<{
  payload: PolicyGroupUpdatePayload;
}>;


export type PolicyGroupsUpdateMutation = { __typename?: 'Mutation', policyGroupsUpdate?: Maybe<(
    { __typename?: 'PolicyGroup' }
    & PolicyGroupFieldsFragment
  )> };

export type PolicyGroupsUsersAddMutationVariables = Exact<{
  policyGroupId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type PolicyGroupsUsersAddMutation = { __typename?: 'Mutation', policyGroupsUsersAdd?: Maybe<(
    { __typename?: 'Result' }
    & ResultFieldsFragment
  )> };

export type PolicyGroupsUsersRemoveMutationVariables = Exact<{
  policyGroupId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type PolicyGroupsUsersRemoveMutation = { __typename?: 'Mutation', policyGroupsUsersRemove?: Maybe<(
    { __typename?: 'Result' }
    & ResultFieldsFragment
  )> };

export type UsersCreateMutationVariables = Exact<{
  payload: UserCreatePayload;
}>;


export type UsersCreateMutation = { __typename?: 'Mutation', usersCreate?: Maybe<(
    { __typename?: 'User' }
    & UserFieldsFragment
  )> };

export type UsersDeleteMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UsersDeleteMutation = { __typename?: 'Mutation', usersDelete?: Maybe<(
    { __typename?: 'Result' }
    & ResultFieldsFragment
  )> };

export type UsersUpdateMutationVariables = Exact<{
  payload: UserUpdatePayload;
}>;


export type UsersUpdateMutation = { __typename?: 'Mutation', usersUpdate?: Maybe<(
    { __typename?: 'User' }
    & UserFieldsFragment
  )> };

export const OrganizationFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"organizationFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Organization"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"organizationId"}},{"kind":"Field","name":{"kind":"Name","value":"organizationUid"}}]}}]} as unknown as DocumentNode<OrganizationFieldsFragment, unknown>;
export const PolicyGroupFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"policyGroupFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PolicyGroup"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"policies"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"organizationId"}}]}}]} as unknown as DocumentNode<PolicyGroupFieldsFragment, unknown>;
export const UserFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"userFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"policies"}},{"kind":"Field","name":{"kind":"Name","value":"organizationId"}}]}}]} as unknown as DocumentNode<UserFieldsFragment, unknown>;
export const ResultFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"resultFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Result"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"result"}},{"kind":"Field","name":{"kind":"Name","value":"failureReason"}}]}}]} as unknown as DocumentNode<ResultFieldsFragment, unknown>;
export const OrganizationsGetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"organizationsGet"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizationsGet"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"organizationId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"organizationFields"}}]}}]}},...OrganizationFieldsFragmentDoc.definitions]} as unknown as DocumentNode<OrganizationsGetQuery, OrganizationsGetQueryVariables>;
export const OrganizationsOrganizationsListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"organizationsOrganizationsList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizationsOrganizationsList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"organizationId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}}},{"kind":"Argument","name":{"kind":"Name","value":"nextToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizations"}},{"kind":"Field","name":{"kind":"Name","value":"nextToken"}}]}}]}}]} as unknown as DocumentNode<OrganizationsOrganizationsListQuery, OrganizationsOrganizationsListQueryVariables>;
export const OrganizationsPolicyGroupsListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"organizationsPolicyGroupsList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizationsPolicyGroupsList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"organizationId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}}},{"kind":"Argument","name":{"kind":"Name","value":"nextToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"groups"}},{"kind":"Field","name":{"kind":"Name","value":"nextToken"}}]}}]}}]} as unknown as DocumentNode<OrganizationsPolicyGroupsListQuery, OrganizationsPolicyGroupsListQueryVariables>;
export const OrganizationsUsersListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"organizationsUsersList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizationsUsersList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"organizationId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}}},{"kind":"Argument","name":{"kind":"Name","value":"nextToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"users"}},{"kind":"Field","name":{"kind":"Name","value":"nextToken"}}]}}]}}]} as unknown as DocumentNode<OrganizationsUsersListQuery, OrganizationsUsersListQueryVariables>;
export const PolicyGroupsGetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"policyGroupsGet"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"policyGroupId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"policyGroupsGet"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"policyGroupId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"policyGroupId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"policies"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"organizationId"}}]}}]}}]} as unknown as DocumentNode<PolicyGroupsGetQuery, PolicyGroupsGetQueryVariables>;
export const PolicyGroupsUsersListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"policyGroupsUsersList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"policyGroupId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"policyGroupsUsersList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"policyGroupId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"policyGroupId"}}},{"kind":"Argument","name":{"kind":"Name","value":"nextToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"users"}},{"kind":"Field","name":{"kind":"Name","value":"nextToken"}}]}}]}}]} as unknown as DocumentNode<PolicyGroupsUsersListQuery, PolicyGroupsUsersListQueryVariables>;
export const UsersGetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"usersGet"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"usersGet"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"policies"}},{"kind":"Field","name":{"kind":"Name","value":"organizationId"}}]}}]}}]} as unknown as DocumentNode<UsersGetQuery, UsersGetQueryVariables>;
export const UsersGrantsListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"usersGrantsList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"usersGrantsList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}},{"kind":"Argument","name":{"kind":"Name","value":"organizationId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"grants"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizationId"}},{"kind":"Field","name":{"kind":"Name","value":"policies"}}]}}]}}]}}]} as unknown as DocumentNode<UsersGrantsListQuery, UsersGrantsListQueryVariables>;
export const UsersPolicyGroupsListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"usersPolicyGroupsList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"usersPolicyGroupsList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}},{"kind":"Argument","name":{"kind":"Name","value":"nextToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"groups"}},{"kind":"Field","name":{"kind":"Name","value":"nextToken"}}]}}]}}]} as unknown as DocumentNode<UsersPolicyGroupsListQuery, UsersPolicyGroupsListQueryVariables>;
export const OrganizationsCreateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"organizationsCreate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"payload"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"OrganizationCreatePayload"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizationsCreate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"payload"},"value":{"kind":"Variable","name":{"kind":"Name","value":"payload"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"organizationFields"}}]}}]}},...OrganizationFieldsFragmentDoc.definitions]} as unknown as DocumentNode<OrganizationsCreateMutation, OrganizationsCreateMutationVariables>;
export const OrganizationsDeleteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"organizationsDelete"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizationsDelete"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"organizationId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"resultFields"}}]}}]}},...ResultFieldsFragmentDoc.definitions]} as unknown as DocumentNode<OrganizationsDeleteMutation, OrganizationsDeleteMutationVariables>;
export const OrganizationsUpdateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"organizationsUpdate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"payload"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"OrganizationUpdatePayload"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizationsUpdate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"payload"},"value":{"kind":"Variable","name":{"kind":"Name","value":"payload"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"organizationFields"}}]}}]}},...OrganizationFieldsFragmentDoc.definitions]} as unknown as DocumentNode<OrganizationsUpdateMutation, OrganizationsUpdateMutationVariables>;
export const OrganizationsUsersAddDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"organizationsUsersAdd"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizationsUsersAdd"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"organizationId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}}},{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"resultFields"}}]}}]}},...ResultFieldsFragmentDoc.definitions]} as unknown as DocumentNode<OrganizationsUsersAddMutation, OrganizationsUsersAddMutationVariables>;
export const OrganizationsUsersRemoveDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"organizationsUsersRemove"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organizationsUsersRemove"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"organizationId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"organizationId"}}},{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"resultFields"}}]}}]}},...ResultFieldsFragmentDoc.definitions]} as unknown as DocumentNode<OrganizationsUsersRemoveMutation, OrganizationsUsersRemoveMutationVariables>;
export const PolicyGroupsCreateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"policyGroupsCreate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"payload"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PolicyGroupCreatePayload"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"policyGroupsCreate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"payload"},"value":{"kind":"Variable","name":{"kind":"Name","value":"payload"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"policyGroupFields"}}]}}]}},...PolicyGroupFieldsFragmentDoc.definitions]} as unknown as DocumentNode<PolicyGroupsCreateMutation, PolicyGroupsCreateMutationVariables>;
export const PolicyGroupsDeleteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"policyGroupsDelete"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"policyGroupId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"policyGroupsDelete"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"policyGroupId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"policyGroupId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"resultFields"}}]}}]}},...ResultFieldsFragmentDoc.definitions]} as unknown as DocumentNode<PolicyGroupsDeleteMutation, PolicyGroupsDeleteMutationVariables>;
export const PolicyGroupsUpdateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"policyGroupsUpdate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"payload"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PolicyGroupUpdatePayload"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"policyGroupsUpdate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"payload"},"value":{"kind":"Variable","name":{"kind":"Name","value":"payload"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"policyGroupFields"}}]}}]}},...PolicyGroupFieldsFragmentDoc.definitions]} as unknown as DocumentNode<PolicyGroupsUpdateMutation, PolicyGroupsUpdateMutationVariables>;
export const PolicyGroupsUsersAddDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"policyGroupsUsersAdd"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"policyGroupId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"policyGroupsUsersAdd"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"policyGroupId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"policyGroupId"}}},{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"resultFields"}}]}}]}},...ResultFieldsFragmentDoc.definitions]} as unknown as DocumentNode<PolicyGroupsUsersAddMutation, PolicyGroupsUsersAddMutationVariables>;
export const PolicyGroupsUsersRemoveDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"policyGroupsUsersRemove"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"policyGroupId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"policyGroupsUsersRemove"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"policyGroupId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"policyGroupId"}}},{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"resultFields"}}]}}]}},...ResultFieldsFragmentDoc.definitions]} as unknown as DocumentNode<PolicyGroupsUsersRemoveMutation, PolicyGroupsUsersRemoveMutationVariables>;
export const UsersCreateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"usersCreate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"payload"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserCreatePayload"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"usersCreate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"payload"},"value":{"kind":"Variable","name":{"kind":"Name","value":"payload"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"userFields"}}]}}]}},...UserFieldsFragmentDoc.definitions]} as unknown as DocumentNode<UsersCreateMutation, UsersCreateMutationVariables>;
export const UsersDeleteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"usersDelete"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"usersDelete"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"resultFields"}}]}}]}},...ResultFieldsFragmentDoc.definitions]} as unknown as DocumentNode<UsersDeleteMutation, UsersDeleteMutationVariables>;
export const UsersUpdateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"usersUpdate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"payload"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserUpdatePayload"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"usersUpdate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"payload"},"value":{"kind":"Variable","name":{"kind":"Name","value":"payload"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"userFields"}}]}}]}},...UserFieldsFragmentDoc.definitions]} as unknown as DocumentNode<UsersUpdateMutation, UsersUpdateMutationVariables>;