/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Service } from "../backend/AppSyncClientProvider";
import { Data, DataUtil } from "./Data";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import AuthWrapper from "../auth/authWrapper";
import { Maybe } from "../../types/aliases";
import { DevicesMeasurementsUpdateFeedDocument } from "../../generated/gqlData";

export interface AppendableDataCollection {
  getId(): string;
  appendData(data: Data): void;
}

// REFACTOR: Could LatestDataSubscriptionManager and DataSubscriptionManager be
// combined through Generics?
export default class DataSubscriptionManager {

  private subscribedData: AppendableDataCollection[] = [];
  private subscriptions: ZenObservable.Subscription[] = [];
  private static instance: DataSubscriptionManager;

  public static getInstance(): DataSubscriptionManager {
    if (!DataSubscriptionManager.instance) {
      DataSubscriptionManager.instance = new DataSubscriptionManager();
    }
    return this.instance;
  }

  public subscribeData(dataSet: AppendableDataCollection): void {
    if (!this.findDataSet(dataSet.getId())) {
      this.subscribedData.push(dataSet);

      if (this.subscribedData.length === 1) {
        // Just added the first observer, start listening to backend events
        // TODO: Fix any type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.subscribe().catch((reason: any) => console.error("Failed to subscribe", reason));
      }
    }
  }

  public removeSubscription(dataSet: AppendableDataCollection): void {
    const observerIndex = this.subscribedData.findIndex((set) => set.getId() === dataSet.getId());

    if (observerIndex !== -1) {
      this.subscribedData.splice(observerIndex, 1);

      if (this.subscribedData.length === 0) {
        // Just removed the last observer, no one listening for updates
        while (this.subscriptions.length > 0) {
          this.subscriptions.pop()?.unsubscribe();
        }
      }
    }
  }

  private findDataSet(deviceId: string): Maybe<AppendableDataCollection> {
    return this.subscribedData.find((dataSet) => deviceId === dataSet.getId());
  }

  private async subscribe(): Promise<void> {
    (await AuthWrapper.getCurrentAuthenticatedUserClaims())?.canSee.forEach((canSee: string) =>
      this.subscriptions.push(this.subscribeWithIdentity(canSee)));
  }

  private subscribeWithIdentity(identity: string): ZenObservable.Subscription {
    console.log("Data subs for " + identity);
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DATA);
    return client.subscribe(
      DevicesMeasurementsUpdateFeedDocument,
      {
        receiver: identity,
      },
    ).subscribe({
      // TODO: Fix any type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: (error: any): void => {
        if (error.errorMessage === "AMQJS0008I Socket closed.") {
          console.log("Reconnecting socket");
          this.subscriptions.push(this.subscribeWithIdentity(identity));
        }
        console.error(error);
      },
      next: (update): void => {
        if (update.data?.devicesMeasurementsUpdateFeed) {
          const dataItem = DataUtil.parseDataFragment(update.data.devicesMeasurementsUpdateFeed.item);
          const dataSet = dataItem ? this.findDataSet(dataItem.deviceId) : undefined;

          if (dataSet) {
            dataSet.appendData(dataItem!);
          }
        }
      },
    });
  }
}
