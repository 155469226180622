/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Button, Drawer } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { TableContent } from "../../types/tableprops";
import { ClientProperties, StatusDataRow, StatusEntry } from "../../data/clientSpecific/ClientProperties";
import { Data } from "../../data/data/Data";
import { LatestData, LatestDataObserver } from "../../data/data/LatestData";
import Device from "../../data/device/Device";
import { DrawerState, getDisplayName } from "../../data/utils/utils";
import TimeFilter from "../ui/time-filter";
import DeviceSettingsButton from "../device-settings/device-settings-button";
import ArrowDouble from "../../assets/arrow-double-24px.svg";
import DataTable from "../ui/data-table";
import LocationIcon from "../ui/location-icon";
import StatusMap from "./components/status-map";
import Loader from "../ui/loader";
import { Maybe, Nullable } from "../../types/aliases";
import ResourceSelector, { ResourceSelectorObserver } from "../../state/ResourceSelector";
import { ReferenceHWState } from "../../client/devices/ReferenceHW/ReferenceHWState";
import { ReferenceHWStateProperties } from "../../client/devices/ReferenceHW/ReferenceHWStateProperties";

const DEVICE_ID_INDEX = 0;

interface Props extends RouteComponentProps {
  devices?: Device[];
}

interface State {
  filteredDataList: Data[];
  filterTimestamp: Nullable<number>;
  drawerState: DrawerState;
  isLoading: boolean;
  selectedDevice?: Device;
  latestDataList?: Nullable<LatestData>[];
}

class StatusView extends Component<Props, State> implements LatestDataObserver, ResourceSelectorObserver {
  
  public constructor(props: Props) {
    super(props);

    this.state = {
      filteredDataList: [],
      filterTimestamp: null,
      drawerState: DrawerState.Open,
      isLoading: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    ResourceSelector.getInstance().addObserver(this);
    await this.fetchStatusData();
  }

  public async componentDidUpdate(prevProps: Props, prevState: State): Promise<void> {
    if ((this.props.devices?.length ?? 0) > 0 && this.props.devices!.length !== prevProps.devices?.length) {
      await this.fetchStatusData();
    }

    if (this.state.latestDataList &&
      (prevState.latestDataList !== this.state.latestDataList || prevState.filterTimestamp !== this.state.filterTimestamp)) {
      this.filterData();
    }
  }

  public componentWillUnmount(): void {
    if (this.state.latestDataList) {
      this.state.latestDataList.forEach((latestData) => {
        latestData?.removeObserver(this);
      });
    }
    ResourceSelector.getInstance().removeObserver(this);
  }

  public onSelectedDeviceChanged(device?: Device): void {
    this.setState({ selectedDevice: device });
  }

  public onDataUpdate(latestData: LatestData): void {
    if (this.state.latestDataList) {
      const latestDataList = this.state.latestDataList.slice();
      const index = latestDataList.findIndex((data) => data?.getId() === latestData.getId());

      if (index !== -1) {
        latestDataList[index] = latestData;
        this.setState({ latestDataList });
      }
    }
  }

  private async fetchStatusData(): Promise<void> {
    if (this.props.devices) {
      this.setState({ isLoading: true });
      const latestDataList = await Promise.all(
        this.props.devices.map((device: Device) => device.getLatestData()));
      this.setState({ latestDataList, isLoading: false });
    }
  }

  private onSet = (timestamp: number): void => {
    this.setState({
      filterTimestamp: timestamp,
    });
  };

  private filterData(): void {
    if (this.state.latestDataList) {
      const latestDataList = (this.state.latestDataList ?? []).slice();
      const filteredDataList: Data[] = latestDataList.reduce((acc, latestData: Nullable<LatestData>) => {
        const data = latestData?.getLatestData();

        if (data && (this.state.filterTimestamp == null || Number(data.timestamp) >= this.state.filterTimestamp)) {
          acc.push(data);
        }
        return acc;
      }, [] as Data[]);
      console.log(`filterData: filtered ${latestDataList.length} down to ${filteredDataList.length} items`);
      this.setState({ filteredDataList });
    }
  }

  private getTableData(): TableContent {
    let tableHeader: string[] = [];
    const tableData: StatusDataRow[] = [];

    const filteredDataList = this.state.filteredDataList.slice();
    filteredDataList.forEach((data: Data) => {
      if (data) {
        const rowDevice = this.props.devices?.find((device: Device) => device.getId() === data.deviceId);

        if (rowDevice) {
          let statusRow: StatusEntry[] = [
            { title: "Device id", value: rowDevice.getId() },
            { title: "Display name", value: getDisplayName(rowDevice) },
          ];
          const deviceState = rowDevice.getState() as Nullable<ReferenceHWState<ReferenceHWStateProperties>>;
          const statusRowEntries: StatusEntry[] = ClientProperties.getStatusRowEntries(data);
          statusRow = statusRow.concat(statusRowEntries);
          statusRow.push({
            title: "",
            value: (
              <Fragment>
                <DeviceSettingsButton
                  device={rowDevice}
                  isIcon={true}
                />
                <LocationIcon
                  locationStatus={deviceState?.gpsFix ?? undefined}
                  updateMilliseconds={deviceState?.getStateUpdatedTimestampMillis()}
                />
              </Fragment>
            ),
          });
          tableHeader = statusRow.map((entry: StatusEntry) => {
            return entry.title;
          });
          const statusTableRowData = statusRow.map((entry: StatusEntry) => {
            return entry.value;
          });
          tableData.push(statusTableRowData);
        }
      }
    });
    
    return {
      tableHeader,
      tableData,
    };
  }

  private isLocationInData(): boolean {
    const filteredDataList: Data[] = this.state.filteredDataList.slice();
    return filteredDataList.some((data: Data) => data["latitude"] != null && data["longitude"] != null);
  }

  private handleTableRowSelect = (index: number, key?: string): void => {
    if (this.getTableData().tableData !== null) {
      const tableData = this.getTableData().tableData;
      let selectedRow: StatusDataRow = tableData[index];

      if (key) {
        const row = tableData.filter((td: StatusDataRow) => {
          return (td[DEVICE_ID_INDEX] === key);
        });

        if (row && row.length > 0) {
          selectedRow = row[0];
        }
      }
      const rowDeviceId: string = selectedRow[DEVICE_ID_INDEX].toString();
      this.props.history.push("/status/" + rowDeviceId);

      if (this.isLocationInData()) {
        this.setState({
          drawerState: DrawerState.Open,
        });
      }
    }
  };

  private handleVisibleItemsChange = (newVisibleItems: string[]): void => {
    if (this.state.latestDataList) {
      this.state.latestDataList.forEach((latestData) => {
        if (newVisibleItems.find((value: string) => latestData?.getId() === value)) {
          latestData?.addObserver(this);
        } else {
          latestData?.removeObserver(this);
        }
      });
    }
  };

  private openDrawer = (): void => {
    this.setState({
      drawerState: DrawerState.Open,
    });
  };

  private openFullDrawer = (): void => {
    this.setState({
      drawerState: DrawerState.Full,
    });
  };

  private closeDrawer = (): void => {
    this.setState({
      drawerState: DrawerState.Closed,
    });
  };

  private renderButtons = (): Maybe<JSX.Element> => {
    if (this.state.drawerState === DrawerState.Open) {
      return (
        <div className="drawer-button-container">
          <Button className="drawer-button close" onClick={this.closeDrawer}>
            <img src={ArrowDouble} alt="forward arrow" />
          </Button>
          <Button onClick={this.openFullDrawer} className="drawer-button visible">
            <img src={ArrowDouble} alt="forward arrow" />
          </Button>
        </div>
      );
    }

    if (this.state.drawerState === DrawerState.Full) {
      return (
        <div className="drawer-button-container">
          <Button className="drawer-button close" onClick={this.openDrawer}>
            <img src={ArrowDouble} alt="forward arrow" />
          </Button>
        </div >
      );
    }
  };

  private renderSidebar = (): Maybe<JSX.Element> => {
    if (this.isLocationInData()) {
      return (
        <Drawer
          variant="persistent"
          anchor="left"
          open={this.state.drawerState !== DrawerState.Closed}
          transitionDuration={500}
          classes={{
            paper: (this.state.drawerState === DrawerState.Full ? "col-xsm-12 col-sm-12 col-md-12 col-lg-12" :
              "col-xsm-12 col-sm-12 col-md-12 col-lg-4") + " iot-content-drawer",
          }}
        >
          <div className="status-map-container">
            <StatusMap
              selectedDevice={this.state.selectedDevice}
              mapData={this.state.filteredDataList}
              drawerState={this.state.drawerState}
              // Needed for marker displayNames:
              devices={this.props.devices}
            />
          </div>
          {this.renderButtons()}
        </Drawer>
      );
    }
  };

  private renderTable = (): Maybe<JSX.Element> => {
    if (this.state.isLoading) {
      return <Loader/>;
    }

    if (this.state.drawerState !== DrawerState.Full) {
      const tableData = this.getTableData();
      return (
        <div className="table-wrapper">
          <DataTable
            title="Active devices"
            rowsPerPageDefault={20}
            header={tableData.tableHeader}
            data={tableData.tableData}
            stickyHeader={false}
            onTableRowSelect={this.handleTableRowSelect}
            onVisibleItemsChanged={this.handleVisibleItemsChange}
          />
        </div>
      );
    }
  };

  private renderSidebarButton = (): Maybe<JSX.Element> => {
    if (this.state.latestDataList && this.state.drawerState !== DrawerState.Open && this.isLocationInData()) {
      return (
        <div className="drawer-button-container">
          <Button onClick={this.openDrawer} className="drawer-open-button">
            <img src={ArrowDouble} alt="forward arrow" />
          </Button>
        </div>
      );
    }
  };

  public render(): JSX.Element {
    return (
      <Fragment>
        <div className="iot-tool-container col-sm-8 col-xsm-7">
          <TimeFilter
            onSet={this.onSet}
            filterList={[300000, 900000, 3600000, 21600000, 86400000, 604800000, 2592000000]}
            labelList={["5 min", "15 min", "1 hour", "6 hours", "1 day", "1 week", "1 month"]}
          />
        </div>
        {this.renderSidebar()}
        <div
          className="iot-content-container col-xsm-12 col-sm-12 col-md-12 col-lg-12"
          style={
            (this.state.drawerState === DrawerState.Open && this.isLocationInData()) 
              ? { marginLeft: "33%", width: "66%" }
              : this.state.drawerState === DrawerState.Full
                ? { width: "0%" } 
                : undefined
          }
        >
          {this.renderSidebarButton()}
          {this.renderTable()}
        </div >
      </Fragment >
    );
  }
}

export default withRouter(StatusView);
