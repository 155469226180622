/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { Slider } from "@material-ui/core";
import React, { ChangeEvent, Component } from "react";
import { Marks } from "../../types/timefilterprops";

interface Props {
  onSet: (timestamp: number) => void;
  filterList: number[];
  labelList: string[];
}

interface State {
  selectedTimeIndex: number;
}

export default class TimeFilter extends Component<Props, State> {
  
  public constructor(props: Props) {
    super(props);
    this.state = {
      selectedTimeIndex: 0,
    };
  }

  public componentDidMount(): void {
    const filterTimestamp: number = new Date().getTime() - this.props.filterList[0];
    this.props.onSet(filterTimestamp);
  }

  private getMarks = (): Marks[] => {
    return this.props.labelList.map((label: string, value: number) => {
      return { value, label };
    });
  };

  private getSliderMaxValue(): number {
    return this.props.filterList.length - 1;
  }

  private setTimestamp(index: number): void {
    const filterListValue: number = this.props.filterList[index];
    const filterTimestamp: number = new Date().getTime() - filterListValue;
    this.props.onSet(filterTimestamp);
  }

  private handleSliderValue = (event: ChangeEvent<unknown>, value: number | number[]): void => {
    if (event !== null && value !== null && this.state.selectedTimeIndex !== value) {
      this.setState({ selectedTimeIndex: value as number });
      this.setTimestamp(value as number);
    }
  };

  private renderSliderValue(): JSX.Element {
    return (
      <div className="slider-label-container">
        <p><span>Show active devices within</span></p>
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <div className="slider-container">
        {this.renderSliderValue()}
        <div className="slider-input-container">
          <Slider
            className="slider"
            step={null}
            valueLabelDisplay="off"
            marks={this.getMarks()}
            min={0}
            max={this.getSliderMaxValue()}
            onChange={this.handleSliderValue}
          />
        </div>
      </div>
    );
  }
}
