/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import Device from "../data/device/Device";
import BaseObservable from "../data/observer/BaseObservable";
import { Maybe } from "../types/aliases";
import DeviceGroup from "../data/device/DeviceGroup";
import BackendFactory from "../BackendFactory";
import AuthListener, { AuthEvent } from "../data/auth/authListener";

export interface ResourceSelectorObserver {
  onSelectedDeviceChanged?: (device?: Device) => void;
  onSelectedGroupChanged?: (group?: DeviceGroup) => void;
}

export default class ResourceSelector extends BaseObservable<ResourceSelectorObserver> {
  private static instance: ResourceSelector;

  private currentDevice?: Device;
  private currentGroup?: DeviceGroup;

  private authEventHandler = (event: AuthEvent): void => {
    if (event === "SignedOut") {
      this.setCurrentGroup();
      this.setCurrentDevice();
    }
  };

  private readonly authListener = new AuthListener(this.authEventHandler);

  public static getInstance(): ResourceSelector {
    if (ResourceSelector.instance == null) {
      ResourceSelector.instance = new ResourceSelector();
    }
    return this.instance;
  }

  private constructor() {
    super();
  }

  public getCurrentGroup(): Maybe<DeviceGroup> {
    return this.currentGroup;
  }

  public getCurrentDevice(): Maybe<Device> {
    return this.currentDevice;
  }

  public async setCurrentGroup(groupId?: string): Promise<void> {
    if (groupId) {
      if (this.currentGroup?.getId() !== groupId) {
        this.currentDevice = undefined;
        this.currentGroup = await BackendFactory.getBackend().getDeviceGroup(groupId);
        this.notifyAction(observer => observer.onSelectedGroupChanged?.(this.currentGroup));
      }
    } else if (this.currentGroup) {
      this.currentGroup = undefined;
      this.notifyAction(observer => observer.onSelectedGroupChanged?.(this.currentGroup));
    }
  }

  public async setCurrentDevice(deviceId?: string): Promise<void> {
    if (deviceId) {
      if (this.currentDevice?.getId() !== deviceId) {
        this.currentGroup = undefined;
        this.currentDevice = await BackendFactory.getBackend().getDevice(deviceId);
        this.notifyAction(observer => observer.onSelectedDeviceChanged?.(this.currentDevice));
      }
    } else if (this.currentDevice) {
      this.currentDevice = undefined;
      this.notifyAction(observer => observer.onSelectedDeviceChanged?.(this.currentDevice));
    }
  }

  public addObserver(observer: ResourceSelectorObserver): void {
    super.addObserver(observer);
    observer.onSelectedDeviceChanged?.(this.currentDevice);
    observer.onSelectedGroupChanged?.(this.currentGroup);
  }
}
