/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import Device from "./data/device/Device";
import { HyperHW } from "./client/devices/HyperHW/HyperHW";
import { SuperHW } from "./client/devices/SuperHW/SuperHW";
import { RuuviTagHW } from "./client/devices/RuuviTagHW/RuuviTagHW";
import { Nullable } from "./types/aliases";
import { Attribute } from "./data/device/Attribute";

export default class DeviceFactory {
  public static createDevice(deviceId: string, type: string, attr?: Attribute[]): Nullable<Device> {
    if (type === SuperHW.type) {
      return new SuperHW(deviceId, attr);
    } else if (type === HyperHW.type) {
      return new HyperHW(deviceId, attr);
    } else if (type === RuuviTagHW.type) {
      return new RuuviTagHW(deviceId, attr);
    }
    return null;
  }
}
