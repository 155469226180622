/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Button, Drawer } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RouteComponentPropsParams } from "../../types/routerprops";
import { Session } from "../../data/clientSpecific/Session";
import DataSet, { DataObserver } from "../../data/data/DataSet";
import { SessionSet } from "../../data/data/SessionSet";
import Device from "../../data/device/Device";
import { getDisplayName } from "../../data/utils/utils";
import IoTHistoryTools from "./components/history-tools";
import LiveControl from "./components/live-control";
import SessionPicker from "./components/session-picker";
import TimeRangePicker from "../ui/time-range-picker";
import Loader from "../ui/loader";
import ArrowDouble from "../../assets/arrow-double-24px.svg";
import IoTChart from "./components/history-chart";
import IoTMap from "./components/history-map";
import { ReferenceHWData } from "../../client/devices/ReferenceHW/ReferenceHWData";
import { Maybe, Nullable } from "../../types/aliases";
import ResourceSelector, { ResourceSelectorObserver } from "../../state/ResourceSelector";

type Props = RouteComponentProps<RouteComponentPropsParams>;

interface State {
  isLoading: boolean;
  drawerOpen: boolean;
  selectedSensor: string[];
  selectedSensor2: string[];
  liveData: boolean;
  timestampChanged: boolean;
  startTimestamp: Nullable<number>;
  endTimestamp: Nullable<number>;
  dataSet: Nullable<DataSet>;
  sessionSet: Nullable<SessionSet>;
  selectedLocation: Nullable<number[]>;
  selectedDevice?: Device;
  liveDataUrl: string;
}

const DELTA_TIME_MS: number = 2 * 60 * 60 * 1000;

class HistoryView
  extends Component<Props, State> 
  implements DataObserver, ResourceSelectorObserver {

  public constructor(props: Props) {
    super(props);

    const urlParams = new URLSearchParams(this.props.location.search);
    const startTimestampFromUrl = urlParams.get("startTimestamp");
    const endTimestampFromUrl = urlParams.get("endTimestamp");
    
    const DEFAULT_START_TIMESTAMP_URL = startTimestampFromUrl ? Number(startTimestampFromUrl) : null;
    const DEFAULT_END_TIMESTAMP_URL = endTimestampFromUrl ? Number(endTimestampFromUrl) : null;

    this.state = {
      isLoading: false,
      drawerOpen: false,
      selectedSensor: [],
      selectedSensor2: [],
      liveData: false,
      timestampChanged: false,
      startTimestamp: DEFAULT_START_TIMESTAMP_URL,
      endTimestamp: DEFAULT_END_TIMESTAMP_URL,
      dataSet: null,
      sessionSet: null,
      selectedLocation: null,
      liveDataUrl: "",
    };
  }

  public componentDidMount(): void {
    const urlParams = new URLSearchParams(this.props.location.search);
    const startTimestampFromUrl = urlParams.get("startTimestamp");
    const endTimestampFromUrl = urlParams.get("endTimestamp");
    const endTimestamp: number = new Date().getTime();
    const startTimestamp: number = endTimestamp - DELTA_TIME_MS;
        
    if (!(this.props.history.location.search)) {
      if (this.state.selectedDevice?.getId() === undefined && this.props.match.params.id === undefined) {
        console.log ("No current device, User needs to select a Device.");
      } else {
        console.log ("URL had no previous timestamp information. Building timestamps for ", this.props.match.params.id);
        this.setState({ 
          endTimestamp: endTimestamp,
          startTimestamp: startTimestamp, 
        });
      }

    } else {
      const parsedStartTimestamp = HistoryView.parseUrlTimestamp(startTimestampFromUrl);
      const parsedEndTimestamp = HistoryView.parseUrlTimestamp(endTimestampFromUrl);
      
      if (parsedStartTimestamp === null || parsedEndTimestamp === null || parsedStartTimestamp < 1 || parsedEndTimestamp < 1) {
        console.log ("Invalid timestamp entered. Default time range will be used.");
        this.setState({
          endTimestamp: endTimestamp,
          startTimestamp: startTimestamp, 
        }); 
      } else {
        console.log ("Valid deviceID and timestamps entered by user.");
      }
    }
    
    ResourceSelector.getInstance().addObserver(this);

    if (this.props.match.params.id) {
      this.setState({ isLoading: true });
    }
  }

  public componentWillUnmount(): void {
    this.state.dataSet?.removeObserver(this);
    ResourceSelector.getInstance().removeObserver(this);
  }

  public async componentDidUpdate(prevProps: Props, prevState: State): Promise<void> {
    const deviceChanged = this.state.selectedDevice != null &&
            (prevState.selectedDevice == null || prevState.selectedDevice.getId() !==
                this.state.selectedDevice.getId());

    if (deviceChanged) {
      console.log("componentDidUpdate: Device changed to " + this.state.selectedDevice?.getId());
      this.setState({
        dataSet: null,
        sessionSet: null,
        drawerOpen: false,
        selectedLocation: null,
      });

      if (this.state.endTimestamp == null || this.state.startTimestamp == null) {
        const endTimestamp: number = new Date().getTime();
        const startTimestamp: number = endTimestamp - DELTA_TIME_MS;
        this.setState({
          timestampChanged: true,
          startTimestamp: startTimestamp,
          endTimestamp: endTimestamp,
        });
      } 
    }
 
    if (this.state.selectedDevice?.getId() !== undefined) {
      if (deviceChanged || this.state.startTimestamp !== prevState.startTimestamp
        || this.state.endTimestamp !== prevState.endTimestamp) {
        console.log("componentDidUpdate: Will fetch data for", this.state.selectedDevice?.getId());
        await this.fetchData();
        await this.fetchSessions();
      }
    }
    
    if (this.state.startTimestamp === prevState.startTimestamp
            && this.state.endTimestamp === prevState.endTimestamp && this.state.timestampChanged) {
      this.setState({ timestampChanged: false });
    }

    if (this.state.dataSet && this.state.dataSet !== prevState.dataSet && !this.state.timestampChanged) {
      console.log("componentDidUpdate: Data changed for", this.props.match.params.id);
      this.setLatestLocation(this.getLatestLocation());

      // Initiate Url push update during Live Data: 
      if (this.state.liveData && this.state.liveDataUrl === prevState.liveDataUrl) {
        this.showNewUrlLive();
      }

      // Initiate Url push update with time ranges:
      if (!this.state.liveData) {
        this.showNewUrlPicker();
      }
    }

    if (!this.state.liveData && prevState.liveData) {
      console.log("componentDidUpdate: LiveData disabled");
      this.state.dataSet?.removeObserver(this);
    }

    if (!prevState.liveData && this.state.liveData) {
      console.log("componentDidUpdate: LiveData enabled");
      this.setState({
        endTimestamp: new Date().getTime(),
      });
    }
  }

  private static parseUrlTimestamp(timestamp: Nullable<string>): Nullable<number> {
    if (timestamp == null) {
      return null;
    }
    const asNumber = Number(timestamp);

    if (Number.isNaN(asNumber)) {
      return null;
    }
    return asNumber;
  }

  private getTimestampedUrl(startTimestamp: Nullable<number>, endTimestamp: Nullable<number>): string {
    const currentDeviceId = this.state.selectedDevice?.getId();
    return `/history/${currentDeviceId}?startTimestamp=${startTimestamp}&endTimestamp=${endTimestamp}`;
  }

  private showNewUrlLive = (): void => {
    const startTimestamp = this.state.startTimestamp;
    const liveEndTimestampMargin = 2000;
    const liveEndTimestampBase = new Date().getTime();
    const liveEndTimestamp = liveEndTimestampBase + liveEndTimestampMargin;
    const liveDataUrl = this.getTimestampedUrl(startTimestamp, liveEndTimestamp);
    const latestDatum = new Date(Number(liveEndTimestamp));
    console.log ("Live Url for", getDisplayName(this.state.selectedDevice), "on", latestDatum, ":", liveDataUrl);
    this.props.history.push(liveDataUrl);
    this.setState({
      liveDataUrl: liveDataUrl,
      liveData: true,
      timestampChanged: true,
    });
  };

  private showNewUrlPicker = (): void => {
    const urlParams = new URLSearchParams(this.props.location.search);
    const startTimestampFromUrl = urlParams.get("startTimestamp");
    const endTimestampFromUrl = urlParams.get("endTimestamp");

    const pathname = this.props.location.pathname;
    const pathnameAndQueryString = `${pathname}${this.props.location.search}`;
    const selectedDeviceId = this.state.selectedDevice?.getId();

    const endTimestamp = this.state.endTimestamp;
    const endDatum = new Date(Number(endTimestamp));
    const startTimestamp = this.state.startTimestamp;
    const startDatum = new Date(Number(startTimestamp));
    const timestampedUrl = this.getTimestampedUrl(startTimestamp, endTimestamp);
   
    if ((selectedDeviceId !== undefined && startTimestampFromUrl == null && endTimestampFromUrl == null) ||
        (pathnameAndQueryString !== timestampedUrl && !this.state.liveData)) {
      console.log ("URL for ", getDisplayName(this.state.selectedDevice), " between ", startDatum,
        " and ", endDatum, ": ", timestampedUrl);
      this.props.history.push(timestampedUrl);
    }
  };
  
  public onSelectedDeviceChanged(device?: Device): void {
    this.setState({ selectedDevice: device });
  }

  public onDataUpdate(dataSet: DataSet): void {
    if (dataSet.getId() === this.state.selectedDevice?.getId()) {
      const newDataSet = Object.create(dataSet);
      newDataSet.data = dataSet.getData().slice();
      this.setState({
        dataSet: newDataSet,
      });
    }
  }

  public onSessionUpdate(sessionSet: SessionSet): void {
    if (sessionSet.getId() === this.state.selectedDevice?.getId()) {
      // const newSessionSet = Object.create(sessionSet);
      // newSessionSet.data = sessionSet.getSessions().slice();
      this.setState({ sessionSet });
    }
  }

  private async fetchData(): Promise<void> {
    if (!this.state.selectedDevice || this.state.startTimestamp == null || this.state.endTimestamp == null) {
      return; 
    }
    this.setState({ isLoading: true });
    const dataSet = await this.state.selectedDevice.getData(this.state.startTimestamp, this.state.endTimestamp);

    if (dataSet) {
      if (this.state.liveData) {
        dataSet.addObserver(this);
      } else {
        console.log("Live off, remove observer");
        dataSet.removeObserver(this);
      }
      this.setState({
        isLoading: false,
        dataSet,
        timestampChanged: false,
      });
    } else {
      console.warn(`No dataset available for device ${this.state.selectedDevice.getId()}`);
      this.setState({ isLoading: false });
    }
  }

  private async fetchSessions(): Promise<void> {
    if (this.state.selectedDevice) {
      const sessionSet = await this.state.selectedDevice.getSessions(0, Date.now());
      this.setState({ sessionSet });
    }
  }

  private selectPoint = (timestamp: number): void => {
    const dataItems = (this.state.dataSet?.getData() ?? []) as ReferenceHWData[];
    dataItems.forEach((item: ReferenceHWData) => {
      if (Number(item.timestamp) === timestamp) {
        if (item.latitude && item.longitude) {
          const location = [item.longitude, item.latitude];
          this.setState({ selectedLocation: location });

          if (this.state.liveData) {
            this.toggleLiveData();
          }

          if (!this.state.drawerOpen) {
            this.toggleDrawer();
          }
        } else {
          if (this.state.selectedLocation) {
            this.setState({ selectedLocation: null });
          }
        }
      }
    });
  };

  private isLocationInData(): boolean {
    return this.state.dataSet?.getData()
      .some((entry) => entry.latitude != null && entry.longitude != null) ?? false;
  }

  private getLatestLocation(): number[] {
    let latestTimestamp = 0;
    let latestLocation: number[] = [];
    const dataItems: ReferenceHWData[] = (this.state.dataSet?.getData() ?? []) as ReferenceHWData[];
    dataItems.slice().forEach((data: ReferenceHWData) => {
      const timestamp = Number(data.timestamp);

      if (timestamp > latestTimestamp && data.longitude != null && data.latitude != null) {
        latestTimestamp = timestamp;
        latestLocation = [data.longitude, data.latitude];
      }
    });
    return latestLocation;
  }

  private setLatestLocation(latestLocation: number[]): void {
    const [longitude, latitude] = latestLocation;

    if (longitude && latitude) {
      const selectedLocation = [longitude, latitude];
      this.setState({ selectedLocation });
    }
  }

  private setTimeRange = (startTimestamp: Nullable<number>, endTimestamp: Nullable<number>): void => {
    this.setState({
      startTimestamp,
      endTimestamp,
      timestampChanged: true,
    });
    console.log ("Time-Range-Picker used: will fetch data and update URL if the time range input has changed."); 
  };

  private toggleLiveData = (): void => {
    this.setState((prevState: State) => ({
      liveData: !prevState.liveData,
      timestampChanged: true,
    }));
  };

  private selectSensor = (sensors: string[], sensors2: string[]): void => {
    this.setState({
      selectedSensor: sensors,
      selectedSensor2: sensors2,
    });
  };

  private selectSession = (session: Session): void => {
    if (session !== null) {
      this.setState({
        startTimestamp: Number(session.sessionId),
        endTimestamp: Number(session.sessionId) + Number(session.durationMs),
      });
    }
  };

  private toggleDrawer = (): void => {
    this.setState((prevState: State) => ({
      drawerOpen: !prevState.drawerOpen,
    }));
  };

  private renderErrorMessage(): Maybe<JSX.Element> {
    if (!this.state.isLoading) {
      return (
        <div className="error-container">
          {
            this.state.selectedDevice ?
              <Fragment>
                <p><span>Device {getDisplayName(this.state.selectedDevice)} selected.</span></p>
                <p><span>No statistics data found!</span></p>
              </Fragment> :
              <p><span>Please select device first and then select start and end time.</span></p>}
        </div >
      );
    }
  }

  private renderToolbar = (): Maybe<JSX.Element> => {
    if (this.state.selectedDevice) {
      return (
        <Fragment>
          {
            this.state.sessionSet && this.state.sessionSet.getSessions().length > 0 &&
              <SessionPicker
                sessions={this.state.sessionSet.getSessions()}
                onSelectSession={this.selectSession}
                disabled={this.state.liveData}
                reset={this.state.timestampChanged}
              />
          }
          <TimeRangePicker
            startTimestamp={this.state.startTimestamp}
            endTimestamp={this.state.endTimestamp}
            onTimeRangeSelect={this.setTimeRange}
            disabled={this.state.liveData}
          />
          <LiveControl
            liveData={this.state.liveData}
            onLiveDataToggled={this.toggleLiveData}
          />
        </Fragment>
      );
    }
  };

  private renderLoader = (): Maybe<JSX.Element> => {
    if (this.state.isLoading) {
      return <Loader />;
    }
  };

  private renderSidebar = (): Maybe<JSX.Element> => {
    if (this.state.selectedDevice && this.isLocationInData()) {
      return (
        <Drawer
          variant="persistent"
          anchor="left"
          open={this.state.drawerOpen}
          transitionDuration={500}
          classes={{ paper: "col-sm-4 col-xsm-9 iot-content-drawer" }}
        >
          <div className="history-map-container">
            <IoTMap
              selectedLocation={this.state.selectedLocation}
              mapsData={this.state.dataSet?.getData() ?? null}
            />
          </div>
          <div className="drawer-button-container">
            <Button className="drawer-button close" onClick={this.toggleDrawer}>
              <img src={ArrowDouble} alt="forward arrow" />
            </Button>
          </div>
        </Drawer>
      );
    }
  };

  private renderSidebarButton = (): Maybe<JSX.Element> => {
    if ((this.state.dataSet?.getData().length ?? 0) > 0 && !this.state.drawerOpen && this.isLocationInData()) {
      return (
        <div className="drawer-button-container">
          <Button onClick={this.toggleDrawer} className="drawer-open-button">
            <img src={ArrowDouble} alt="forward arrow" />
          </Button>
        </div>
      );
    }
  };

  private renderChart = (): Maybe<JSX.Element> => {
    const data = this.state.dataSet?.getData();

    if (data && data.length > 1) {
      return (
        <div className="chart-container">
          <IoTChart
            onPointSelect={this.selectPoint}
            selectedSensor={this.state.selectedSensor}
            selectedSensor2={this.state.selectedSensor2}
            data={data}
          />
        </div>
      );
    }
  };

  private renderHistoryTools = (): Maybe<JSX.Element> => {
    if (this.state.selectedDevice && this.state.dataSet && this.state.dataSet.getData().length > 1) {
      return (
        <IoTHistoryTools
          onSensorSelect={this.selectSensor}
          data={this.state.dataSet.getData()}
          selectedDevice={this.state.selectedDevice}
        />
      );
    }
  };

  public render(): JSX.Element {
    return (
      <Fragment>
        <div className="iot-tool-container col-xsm-12 col-sm-9 col-md-9"> 
          {this.renderToolbar()}
        </div>
        {this.renderSidebar()}
        <div
          className="iot-content-container col-sm-12 col-xsm-12"
          style={this.state.drawerOpen ? { marginLeft: "33%", width: "66%" } : undefined}
        >
          {this.renderSidebarButton()}
          {this.renderErrorMessage()}
          {this.renderLoader()}
          {this.renderChart()}
          {this.renderHistoryTools()}
        </div>
      </Fragment >
    );
  }
}

export default withRouter(HistoryView);
