/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import moment from "moment";

export default class TimeWrapper {

  public static getStartOfXDaysAgo(days: number): number {
    const now = new Date(Date.now());
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - days).getTime();
  }

  public static getTimestampXDaysAgo(days: number): number {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date.getTime();
  }

  public static getTimestampXUnitsAgo(fromTimestamp: number, num: number, unit: moment.unitOfTime.DurationConstructor): number {
    return moment(fromTimestamp).utc().subtract(num, unit).valueOf();
  }
}
