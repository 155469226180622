import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSJSON: string;
}


export interface Event {
  __typename?: 'Event';
  deviceId: Scalars['ID'];
  eventId?: Maybe<Scalars['String']>;
  eventState?: Maybe<EventState>;
  metadata?: Maybe<Scalars['String']>;
  receiver?: Maybe<Scalars['String']>;
  sensorName?: Maybe<Scalars['String']>;
  sensorValue?: Maybe<Scalars['Float']>;
  severity?: Maybe<EventSeverity>;
  timestamp: Scalars['String'];
  type?: Maybe<EventType>;
  updatedTimestamp?: Maybe<Scalars['String']>;
}

export interface EventMetadata {
  __typename?: 'EventMetadata';
  description?: Maybe<Scalars['String']>;
  eventId: Scalars['ID'];
}

export interface EventMetadataList {
  __typename?: 'EventMetadataList';
  eventMetadataItems: Array<EventMetadata>;
  nextToken?: Maybe<Scalars['String']>;
}

export interface EventPayload {
  deviceId: Scalars['ID'];
  eventId?: Maybe<Scalars['ID']>;
  eventState?: Maybe<EventState>;
  metadata?: Maybe<Scalars['String']>;
  receiver?: Maybe<Scalars['String']>;
  sensorName?: Maybe<Scalars['String']>;
  sensorValue?: Maybe<Scalars['Float']>;
  severity?: Maybe<EventSeverity>;
  timestamp: Scalars['String'];
  type?: Maybe<EventType>;
  updatedTimestamp?: Maybe<Scalars['String']>;
}

export interface Events {
  __typename?: 'Events';
  events: Array<Event>;
  nextToken?: Maybe<Scalars['String']>;
}

export enum EventSeverity {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export enum EventState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum EventType {
  Generic = 'GENERIC',
  Sensor = 'SENSOR'
}

export interface Mutation {
  __typename?: 'Mutation';
  eventsDeactivate?: Maybe<Event>;
  eventsNotify?: Maybe<Event>;
}


export interface MutationEventsDeactivateArgs {
  payload: EventPayload;
}


export interface MutationEventsNotifyArgs {
  payload: EventPayload;
  receiver: Scalars['String'];
}

export interface Query {
  __typename?: 'Query';
  devicesEventsList?: Maybe<Events>;
  eventsList?: Maybe<Events>;
  eventsMetadataList?: Maybe<EventMetadataList>;
}


export interface QueryDevicesEventsListArgs {
  deviceId: Scalars['ID'];
  nextToken?: Maybe<Scalars['String']>;
}


export interface QueryEventsListArgs {
  endTimestamp: Scalars['String'];
  nextToken?: Maybe<Scalars['String']>;
  startTimestamp: Scalars['String'];
}


export interface QueryEventsMetadataListArgs {
  nextToken?: Maybe<Scalars['String']>;
}

export interface Schema {
  __typename?: 'Schema';
  mutation?: Maybe<Mutation>;
  query?: Maybe<Query>;
  subscription?: Maybe<Subscription>;
}

export interface Subscription {
  __typename?: 'Subscription';
  eventsFeed?: Maybe<Event>;
}


export interface SubscriptionEventsFeedArgs {
  receiver: Scalars['String'];
}

export type EventFieldsFragment = { __typename?: 'Event', deviceId: string, timestamp: string, eventId?: Maybe<string>, updatedTimestamp?: Maybe<string>, type?: Maybe<EventType>, eventState?: Maybe<EventState>, severity?: Maybe<EventSeverity>, sensorName?: Maybe<string>, sensorValue?: Maybe<number>, receiver?: Maybe<string>, metadata?: Maybe<string> };

export type EventsListQueryVariables = Exact<{
  startTimestamp: Scalars['String'];
  endTimestamp: Scalars['String'];
  nextToken?: Maybe<Scalars['String']>;
}>;


export type EventsListQuery = { __typename?: 'Query', eventsList?: Maybe<{ __typename?: 'Events', nextToken?: Maybe<string>, events: Array<(
      { __typename?: 'Event' }
      & EventFieldsFragment
    )> }> };

export type DevicesEventsListQueryVariables = Exact<{
  deviceId: Scalars['ID'];
  nextToken?: Maybe<Scalars['String']>;
}>;


export type DevicesEventsListQuery = { __typename?: 'Query', devicesEventsList?: Maybe<{ __typename?: 'Events', nextToken?: Maybe<string>, events: Array<(
      { __typename?: 'Event' }
      & EventFieldsFragment
    )> }> };

export type EventsMetadataListQueryVariables = Exact<{
  nextToken?: Maybe<Scalars['String']>;
}>;


export type EventsMetadataListQuery = { __typename?: 'Query', eventsMetadataList?: Maybe<{ __typename?: 'EventMetadataList', nextToken?: Maybe<string>, eventMetadataItems: Array<{ __typename?: 'EventMetadata', eventId: string, description?: Maybe<string> }> }> };

export type EventsDeactivateMutationVariables = Exact<{
  payload: EventPayload;
}>;


export type EventsDeactivateMutation = { __typename?: 'Mutation', eventsDeactivate?: Maybe<(
    { __typename?: 'Event' }
    & EventFieldsFragment
  )> };

export type EventsFeedSubscriptionVariables = Exact<{
  receiver: Scalars['String'];
}>;


export type EventsFeedSubscription = { __typename?: 'Subscription', eventsFeed?: Maybe<(
    { __typename?: 'Event' }
    & EventFieldsFragment
  )> };

export const EventFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"eventFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Event"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deviceId"}},{"kind":"Field","name":{"kind":"Name","value":"timestamp"}},{"kind":"Field","name":{"kind":"Name","value":"eventId"}},{"kind":"Field","name":{"kind":"Name","value":"updatedTimestamp"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"eventState"}},{"kind":"Field","name":{"kind":"Name","value":"severity"}},{"kind":"Field","name":{"kind":"Name","value":"sensorName"}},{"kind":"Field","name":{"kind":"Name","value":"sensorValue"}},{"kind":"Field","name":{"kind":"Name","value":"receiver"}},{"kind":"Field","name":{"kind":"Name","value":"metadata"}}]}}]} as unknown as DocumentNode<EventFieldsFragment, unknown>;
export const EventsListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"eventsList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"startTimestamp"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"endTimestamp"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eventsList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"startTimestamp"},"value":{"kind":"Variable","name":{"kind":"Name","value":"startTimestamp"}}},{"kind":"Argument","name":{"kind":"Name","value":"endTimestamp"},"value":{"kind":"Variable","name":{"kind":"Name","value":"endTimestamp"}}},{"kind":"Argument","name":{"kind":"Name","value":"nextToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"events"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"eventFields"}}]}},{"kind":"Field","name":{"kind":"Name","value":"nextToken"}}]}}]}},...EventFieldsFragmentDoc.definitions]} as unknown as DocumentNode<EventsListQuery, EventsListQueryVariables>;
export const DevicesEventsListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"devicesEventsList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"deviceId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"devicesEventsList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"deviceId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"deviceId"}}},{"kind":"Argument","name":{"kind":"Name","value":"nextToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"events"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"eventFields"}}]}},{"kind":"Field","name":{"kind":"Name","value":"nextToken"}}]}}]}},...EventFieldsFragmentDoc.definitions]} as unknown as DocumentNode<DevicesEventsListQuery, DevicesEventsListQueryVariables>;
export const EventsMetadataListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"eventsMetadataList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eventsMetadataList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"nextToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nextToken"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eventMetadataItems"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eventId"}},{"kind":"Field","name":{"kind":"Name","value":"description"}}]}},{"kind":"Field","name":{"kind":"Name","value":"nextToken"}}]}}]}}]} as unknown as DocumentNode<EventsMetadataListQuery, EventsMetadataListQueryVariables>;
export const EventsDeactivateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"eventsDeactivate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"payload"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EventPayload"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eventsDeactivate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"payload"},"value":{"kind":"Variable","name":{"kind":"Name","value":"payload"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"eventFields"}}]}}]}},...EventFieldsFragmentDoc.definitions]} as unknown as DocumentNode<EventsDeactivateMutation, EventsDeactivateMutationVariables>;
export const EventsFeedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"eventsFeed"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"receiver"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"eventsFeed"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"receiver"},"value":{"kind":"Variable","name":{"kind":"Name","value":"receiver"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"eventFields"}}]}}]}},...EventFieldsFragmentDoc.definitions]} as unknown as DocumentNode<EventsFeedSubscription, EventsFeedSubscriptionVariables>;