/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Auth } from "aws-amplify";
import AWSThingGroup from "../device/AWSThingGroup";
import DeviceGroup from "../device/DeviceGroup";
import { Service } from "./AppSyncClientProvider";
import Backend, { CreateDeviceGroupParams } from "./Backend";
import Device from "../device/Device";
import AppSyncClientFactory from "./AppSyncClientFactory";
import AuthWrapper from "../auth/authWrapper";
import { Maybe, Nullable } from "../../types/aliases";
import { throwGQLError } from "../utils/utils";
import { UrlsQsEmbedGenerateDocument, UrlsQsEmbedGenerateQueryVariables } from "../../generated/gqlStats";
import {
  DeviceFieldsFragment,
  DeviceGroupFieldsFragment,
  DeviceGroupsCreateDocument,
  DeviceGroupsDevicesListDocument,
  DeviceGroupsGetDocument,
  DeviceGroupsListDocument,
  DevicesGetDocument,
  DevicesSearchDocument,
} from "../../generated/gqlDevice";
import DeviceFactory from "../../DeviceFactory";
import { Attribute } from "../device/Attribute";
import AuthListener, { AuthEvent } from "../auth/authListener";
import AsyncCache from "../utils/AsynCache";
import { isDefined } from "../../utils/types";

export function narrowDownAttributeTypes(attrs: Array<{key: string; value?: Nullable<string>}>): Attribute[] {
  return attrs.map(({ key, value }) => ({ key, value: value ?? undefined }));
}

export default class AWSBackend implements Backend {
  // TODO: it might be smarter to cache devices to DeviceFactory
  private deviceCache = new AsyncCache<Device>();
  private groupCache = new AsyncCache<DeviceGroup>();
  private rootGroupIds?: string[];

  private authEventHandler = (event: AuthEvent): void => {
    if (event === "SignedOut") {
      this.groupCache.clear();
      this.deviceCache.clear();
      this.rootGroupIds = undefined;
    }
  };

  private readonly authListener = new AuthListener(this.authEventHandler);

  public async getQsEmbedUrl(openIdToken: string, dashboardId: string): Promise<Maybe<string>> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const variables: UrlsQsEmbedGenerateQueryVariables = {
        request: {
          dashboardId,
          emailAddress: user.username,
          openIdToken,
          sessionName: user.username,
          undoRedoDisabled: true,
          resetDisabled: true,
          sessionLifetimeInMinutes: 600,
        },
      };
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.STATS);
      const embedUrlResponse = await client.query(UrlsQsEmbedGenerateDocument, variables);
      const embedUrl = embedUrlResponse.data.urlsQsEmbedGenerate?.embedUrl;
      console.log("Embed URL: " + embedUrl);
      return embedUrl ?? undefined;
    } catch (error) {
      console.error("Error: ", error);
    }
  }
  
  public async getDevice(id: string): Promise<Maybe<Device>> {
    const fetchDevice = async (): Promise<Maybe<Device>> => {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
      const response = await client.query(
        DevicesGetDocument,
        {
          deviceId: id,
        },
      );

      if (!response.data.devicesGet) {
        return;
      }

      return this.deviceFragmentIntoDevice(response.data.devicesGet);
    };
    
    return this.deviceCache.get(id, fetchDevice);
  }
  
  public async getDeviceGroup(id: string): Promise<Maybe<DeviceGroup>> {
    const fetchDeviceGroup = async (): Promise<Maybe<DeviceGroup>> => {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);

      const response = await client.query(
        DeviceGroupsGetDocument,
        {
          groupId: id,
        },
      );

      if (response.data.deviceGroupsGet) {
        return this.fragmentIntoDeviceGroup(response.data.deviceGroupsGet);
      }
    };

    return this.groupCache.get(id, fetchDeviceGroup);
  }

  public async getRootDeviceGroups(): Promise<DeviceGroup[]> {
    if (this.rootGroupIds) {
      const groups = await Promise.all(this.rootGroupIds.map(id => this.getDeviceGroup(id)));
      const filteredGroups = groups.filter(isDefined);

      if (groups.length !== filteredGroups.length) {
        console.error("Invalid root group id cache state, adjusting");
        this.rootGroupIds = filteredGroups.map(group => group.getId());
      }
      return filteredGroups;
    }

    const groups = await this.getDeviceGroups();
    this.rootGroupIds = groups.map(group => group.getId());
    return groups;
  }

  public async getAllDeviceGroups(): Promise<DeviceGroup[]> {
    return this.getDeviceGroups({ recursive: true });
  }

  public async getDeviceGroups({ parent, recursive = false }: {parent?: DeviceGroup; recursive?: boolean} = {}): Promise<DeviceGroup[]> {
    try {
      let nextToken: Nullable<string> = null;
      let groupFragments: DeviceGroupFieldsFragment[] = [];

      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);

      do {
        const groupListResponse = await client.query(
          DeviceGroupsListDocument,
          {
            recursive,
            includeAttributes: true,
            parentGroupId: parent?.getId(),
            nextToken,
          },
        );
        // for some reason, typescript gets trapped into a circular inference hell, if the type of the
        // nextToken is not respecified
        nextToken = (groupListResponse.data?.deviceGroupsList?.nextToken ?? null) as Nullable<string>;
        groupFragments = groupFragments.concat(groupListResponse.data.deviceGroupsList?.deviceGroups ?? []);
      } while (nextToken);

      return this.cacheFragments(this.groupCache, groupFragments, this.fragmentIntoDeviceGroup);
    } catch (error) {
      console.error("Error", error);
      return [];
    }
  }

  public async createDeviceGroup(params: CreateDeviceGroupParams): Promise<void> {
    if (params.parentGroup && !AWSThingGroup.instanceOf(params.parentGroup)) {
      throw new Error("Invalid DeviceGroup implementation for parent group");
    }

    const org: string = params.organizationId ?? await AWSBackend.getOrganization(params.parentGroup);
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
    const groupResponse = await client.mutate(
      DeviceGroupsCreateDocument,
      {
        groupId: params.displayName,
        parentGroupId: params.parentGroup?.getId() ?? null,
        organizationId: org,
      },
    );

    if (!groupResponse.data?.deviceGroupsCreate) {
      console.error("Failed to create group, backend response empty: " + JSON.stringify(groupResponse.errors));
      throwGQLError(groupResponse);
    }

    const newGroup = await this.fragmentIntoDeviceGroup(groupResponse.data.deviceGroupsCreate);

    this.groupCache.set(newGroup.getId(), newGroup);

    if (!params.parentGroup) {
      this.rootGroupIds = (this.rootGroupIds ?? []).concat(newGroup.getId());
    } else {
      await (params.parentGroup as AWSThingGroup).addGroup(newGroup);
    }
  }

  public async searchDevices(query: string): Promise<Device[]> {
    // TODO: should we have query-specific cache?
    try {
      const deviceFragments: DeviceFieldsFragment[] = [];
      let nextToken: Nullable<string> = null;

      do {
        const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        const searchDevicesResponse = await appSyncClient.query(
          DevicesSearchDocument,
          {
            query,
            nextToken,
          },
        );
        nextToken = (searchDevicesResponse.data?.devicesSearch?.nextToken ?? null) as Nullable<string>;
        deviceFragments.push(...(searchDevicesResponse.data?.devicesSearch?.devices ?? []));
      } while (nextToken);

      return this.cacheFragments(this.deviceCache, deviceFragments, this.deviceFragmentIntoDevice);
    } catch (error) {
      console.error("searchDevices error", error);
      return [];
    }
  }
  
  /////
  /// AWSBackend specific public methods
  /////
  
  public async getDeviceGroupDevices(group: AWSThingGroup): Promise<Device[]> {
    try {
      let nextToken: Nullable<string> = null;
      let deviceFragments: DeviceFieldsFragment[] = [];

      do {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        const deviceIdListResponse = await client.query(
          DeviceGroupsDevicesListDocument,
          {
            groupId: group.getId(),
            nextToken,
          },
        );
        // cast is required to avoid cyclic type inference on response type
        nextToken = (deviceIdListResponse.data.deviceGroupsDevicesList?.nextToken ?? null) as Nullable<string>;
        deviceFragments = deviceFragments.concat(deviceIdListResponse.data.deviceGroupsDevicesList?.devices ?? []);
      } while (nextToken);

      return this.cacheFragments(this.deviceCache, deviceFragments, this.deviceFragmentIntoDevice);
    } catch (error) {
      console.error("Error", error);
      return [];
    }
  }
  
  public async removeLocal(thing: Device | DeviceGroup): Promise<void> {
    if (Device.instanceOf(thing)) {
      await this.deviceCache.delete(thing.getId());
    } else if (DeviceGroup.instanceOf(DeviceGroup)) {
      await this.groupCache.delete(thing.getId());
    }
  }

  /**
   * Takes a collection of fragments, which are either
   * - if id matches something in cache, replaced with the cached entity
   * - converted into the desired entity, and then cached
   *
   * @param cache
   *    an AsyncCache into which to store the converted fragments
   * @param fragments
   *    list of fragments to go through
   * @param fragmentConverter
   *    method for converting fragment into the desired entity type
   * @private
   */
  private async cacheFragments<TFrag extends { id: string }, TType>(cache: AsyncCache<TType>, fragments: TFrag[], fragmentConverter: (f: TFrag) => Promise<Maybe<TType>>): Promise<TType[]>{
    const results = await Promise.all(
      fragments.map(fragment => cache.get(fragment.id, () => fragmentConverter(fragment))),
    );
    return results.filter(isDefined);
  }

  private fragmentIntoDeviceGroup = async (fragment: DeviceGroupFieldsFragment): Promise<AWSThingGroup> => {
    return new AWSThingGroup(
      this,
      {
        groupId: fragment.id,
        attributes: narrowDownAttributeTypes(fragment.attr), 
      });
  };

  private deviceFragmentIntoDevice = async (fragment: DeviceFieldsFragment): Promise<Maybe<Device>> => {
    const device = DeviceFactory.createDevice(
      fragment.id,
      fragment.type,
      narrowDownAttributeTypes(fragment.attr),
    );

    if (device) {
      // While this is pretty bad for performance, it was already done everywhere anyways.
      // It should actually probably be part of the factory instead.
      await device.init();
      return device;
    }
  };

  private static async getOrganization(group?: DeviceGroup): Promise<string> {
    let org = group ? group.getOrganization() : undefined;

    if (!org || org.length === 0) {
      const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();
      org = claims?.homeOrganizationId;
    }

    if (!org) {
      throw new Error("No organization available");
    }

    return org;
  }
}
