/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component, FunctionComponent, PropsWithChildren, ReactElement } from "react";
import { Grid, Tab, Tabs } from "@material-ui/core";
import AuthWrapper from "../../data/auth/authWrapper";
import DeviceGroup from "../../data/device/DeviceGroup";
import InlineBox from "../ui/inline-box";
import TabPanel from "../ui/tab-panel";
import BackendFactory from "../../BackendFactory";
import Organization from "../../data/organization/Organization";
import ViewAccessMethods from "../../ViewAccessMethods";
import { Maybe } from "../../types/aliases";
import OrganizationSelector from "./components/organization-selector";
import Permissions from "./components/permissions";
import DeviceManagement from "./components/device-management";
import OrganizationManagement from "./components/organization-management";
import UserList from "./components/user-list";
import accessControlled from "../access-control/access-controlled";

interface State {
  selectedTab: number;
  homeOrganization?: Organization;
  selectedOrganization?: Organization;
  currentUserId?: string;
  searchFilter?: string;
  errorMsg?: string;
}

interface Props {
  groups?: DeviceGroup[];
  legacyBrowserMode: boolean;
  triggerGroupsUpdate: () => void;
}

type TabPageProps = { currentPage: number; index: number };

const TabPage: FunctionComponent<TabPageProps> = (props: PropsWithChildren<TabPageProps>): ReactElement => {
  return (
    <TabPanel
      value={props.currentPage}
      index={props.index}
    >
      <InlineBox>
        {props.children}
      </InlineBox>
    </TabPanel>
  );
};

export class AdminView extends Component<Props, State> {
  private readonly organizationBackend = BackendFactory.getOrganizationBackend();

  public constructor(props: Props) {
    super(props);
    this.state = {
      selectedTab: 0,
    };
  }

  public async componentDidMount(): Promise<void> {
    const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();

    if (!claims) {
      throw new Error("Failed to resolve current user!");
    }

    const homeOrganization = await this.organizationBackend.getCurrentHomeOrganization();

    this.setState({
      homeOrganization,
      currentUserId: claims.userId,
      selectedOrganization: homeOrganization,
    });
  }

  private handleOrganizationSelected = (organization: Organization): void => {
    this.setState({
      selectedOrganization: organization,
    });
  };

  private renderOrganizationStructure(): Maybe<JSX.Element> {
    if (this.state.selectedOrganization) {
      return (
        <OrganizationManagement
          organization={this.state.selectedOrganization}
        />
      );
    }
  }

  private renderOrganizationPolicies(): Maybe<JSX.Element> {
    if (this.state.selectedOrganization) {
      return (
        <Permissions
          organization={this.state.selectedOrganization}
        />
      );
    }
  }

  private renderUsersList(): Maybe<JSX.Element> {
    if (this.state.selectedOrganization && this.state.currentUserId) {
      return (
        <UserList
          organization={this.state.selectedOrganization}
          currentUserId={this.state.currentUserId}
        />
      );
    }
  }

  private renderDeviceList(): Maybe<JSX.Element> {
    return (
      <DeviceManagement
        groups={this.props.groups}
        triggerGroupsUpdate={this.props.triggerGroupsUpdate}
      />
    );
  }

  private renderOrganizationSelector(): Maybe<JSX.Element> {
    if (this.state.homeOrganization) {
      return (
        <OrganizationSelector rootOrganization={this.state.homeOrganization}
          organizationSelected={this.handleOrganizationSelected}/>
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private handlePageChange = (event: React.ChangeEvent<{}>, value: number): void => {
    console.log(`Changing page from ${this.state.selectedTab} to ${value}`);
    this.setState({ selectedTab: value });
  };

  public render(): JSX.Element {
    return (
      <div style={{ margin: "1rem" }}>
        <Grid container={true} spacing={1} justify={"center"}>
          <Grid item={true} md={12} lg={10} xl={8}>
            {this.renderOrganizationSelector()}
            <Tabs
              value={this.state.selectedTab}
              onChange={this.handlePageChange}
            >
              <Tab label="Users"/>
              <Tab label="Devices"/>
              <Tab label="Organization"/>
              <Tab label="Policies"/>
            </Tabs>
            <TabPage currentPage={this.state.selectedTab} index={0}>
              {this.renderUsersList()}
            </TabPage>
            <TabPage currentPage={this.state.selectedTab} index={1}>
              {this.renderDeviceList()}
            </TabPage>
            <TabPage currentPage={this.state.selectedTab} index={2}>
              {this.renderOrganizationStructure()}
            </TabPage>
            <TabPage currentPage={this.state.selectedTab} index={3}>
              {this.renderOrganizationPolicies()}
            </TabPage>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default accessControlled(AdminView, ViewAccessMethods.hasAdminAccess);
