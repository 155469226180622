/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { CognitoUser } from "@aws-amplify/auth";
import Amplify, { Auth } from "aws-amplify";
import AWS, { CognitoIdentity } from "aws-sdk";
import awsconfig from "../../aws-config";
import { Maybe } from "../../types/aliases";

Amplify.configure(awsconfig);

interface GetOpenIdParams {
  IdentityId: string;
  Logins: { [key: string]: string };
}

const ORG_ID_PREFIX = "ORG/";
const USER_ID_PREFIX = "USER/";

// the custom claims are set by preTokenGenerationHook in users-service (but many vtl files depend on these values)
const GRANTS_CLAIM = "custom:policies";
const HOME_CLAIM = "custom:home";
const ORGANIZATIONS_CLAIM = "custom:orgs";
const USER_ID_CLAIM = "sub";

export type UserGrants = {
  [organizationId: string]: string[];
};

export interface UserClaims {
  userId: string;
  homeOrganizationId: string;
  uniqueParentOrganizations: string[];
  canSee: string[];
  grants: UserGrants;
}

// REFACTOR: Hard to unit test as dependencies are built in. Could use dependency injection.
// Also usage of static methods can make mocking hard in classes that use AuthWrapper.
export default class AuthWrapper {
  // set to undefined at logOut
  private static userClaims?: UserClaims;

  // TODO: Fix any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static async logIn(email: string, password: string): Promise<CognitoUser | any> {
    if (password === "") {
      throw new Error("Empty password");
    }

    try {
      return Auth.signIn(email, password);
    } catch (error) {
      throw new Error(error.message || error); // provide error data to login-content logIn UI messages
    }
  }

  // TODO: Fix any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static async logOut(): Promise<any> {
    try {
      await Auth.signOut();
      AuthWrapper.userClaims = undefined;
    } catch (error) {
      throw new Error(error.message || error);
    }
  }

  // Send verification code to email (to existing user):
  public static async forgotPassword(email: string): Promise<void> {
    await Auth.forgotPassword(email);
    console.log("Verification code sent to email ", email);
  }

  // Check verification code from the backend and save new password (existing user):
  public static async checkCodeAndSubmitNewPassword(email: string, code: string, new_pwd: string): Promise<void> {
    try {
      await Auth.forgotPasswordSubmit(email, code, new_pwd);
      console.log("Password of the user ", email, " has been successfully changed.");
    } catch (error) {
      throw new Error(error.message || error); // provide error data to login-content submitNewPasswordAndCheckVerificationCode UI messages
    }
  }

  // Save new password (new user):
  // TODO: Fix any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static async completeNewPassword(user: CognitoUser | any, password: string): Promise<CognitoUser | any> {
    try {
      return await Auth.completeNewPassword(user, password, {});
    } catch (error) {
      throw new Error(error.message || error); // provide error data to login-content completePassword UI messages
    }
  }

  public static async isCurrentUserAuthenticated(): Promise<boolean> {
    return await this.getCurrentAuthenticatedUser(false) != null;
  }

  public static async getCurrentAuthenticatedUser(refreshFromServer: boolean): Promise<Maybe<CognitoUser>> {
    try {
      return await Auth.currentAuthenticatedUser({
        bypassCache: refreshFromServer,
      });
    } catch (error) {
      console.log(`Could not retrieve authenticated user (${error})`);
    }
  }

  public static async getCurrentAuthenticatedUserClaims(): Promise<Maybe<UserClaims>> {
    if (!AuthWrapper.userClaims) {
      const claims = (await AuthWrapper.getCurrentAuthenticatedUser(false))
        ?.getSignInUserSession()
        ?.getIdToken()
        ?.decodePayload();

      if (!claims) {
        console.error("Cannot retrieve claims for an unauthenticated user");
        return;
      }

      const rawUserId = claims[USER_ID_CLAIM];
      const organizations = (claims[ORGANIZATIONS_CLAIM] ? JSON.parse(claims[ORGANIZATIONS_CLAIM]) as string[] : [])
        // add organization prefix to IDs
        .map((organization) => ORG_ID_PREFIX + organization);
      const grantsRaw: UserGrants = claims[GRANTS_CLAIM] ? JSON.parse(claims[GRANTS_CLAIM]) : {};

      AuthWrapper.userClaims = {
        userId: USER_ID_PREFIX + rawUserId,
        homeOrganizationId: ORG_ID_PREFIX + claims[HOME_CLAIM],
        uniqueParentOrganizations: organizations,
        // user id in canSee value does not have user prefix, but organizations have organization prefix
        canSee: [rawUserId].concat(organizations),
        // add organization prefix to raw id keys
        grants: Object.entries(grantsRaw).reduce((acc, [key, value]) => {
          acc[ORG_ID_PREFIX + key] = value;
          return acc;
        }, {} as UserGrants),
      };
    }
    return AuthWrapper.userClaims;
  }

  public static async getCurrentAuthenticatedUsername(): Promise<string> {
    const loggedInUser = await Auth.currentAuthenticatedUser();
    return loggedInUser.username;  // provide username for change-userAttributes
  }

  public static async retrieveGivenName(): Promise<string> {
    const loggedInUserAttributes = await Auth.currentUserInfo();
    return loggedInUserAttributes.attributes.given_name;  // provide first name for change-userAttributes
  }

  public static async retrieveFamilyName(): Promise<string> {
    const loggedInUserAttributes = await Auth.currentUserInfo();
    return loggedInUserAttributes.attributes.family_name;  // provide family name for change-userAttributes
  }

  public static async updateAttributes(firstName: string, lastName: string): Promise<void> {
    try {
      const loggedInUser = await Auth.currentAuthenticatedUser();
      const successResult = await Auth.updateUserAttributes(loggedInUser, {
        given_name: firstName,
        family_name: lastName,
      });
      console.log ("(", successResult, ")", "First/last name of the user ", loggedInUser.username, "now successfully changed.");
    } catch (error) {
      throw new Error(error.message || error); // provide error data to change-userAttributes UI messages
    }
  }

  // Check old password from the backend and save new password (existing user, already logged in):
  public static async checkOldPasswordAndSubmitNewPassword(email: string, old_pwd: string, new_pwd: string): Promise<void> {
    try {
      const loggedInUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(loggedInUser, old_pwd, new_pwd);
      console.log ("Password of the user ", email, " has now been successfully changed.");
    } catch (error) {
      throw new Error(error.message || error); // provide error data to change-password UI messages
    }
  }

  public static async getOpenIdToken(): Promise<Maybe<string>> {
    const currentSession = await Auth.currentSession();
    const cognitoAuthenticatedLoginsKey = `cognito-idp.${awsconfig.Auth.region}.amazonaws.com/${awsconfig.Auth.userPoolId}`;
    const cognitoAuthenticatedLogins = { [cognitoAuthenticatedLoginsKey]: currentSession.getIdToken().getJwtToken() };

    if (!awsconfig.Auth.identityPoolId) {
      console.error("No identity pool id available");
      return;
    }

    const getIdParams: CognitoIdentity.Types.GetIdInput = {
      IdentityPoolId: awsconfig.Auth.identityPoolId,
      Logins: cognitoAuthenticatedLogins,
    };

    if (!AWS.config.region) {
      AWS.config.update({
        region: awsconfig.Auth.region,
      });
    }

    const cognitoIdentity = new AWS.CognitoIdentity();

    try {
      const data: AWS.CognitoIdentity.GetIdResponse = await cognitoIdentity.getId(getIdParams).promise();

      if (!data.IdentityId) {
        console.error("No identity id available");
        return;
      }
      const getOpenIdParams: GetOpenIdParams = {
        IdentityId: data.IdentityId,
        Logins: cognitoAuthenticatedLogins,
      };
      const response: AWS.CognitoIdentity.GetOpenIdTokenResponse = await cognitoIdentity.getOpenIdToken(getOpenIdParams).promise();
      return response.Token;
    } catch (error) {
      console.error("getOpenIdToken", error);
    }
  }
}
