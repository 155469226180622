/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Service } from "../backend/AppSyncClientProvider";
import { Data, DataUtil } from "./Data";
import DataSet, { DataObserver } from "./DataSet";
import DataSubscriptionManager from "./DataSubscriptionManager";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import { Maybe, Nullable } from "../../types/aliases";
import { isDefined } from "../../utils/types";
import { DevicesMeasurementsListDocument } from "../../generated/gqlData";
import { GeneratorConfig, SyntheticDataGenerator } from "./DataHolder";

export interface AWSDataSetOptions<TData extends Data> {
  deviceId: string;
  type: string;
  startTimestamp: number;
  endTimestamp: number;
  syntheticDataGenerator?: SyntheticDataGenerator<TData>;
}

function getGeneratorConfig<TData extends Data>(start: number, end: number, generator?: SyntheticDataGenerator<TData>): Maybe<GeneratorConfig<TData>> {
  if (generator) {
    return {
      generator,
      sampleRange: {
        start,
        end,
      },
    };
  }
}

export default class AWSDataSet<TData extends Data> extends DataSet<TData> {
  private readonly deviceId: string;
  private readonly type: string;
  private readonly startTimestamp: number;
  private readonly endTimestamp: number;

  public constructor(options: AWSDataSetOptions<TData>) {
    super({
      syntheticDataGeneratorConfig: getGeneratorConfig(
        options.startTimestamp, options.endTimestamp, options.syntheticDataGenerator),
    });
    this.deviceId = options.deviceId;
    this.type = options.type;
    this.startTimestamp = options.startTimestamp;
    this.endTimestamp = options.endTimestamp;
  }

  public getId(): string {
    return this.deviceId;
  }

  public getType(): string {
    return this.type;
  }

  public getStartTimestamp(): number {
    return this.startTimestamp;
  }

  public getEndTimestamp(): number {
    return this.endTimestamp;
  }

  public async addObserver(observer: DataObserver<TData>): Promise<void> {
    await this.fetchSemaphore.guard();
    super.addObserver(observer);

    if (this.observerCount === 1) {
      DataSubscriptionManager.getInstance().subscribeData(this);
    }
  }

  public removeObserver(observer: DataObserver<TData>): void {
    super.removeObserver(observer);

    if (this.observerCount === 0) {
      DataSubscriptionManager.getInstance().removeSubscription(this);
    }
  }

  protected async fetchData(): Promise<void> {
    let nextToken: Nullable<string> = null;
    let data: TData[] = [];

    try {
      do {
        const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DATA);
        const dataResponse = await appSyncClient.query(
          DevicesMeasurementsListDocument,
          {
            deviceId: this.deviceId,
            startTimestamp: this.startTimestamp.toString(),
            endTimestamp: this.endTimestamp.toString(),
            nextToken,
          },
        );
        // cast is required or response encounters a cyclic type inference
        nextToken = (dataResponse.data.devicesMeasurementsList?.nextToken ?? null) as Nullable<string>;
        const dataItems: TData[] = (dataResponse.data.devicesMeasurementsList?.measurementItems ?? [])
          .map((item) => DataUtil.parseDataFragment<TData>(item, this.type))
          .filter(isDefined);
        data = data.concat(dataItems);
      } while (nextToken);
      this.setData(data);
    } catch (error) {
      console.error("Error", error);
    }
  }
}
