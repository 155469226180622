/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Button } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import Device from "../../data/device/Device";
import SettingsIcon from "../../assets/baseline-settings-20px.svg";
import DeviceSettingsPopup from "./device-settings-popup";
import { Maybe, Nullable } from "../../types/aliases";

interface Props {
  device: Nullable<Device>;
  isIcon: boolean;
}

interface State {
  showSettings: boolean;
}

export default class DeviceSettingsButton extends Component<Props, State> {
  
  public constructor(props: Props) {
    super(props);
    this.state = {
      showSettings: false,
    };
  }

  private toggleDeviceSettings = (): void => {
    this.setState((prevState: State) => ({ showSettings: !prevState.showSettings }));
  };

  private renderSettingsPopupIcon(): Maybe<JSX.Element> {
    if (!this.props.device) {
      return;
    }

    if (this.props.isIcon) {
      return (
        <img
          className="status-icon action"
          alt="Settings"
          src={SettingsIcon}
          onClick={this.toggleDeviceSettings}
        />
      );
    } else {
      return (
        <Button
          variant="contained"
          color="primary"
          className="button left"
          onClick={this.toggleDeviceSettings}
        >
          Settings
        </Button>
      );
    }
  }

  private renderSettingsPopup(): Maybe<JSX.Element> {
    if (this.state.showSettings) {
      return (
        <DeviceSettingsPopup
          selectedDevice={this.props.device!}
          closeSettings={this.toggleDeviceSettings}
        />
      );
    }
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        {this.renderSettingsPopupIcon()}
        {this.renderSettingsPopup()}
      </Fragment>
    );
  }
}
