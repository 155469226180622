/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

// any must be used in constructor types, unknown is too tight and wont match any constructor with actual parameters

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TypedCtor<T> = new (...args: any[]) => T;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Ctor = TypedCtor<unknown>;

export type InstanceTypeOfCtor<TCtor extends TypedCtor<unknown>> = TCtor extends TypedCtor<infer TEntity> ? TEntity : never;
export type PairType = [Ctor, Ctor];

/**
 * Contains paired (or recently unpaired entities).
 */
export class RelationChange {
  private readonly ctor: Ctor;
  public constructor(entity: HasEntityRelations | Ctor) {
    this.ctor = entity.constructor.name === "Function" ? entity as Ctor : entity.constructor as Ctor;
  }
  
  public ofType<TCtor extends TypedCtor<unknown>>(type: TCtor): boolean {
    return this.ctor === type;
  }
}

export interface HasEntityRelations {
  onRelationChange(change: RelationChange): void;
}

/**
 * Caches entities that have a N-M relationship, and both of those entities need to be aware of the other.
 * For example, there are entities of type A and B, and they have the following methods:
 * A::getMyBEntities() => B[]
 * B::getMyAEntities() => A[]
 *
 * Given that there are ways to mutate those lists, and mutations to one list can cause mutations to the other list,
 * this cache centralizes those relationships without having to use cross-listeners.
 *
 */
export class EntityRelationCache {
  // TODO:  if the cache lacks performance for large number of entities (especially listFor), this can be improved
  //        by replacing Set<HasEntityRelations> with Map<Ctor, Set<HasEntityRelations>>. This will allow listFor to
  //        perform in O(1)
  private entityMap = new Map<HasEntityRelations, Set<HasEntityRelations>>();

  /**
   * Creates a linked pair.
   * Self-links are ignored (cannot link an entity to itself).
   *
   * @param a
   *    entity
   * @param b
   *    entity
   */
  public link(a: HasEntityRelations, b: HasEntityRelations): void {
    if (a === b) {
      return;
    }

    const ab = this.addFor(a, b);
    const ba = this.addFor(b, a);

    if (ab || ba) {
      this.notifyActionToBoth(a, b);
    }
  }

  /**
   * Removes a link from between pairs
   *
   * @param a
   *    entity
   * @param b
   *    entity
   */
  public unlink(a: HasEntityRelations, b: HasEntityRelations): void {
    const ab = this.removeFor(a, b);
    const ba = this.removeFor(b, a);

    if (ab || ba) {
      this.notifyActionToBoth(a, b);
    }
  }

  /**
   * Retrieves all entities of particular type linked to the given entity
   *
   * @param a
   *    entity
   * @param ctor
   *    type's constructor
   */
  public listFor<TCtor extends TypedCtor<unknown>>(a: HasEntityRelations, ctor: TCtor): InstanceTypeOfCtor<TCtor>[] {
    const set = this.entityMap.get(a);

    if (!set) {
      return [];
    }

    const entityList: InstanceTypeOfCtor<TCtor>[] = [];
    set.forEach((entity) => {
      if (entity instanceof ctor) {
        entityList.push(entity as InstanceTypeOfCtor<TCtor>);
      }
    });
    return entityList;
  }

  /**
   * Removes those links of a's where the linked entity does not exist in the entities list.
   * Then links those entities, that were not already linked.
   *
   * @param a
   *    entity
   * @param typeHint
   *    constructor of the entities. Used as a type hint to perform replacements
   * @param entities
   *    list of potentially new entities to link
   * @param keepFilter
   *    method for checking, whether an old entity should be kept as linked
   */
  public replaceLinks<TEntity extends HasEntityRelations>(a: HasEntityRelations, typeHint: TypedCtor<TEntity>, entities: TEntity[], keepFilter?: (entity: TEntity) => boolean): void {
    const keep = keepFilter ?? ((_: TEntity): boolean => false);

    const newSet = new Set(entities as HasEntityRelations[]);
    const currentEntitiesOfType = new Set<HasEntityRelations>(this.listFor(a, typeHint));
    const previousLinkCount = currentEntitiesOfType.size;

    const ctorSet = new Set<Ctor>();
    
    const change = new RelationChange(a);

    for (const oldEntity of currentEntitiesOfType) {
      if (!newSet.has(oldEntity) && !keep(oldEntity as TEntity)) {
        // keepSet is true, so if the set empties out, the entity will not be removed
        if (this.removeFor(a, oldEntity, true)) {
          ctorSet.add(oldEntity.constructor as Ctor);
        }

        if (this.removeFor(oldEntity, a)) {
          this.notifyRelationChange(oldEntity, change);
        }
      }
    }

    for (const newEntity of newSet) {
      if (!currentEntitiesOfType.has(newEntity)) {
        if (this.addFor(a, newEntity)) {
          ctorSet.add(newEntity.constructor as Ctor);
        }

        if (this.addFor(newEntity, a)) {
          this.notifyRelationChange(newEntity, change);
        }
      }
    }

    if (ctorSet.size > 0) {
      [...ctorSet]
        .map(ctor => new RelationChange(ctor))
        .forEach(change => this.notifyRelationChange(a, change));
    }

    if (previousLinkCount > 0 && this.getSet(a).size === 0) {
      this.entityMap.delete(a);
    }
  }

  /**
   * Removes entity and all its links from the cache
   *
   * @param a
   *    entity
   */
  public remove(a: HasEntityRelations): boolean {
    const set = this.entityMap.get(a);

    if (!set) {
      return false;
    }
    this.entityMap.delete(a);
    
    const change = new RelationChange(a);
    
    set.forEach((b) => {
      this.removeFor(b, a);
      this.notifyRelationChange(b, change);
    });
    return true;
  }

  /**
   * Does cache contain mapping for entity
   *
   * @param a
   *    entity
   */
  public contains(a: HasEntityRelations): boolean {
    return this.entityMap.has(a);
  }

  /**
   * Empties the cache
   */
  public clear(): void {
    this.entityMap.clear();
  }

  private addFor(a: HasEntityRelations, b: HasEntityRelations): boolean {
    const set = this.getSet(a);

    if (set.has(b)) {
      return false;
    } else {
      set.add(b);
      return true;
    }
  }

  private removeFor(a: HasEntityRelations, b: HasEntityRelations, keepSet?: boolean): boolean {
    const set = this.entityMap.get(a);

    if (!set) {
      return false;
    }
    const result = set.delete(b);

    if (!keepSet && set.size === 0) {
      this.entityMap.delete(a);
    }
    return result;
  }

  private getSet(a: HasEntityRelations): Set<HasEntityRelations> {
    if (!this.entityMap.has(a)) {
      this.entityMap.set(a, new Set<HasEntityRelations>());
    }
    return this.entityMap.get(a)!;
  }
  
  private notifyActionToBoth(a: HasEntityRelations, b: HasEntityRelations): void {
    setTimeout(() => a.onRelationChange(new RelationChange(b.constructor as Ctor)), 0);
    setTimeout(() => b.onRelationChange(new RelationChange(a.constructor as Ctor)), 0);
  }
  
  private notifyRelationChange(target: HasEntityRelations, change: RelationChange): void {
    setTimeout(() => target.onRelationChange(change), 0);
  }
}
