/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component } from "react";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { RouteComponentPropsParams } from "../types/routerprops";
import AuthWrapper from "../data/auth/authWrapper";
import CLOSE_ICON from "../assets/baseline-close-24px.svg";
import MENU_ICON from "../assets/baseline-menu-24px.svg";
import ViewAccessMethods from "../ViewAccessMethods";
import accessControlled from "../components/access-control/access-controlled";
import HEADER_LOGO from "../assets/ReFaMo_logo.png";

interface State {
  menuOpen?: boolean;
  given_name: string;
}

type Props = RouteComponentProps<RouteComponentPropsParams>;

const AdminContent = accessControlled(React.Fragment, ViewAccessMethods.hasAdminAccess);

class Header extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      menuOpen: false,
      given_name: "",
    };
  }

  private toggleMenu = (): void => {
    this.setState((prevState: State) => ({ menuOpen: !prevState.menuOpen }));
  };

  private getTrailingRoute = (): string => {
    if (this.props.match.params.id) {
      return "/" + this.props.match.params.id;
    }
    return "";
  };

  public componentDidMount(): void {
    this.retrieveAttributeGivenName();
  }

  public async retrieveAttributeGivenName(): Promise<void> {
    try {
      const userAttributeGivenName = await AuthWrapper.retrieveGivenName();
      this.setState({
        given_name: userAttributeGivenName,
      });
    } catch (error) {
      console.error(error);
    }
  }

  private listMenuItems(onClickEnabled?: boolean): JSX.Element {
    let username = "User";

    if (this.state.given_name != null) {
      const trimmedUsername: string = this.state.given_name.trim();

      if (trimmedUsername.length > 0) {
        username = this.state.given_name;
      }
    }

    return (
      <ul className="menu-list">
        <li className="menu-item">
          <NavLink
            to={"/status" + this.getTrailingRoute()}
            onClick={onClickEnabled ? this.toggleMenu : undefined}
            activeClassName="active"
            className="menu-item-link"
          >
            Status
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink
            to={"/history" + this.getTrailingRoute()}
            onClick={onClickEnabled ? this.toggleMenu : undefined}
            activeClassName="active"
            className="menu-item-link"
          >
            History
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink
            to={"/statistics" + this.getTrailingRoute()}
            onClick={onClickEnabled ? this.toggleMenu : undefined}
            activeClassName="active"
            className="menu-item-link"
          >
            Analytics
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink
            to={"/events" + this.getTrailingRoute()}
            onClick={onClickEnabled ? this.toggleMenu : undefined}
            activeClassName="active"
            className="menu-item-link"
          >
            Events
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink
            to={"/user"}
            onClick={onClickEnabled ? this.toggleMenu : undefined}
            activeClassName="active"
            className="menu-item-link"
          >
            {username}
          </NavLink>
        </li>
        <AdminContent showLoader={false} showAccessError={false}>
          <li className="menu-item">
            <NavLink
              to={"/admin"}
              onClick={onClickEnabled ? this.toggleMenu : undefined}
              activeClassName="active"
              className="menu-item-link"
            >
              Admin
            </NavLink>
          </li>
        </AdminContent>
      </ul >
    );
  }

  public render(): JSX.Element {
    return (
      <header className="header-container col-sm-11">
        <div className="mobile-menu-container col-sm-1 col-xsm-2">
          <img
            className="menu-button"
            alt=""
            src={this.state.menuOpen ? CLOSE_ICON : MENU_ICON}
            onClick={this.toggleMenu}
          />
          <div
            className={this.state.menuOpen ? "menu-content" : "menu-content hidden"}
          >
            {this.state.menuOpen && (this.listMenuItems(true))}
          </div>
        </div>
        <div className="logo-container col-md-4 col-sm-5 col-xsm-10">
          <div>
            <a href={"/status" + this.getTrailingRoute()}>
              <img className="header-logo" src={HEADER_LOGO} alt="header-logo" />
              <h1 className="header-title">ReFaMo cloud</h1>
            </a>
          </div>
        </div>
        <div className="menu-container col-md-8 col-sm-7">
          <div className="menu">
            {this.listMenuItems()}
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
