/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component, Fragment } from "react";
import Device, { DeviceObserver } from "../../data/device/Device";
import Loader from "../ui/loader";
import BackendFactory from "../../BackendFactory";
import DraggableDeviceItem from "./components/draggable-device-item";

interface Props {
  searchFilter: string;
}

interface State {
  isLoading: boolean;
  devices: Device[];
}

/**
 * A list of devices. List is populated based on the given search filter
 * which triggers a device search.
 */
export default class DeviceList extends Component<Props, State> implements DeviceObserver {
  public constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      devices: [],
    };
  }

  public async componentDidMount(): Promise<void> {
    await this.searchDevices();
    this.setState({ isLoading: false });
  }

  public async componentDidUpdate(prevProps: Props): Promise<void> {
    if (this.props.searchFilter !== prevProps.searchFilter) {
      this.setState({ isLoading: true });
      await this.searchDevices();
      this.setState({ isLoading: false });
    }
  }

  public componentWillUnmount(): void {
    this.state.devices.forEach(device => device.removeObserver(this));
  }

  public onDeviceStateUpdate(device: Device): void {
    console.log(`onDeviceStateUpdate IoTDeviceList: ${device.getId()}`);
    this.setState(prevState => {
      const devices = prevState.devices.map(item => {
        if (item.getId() === device.getId()) {
          return device;
        } else {
          return item;
        }
      });

      return { devices };
    });
  }

  /**
   * Search devices based on the given search filter.
   */
  private async searchDevices(): Promise<void> {
    const query = this.props.searchFilter.substring(1);
    const devices: Device[] = await BackendFactory.getBackend().searchDevices(query);

    this.state.devices.forEach(device => device.removeObserver(this));

    if (devices.length === 0) {
      this.setState({ devices: [] });
    } else {
      devices.forEach(device => device.addObserver(this));
      this.setState({ devices });
    }
  }

  public render(): JSX.Element {
    if (this.state.isLoading) {
      return <Loader/>;
    }
    return (
      <Fragment>
        {this.state.devices.map(device => {
          return (
            <DraggableDeviceItem
              key={device.getId()}
              device={device}
              editMode={false}
            />
          );
        })}
      </Fragment>
    );
  }
}
