/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { convertTimestampToString, DateTimeFormatTarget, numberToFixed } from "../utils/utils";
import { Maybe } from "../../types/aliases";
import { Data } from "../data/Data";

export type StatusValue = string | number | boolean | JSX.Element;

export interface StatusEntry {
  title: string;
  value: StatusValue;
}

export type StatusDataRow = StatusValue[];

export interface TableColumnLabels {
  [key: string]: string;
}

export interface TimestampRange {
  start: number;
  end: number;
}

export class ClientProperties {
  public static readonly EVENT_AGE_DAYS = 365;
  public static readonly DEVICE_DRAWER_TITLE: string = "Devices";
  public static readonly DEVICES_TAB_TITLE: string = "Devices";
  public static readonly GROUPS_TAB_TITLE: string = "Groups";

  public static readonly EVENT_TABLE_TITLE: string = "Events";
  public static readonly EVENT_ACKNOWLEDGE_BUTTON_TITLE: string = "Acknowledge";

  public static readonly EVENT_TABLE_LABELS: TableColumnLabels = {
    timestamp: "Timestamp",
    description: "Description",
    type: "Type",
    severity: "Severity",
    sensorName: "Sensor",
    sensorValue: "Sensor value",
    eventState: "State",
    metadata: "Metadata",
  };

  public static getOverlayPopupEntries(data: Data): StatusEntry[] {
    return [
      // Show formatted value with unit for each, or when devices report no value, show hyphen and unit:
      { title: "Temperature", value: ClientProperties.formatValue(data.temperature, "\u2103", 2) ?? "-\u2103" },
      { title: "Humidity", value: ClientProperties.formatValue(data.humidity, "%", 2) ?? "-%" },
      { title: "Pressure", value: ClientProperties.formatValue(data.pressure, " hPa", 2) ?? "- hPa" },
      { title: "BatteryLevel", value: ClientProperties.formatValue(data.batteryLevel, "%", 2) ?? "-%" },
      { title: "Speed", value: ClientProperties.formatValue(data.speed, " km/h", 2) ?? "- km/h" },
    ];
  }

  public static getAlarmTooltipFromEntities(entities: string[]): string {
    if (entities.length > 0) {
      const result = entities.join(",") + " alarm";
      return result.charAt(0).toUpperCase() + result.slice(1);
    } else {
      return "";
    }
  }

  public static getDefaultEventTimestampRange(days: number): TimestampRange {
    const delta: number = days * 24 * 60 * 60 * 1000;
    const startTimestamp: number = new Date().getTime() - delta;
    const endTimestamp: number = new Date().getTime();
    return {
      start: startTimestamp,
      end: endTimestamp,
    };
  }

  // TODO: This should be made dynamic based on the data received from backend (IP-471)
  public static getStatusRowEntries(data: Data): StatusEntry[] {
    return [
      { title: "Timestamp", value: convertTimestampToString(data.timestamp, DateTimeFormatTarget.StatusTable) },
      // Show formatted value with unit for each, or when devices report no value, show blank w/o unit:
      { title: "Temperature", value: ClientProperties.formatValue(data.temperature, "\u2103", 2) ?? " " },
      { title: "Humidity", value: ClientProperties.formatValue(data.humidity, "%", 2) ?? " " },
      { title: "Pressure", value: ClientProperties.formatValue(data.pressure, " hPa", 2) ?? " " },
      { title: "Battery level", value: ClientProperties.formatValue(data.batteryLevel, "%", 2) ?? " " },
      { title: "Speed", value: ClientProperties.formatValue(data.speed, " km/h", 2) ?? " " },
    ];
  }

  public static getMetricsHeaderTitles(): string[] {
    return ["Measured data", "Min", "Max", "Average"];
  }

  public static getMetricsKeyLabel(labelId: string): string {
    const labels: TableColumnLabels = {
      temperature: "Temperature",
      humidity: "Humidity",
      pressure: "Pressure",
      altitude: "Altitude",
      speed: "Speed",
      batteryLevel: "Battery Level",
    };

    if (labels[labelId]) {
      return labels[labelId];
    }
    return labelId;
  }

  private static formatValue(value: number | string | boolean | undefined, unit: string, decimals?: number): Maybe<string> {
    if (value === undefined) {
      return;
      // Show "0" and unit instead of blank when devices report "0" value:
    } else if (value === 0) {
      return "0" + unit;
      // Show as many decimals as stated, or if not stated, show full value including all decimals:
    } else if (typeof value === "number") {
      const num = decimals != null ? numberToFixed(value, decimals) : value;
      return `${num}${unit}`;
    } else {
      console.log("no number value reported");
      return "N/A";
    } 
  }
}

export default ClientProperties;
