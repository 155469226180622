/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { Button, TextField } from "@material-ui/core";
import React, { ChangeEvent, Component, Fragment } from "react";
import Loader from "../../ui/loader";
import { Maybe } from "../../../types/aliases";
import AuthWrapper from "../../../data/auth/authWrapper";

export interface Props {
}

interface State {
  isLoadingUserAttributes: boolean;
  loggedInUserName: string;
  beErrorMessageUserAttributes: string;
  successMessageUserAttributes: string;
  given_name: string;
  family_name: string;
}

export default class AttributeForm extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);

    this.state = {
      isLoadingUserAttributes: false,
      loggedInUserName: "",
      beErrorMessageUserAttributes: "",
      successMessageUserAttributes: "",
      given_name: "",
      family_name: "",
    };
  }

  public componentDidMount(): void {
    this.getUserDetails();
  }

  // Display username, first name, and last name at the same time:
  private async getUserDetails(): Promise<void> {
    this.setState({ isLoadingUserAttributes: true });

    Promise.allSettled([
      AuthWrapper.getCurrentAuthenticatedUsername(),
      AuthWrapper.retrieveGivenName(),
      AuthWrapper.retrieveFamilyName(),
    ]).then(results => {
      const loggedInUserName = this.getPromiseResult(results[0]);
      console.log("Username: ", loggedInUserName);
      const given_name = this.getPromiseResult(results[1]);
      const family_name = this.getPromiseResult(results[2]);

      this.setState({
        isLoadingUserAttributes: false,
        loggedInUserName,
        given_name,
        family_name,
        beErrorMessageUserAttributes: "",
        successMessageUserAttributes: "",
      });
    });
  }

  private getPromiseResult(result: PromiseSettledResult<string>): string {
    if (result.status === "rejected") {
      console.error((result as PromiseRejectedResult).reason);
      return "";
    }
    return (result as PromiseFulfilledResult<string>).value;
  }

  private renderLoaderUserAttributes = (): Maybe<JSX.Element> => {
    if (this.state.isLoadingUserAttributes) {
      return <Loader />;
    }
  };

  // This is the UI where an existing user´s user attributes, such as first name, can be changed. 
  private renderInputs(): JSX.Element {
    const isEnabled = this.state.given_name?.length > 0 || this.state.family_name?.length > 0;
    let firstNameInBox: string;

    if (this.state.given_name === undefined) {
      firstNameInBox = "";  // handle undefined, i.e. if there is no given_name set
    } else {
      firstNameInBox = this.state.given_name;
    }
    
    let lastNameInBox: string;

    if (this.state.family_name === undefined) {
      lastNameInBox = "";  // handle undefined, i.e. if there is no family_name set
    } else {
      lastNameInBox = this.state.family_name;
    }

    return (
      <Fragment>
        <div className="user-fields">
          <TextField
            className="text-field"
            label="First name"
            type="text"
            margin="normal"
            variant="outlined"
            value={firstNameInBox}
            onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void =>
              this.setState({ given_name: event.target.value })}
          />
          <TextField    
            className="text-field"
            label="Last name"
            type="text"
            margin="normal"
            variant="outlined"
            value={lastNameInBox}
            onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void =>
              this.setState({ family_name: event.target.value })}
          />
        </div>
        <div className="user-buttons">
          <Button
            disabled={!isEnabled}
            variant="contained"
            color="primary"
            onClick={this.newNameCheckAndSubmit}
            className="button"
          >
            Confirm and Change Name
          </Button>
        </div>
      </Fragment>
    );
  }

  private newNameCheckAndSubmit = (): void => {
    if (this.state.given_name?.length === 0 && this.state.family_name?.length === 0) {
      return;
    }
    else {
      console.log ("Confirm and Change Name pressed. Proceeding.");
      // Clear previous messages from the UI before continuing:
      this.setState({ 
        beErrorMessageUserAttributes: "",
        successMessageUserAttributes: "", 
      });
      this.handleNewUserAttributes();
    }
  };

  // Existing user: After pressing "Confirm and Change Name", save first/last name:
  public async handleNewUserAttributes(): Promise<void> {
    try {
      this.setState({ isLoadingUserAttributes: true });
      await AuthWrapper.updateAttributes(this.state.given_name, this.state.family_name);
      this.setState({
        isLoadingUserAttributes: false,
        given_name: this.state.given_name,
        family_name: this.state.family_name,
        beErrorMessageUserAttributes: "",
        successMessageUserAttributes: "Your name is now successfully changed!",
      });
    } catch (error) {
      console.error(error);
      let beErrorMessageUserAttributes = "";

      switch (error.toString()) {  // cases mapped based on authWrapper error messages:
        case "Error: Attribute value for given_name must not be null":
        case "Error: Attribute value for family_name must not be null":
          beErrorMessageUserAttributes = "InvalidParameterException: no name. Please enter a name and try again.";
          break;
        case "Error: Network error":
          beErrorMessageUserAttributes = "NetworkError. There was an error with the network. Please check your network connection and try again.";
          break;
        default:
          beErrorMessageUserAttributes = "General Backend Error, Unknown/Unusual Error, or Multiple Errors. Unable to perform action, please try again. If this problem persists, contact your admin.";
      }

      this.setState({
        isLoadingUserAttributes: false,
        beErrorMessageUserAttributes,
        successMessageUserAttributes: "",
      });
    } 
  }


  private renderMessageUserAttributes = (): JSX.Element => {
    if (this.state.successMessageUserAttributes) {
      return (
        <div className="user-successtext">
          Success! {this.state.successMessageUserAttributes}
        </div>
      );
    } else if (this.state.beErrorMessageUserAttributes) {      
      return (
        <div className="user-errortext">
          An error occured: {this.state.beErrorMessageUserAttributes}
        </div>
      );
    } else {
      return (
        <div className="user-successtext">
          &nbsp;
        </div>
      );
    }
  };

  public render(): JSX.Element { 
    return (
      <Fragment>
        <section className="user-page-container"> 
        
          <div>
            <span style={{ fontWeight: "bold" }}> Username: </span> {this.state.loggedInUserName}
          </div>

          <h3 className="user-header">
            Enter New First name and Last name
          </h3>
          
          {this.renderInputs()}
          {this.renderLoaderUserAttributes()}
          {this.renderMessageUserAttributes()}

        </section>
      </Fragment >
    );
  }
}
