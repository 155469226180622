/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import AWSBackend from "../backend/AWSBackend";
import DeviceGroup, { DeviceGroupObserver, DeviceGroupParameters, DeviceId } from "./DeviceGroup";
import { Maybe } from "../../types/aliases";
import { Attribute } from "./Attribute";
import Device from "./Device";
import { isDefined } from "../../utils/types";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import { Service } from "../backend/AppSyncClientProvider";
import {
  DeviceGroupsDeleteDocument,
  DeviceGroupsDevicesAddDocument,
  DeviceGroupsDevicesRemoveDocument,
} from "../../generated/gqlDevice";
import AuthWrapper from "../auth/authWrapper";
import { throwGQLError } from "../utils/utils";

const ORGANIZATION_KEY = "organization";
const IOT_MAX_CHILD_GROUPS = 100;

export default class AWSThingGroup extends DeviceGroup implements DeviceGroupObserver {
  private readonly backend: AWSBackend;
  // the actual instances are cached by backend
  private deviceIds?: string[];
  private groupIds?: string[];

  public constructor(backend: AWSBackend, params: DeviceGroupParameters) {
    super(params);
    this.backend = backend;
  }

  public getLabel(): string {
    return AWSThingGroup.getLocalisedName(this.getAttributes(), this.getId());
  }

  public getOrganization(): Maybe<string> {
    return this.getAttributes()
      .find((attribute) => attribute.key === ORGANIZATION_KEY)
      ?.value;
  }

  public async getGroups(): Promise<DeviceGroup[]> {
    if (this.groupIds) {
      // this should only hit cached items, but hitting non-cached is fine, too
      const potentialGroups = await Promise.all(this.groupIds.map(id => this.backend.getDeviceGroup(id)));
      return potentialGroups.filter(isDefined);
    }
      
    const groups = await this.backend.getDeviceGroups({ parent: this });
    groups.forEach((group) => group.addObserver(this));
    this.groupIds = groups.map(group => group.getId());

    return groups;
  }

  public async getDevices(): Promise<Device[]> {
    if (this.deviceIds) {
      // this should only hit cached items, but hitting non-cached is fine, too
      const potentialDevices = await Promise.all(this.deviceIds.map(id => this.backend.getDevice(id)));
      return potentialDevices.filter(isDefined);
    }

    const devices = await this.backend.getDeviceGroupDevices(this);
    this.deviceIds = devices.map(device => device.getId());

    return devices;
  }

  public async addDevice(device: DeviceId | Device): Promise<void> {
    const deviceId = typeof device === "string" ? device : device.getId();
 
    if (this.deviceIds?.includes(deviceId)) {
      console.log(`Attempted to add device '${deviceId}' to group '${this.getId()}' but it was already there!`);
      return;
    }
    
    const organizationId = await this.getOrganizationIdForRequest();
    const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
    const response = await appSyncClient.mutate(
      DeviceGroupsDevicesAddDocument,
      {
        deviceId,
        groupId: this.getId(),
        overrideDynamics: false,
        organizationId,
      },
    );

    if (response.errors) {
      throwGQLError(response, `Failed to add device ${deviceId} to group ${this.getId()}`);
    }

    if (response.data?.deviceGroupsDevicesAdd && this.deviceIds) {
      this.deviceIds.push(deviceId);
      await this.notifyDeviceChange();
    }
  }

  public async removeDevice(device: DeviceId | Device): Promise<void> {
    const deviceId = typeof device === "string" ? device : device.getId();

    if (this.deviceIds && !this.deviceIds.includes(deviceId)) {
      console.log(`Attempted to remove device '${deviceId}' from group '${this.getId()}' but it was not there!`);
      return;
    }

    const organizationId = await this.getOrganizationIdForRequest();
    const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
    const response = await appSyncClient.mutate(
      DeviceGroupsDevicesRemoveDocument,
      {
        deviceId,
        groupId: this.getId(),
        organizationId,
      },
    );

    if (response.errors) {
      throwGQLError(response, `Failed to remove device ${deviceId} from group ${this.getId()}`);
    }

    if (response.data?.deviceGroupsDevicesRemove && this.deviceIds) {
      const index = this.deviceIds.indexOf(deviceId);
      this.deviceIds.splice(index, 1);
      await this.notifyDeviceChange();
    }
  }

  public async delete(): Promise<void> {
    const organizationId = await this.getOrganizationIdForRequest();

    try {
      const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
      await appSyncClient.mutate(
        DeviceGroupsDeleteDocument,
        {
          groupId: this.getId(),
          organizationId: organizationId,
        },
      );
      await this.backend.removeLocal(this);
      await this.notifyDelete();
    } catch (error) {
      console.error("Failed to remove group", error);
      throw error;
    }
  }

  public childGroupCanBeAdded(): boolean {
    return !!this.groupIds && this.groupIds.length < IOT_MAX_CHILD_GROUPS;
  }

  public canBeDeleted(): boolean {
    return this.groupIds?.length === 0 && this.deviceIds?.length === 0;
  }

  /// AWSThingGroup specific public methods

  public async onDelete(group: DeviceGroup): Promise<void> {
    await this.removeGroup(group as AWSThingGroup);
  }

  public async addGroup(group: AWSThingGroup): Promise<void> {
    if (!this.groupIds) {
      console.error("Cannot add subgroup when subgroups have not been initialized (getGroups)");
      return;
    }

    const alreadyExists = this.groupIds.some((groupId) => groupId === group.getId());

    if (!alreadyExists) {
      group.addObserver(this);
      this.groupIds.push(group.getId());
      await this.notifyGroupChange();
    }
  }

  public async removeGroup(removeGroup: AWSThingGroup): Promise<void> {
    if (!this.groupIds) {
      console.error("Cannot remove subgroup when subgroups have not been initialized (getGroups)");
      return;
    }

    const groupIndex = this.groupIds.indexOf(removeGroup.getId());
    
    if (groupIndex > -1) {
      removeGroup.removeObserver(this);
      this.groupIds.splice(groupIndex, 1);
      await this.notifyGroupChange();
    }
  }

  private async getOrganizationIdForRequest(): Promise<string> {
    const organizationId = this.getOrganization();

    if (organizationId) {
      return organizationId;
    }
    const userOrganization = await AWSThingGroup.getCurrentUserOrganization();

    if (!userOrganization) {
      throw new Error("Could not resolve organization!");
    }
    return userOrganization;
  }

  private async notifyGroupChange(): Promise<void> {
    const groups = await this.getGroups();
    this.notifyAction(observer => observer.onGroupsChanged?.(groups, this));
  }

  private async notifyDeviceChange(): Promise<void> {
    const devices = await this.getDevices();
    this.notifyAction(observer => observer.onDevicesChanged?.(devices, this));
  }

  private async notifyDelete(): Promise<void> {
    this.notifyAction(observer => observer.onDelete?.(this));
  }

  /// STATIC METHODS

  public static instanceOf(value: unknown): value is AWSThingGroup {
    return value instanceof AWSThingGroup;
  }

  private static getLocalisedName(groupAttributes: Attribute[], defaultValue: string, language = "label_default"): string {
    return groupAttributes.find((attr: Attribute) => attr.key === language)?.value ?? defaultValue;
  }

  private static async getCurrentUserOrganization(): Promise<Maybe<string>> {
    const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();
    return claims?.homeOrganizationId;
  }
}
