/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/
import { Data } from "./Data";
import { Maybe } from "../../types/aliases";

type MinData<TData extends Data> = Data & Partial<TData>;
export type SampleRange = {start: number; end: number};
export type SyntheticDataGenerator<TData extends Data> = (dataArray: Data[], sampleRange: SampleRange) => TData[];

export interface GeneratorConfig<TData extends Data> {
  generator: SyntheticDataGenerator<TData>;
  sampleRange: SampleRange;
}

export class DataHolder<TData extends Data> {
  private constructor(
      private __data: TData[],
      private readonly generator?: SyntheticDataGenerator<TData>,
      private __sampleRange?: SampleRange,
  ) {}

  public get data(): TData[] {
    return [...this.__data];
  }
  
  public get sampleRange(): Maybe<SampleRange> {
    return this.__sampleRange ? { ...this.__sampleRange } : undefined;
  }

  public addData(data: MinData<TData>[] | TData[]): void {
    const newData = [...this.__data, ...data];

    this.updateSampleRange([...data].sort(DataHolder.compareTimestamp));
    this.__data = DataHolder.getAdjustedData(newData, this.generator, this.__sampleRange);
  }
  
  private updateSampleRange(newData: MinData<TData>[] | TData[]): void {
    if (!this.__sampleRange) return;

    if (newData[0].timestamp < this.__sampleRange.start) {
      this.__sampleRange.start = newData[0].timestamp;
    }

    if (newData[newData.length - 1].timestamp > this.__sampleRange.end) {
      this.__sampleRange.end = newData[newData.length - 1].timestamp;
    }
  }
  
  public static create<TData extends Data>(data: MinData<TData>[], config?: GeneratorConfig<TData>): DataHolder<TData> {
    const adjustedData = DataHolder.getAdjustedData(data, config?.generator, config?.sampleRange);
    return new DataHolder<TData>(adjustedData, config?.generator, config?.sampleRange ? { ...config.sampleRange } : undefined);
  }

  private static getAdjustedData<TData extends Data>(data: MinData<TData>[], generator?: SyntheticDataGenerator<TData>, range?: SampleRange): TData[] {
    if (data.length === 0) return [];
    const sortedData = [...data].sort(DataHolder.compareTimestamp);
    return !generator || !range ? sortedData as TData[] : generator(sortedData, range);
  }

  private static compareTimestamp(data1: Data, data2: Data): number {
    return data1.timestamp - data2.timestamp;
  }
}
