/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { RouteComponentPropsParams } from "../../../types/routerprops";
import { Maybe, Nullable } from "../../../types/aliases";
import Device, { DeviceObserver } from "../../../data/device/Device";
import React, { Component } from "react";
import { ReferenceHWState } from "../../../client/devices/ReferenceHW/ReferenceHWState";
import { ReferenceHWStateProperties } from "../../../client/devices/ReferenceHW/ReferenceHWStateProperties";
import moment from "moment";
import { DateTimeFormatTarget, getDateTimeFormat, getDisplayName } from "../../../data/utils/utils";
import ReactTooltip from "react-tooltip";
import DeviceSettingsButton from "../../device-settings/device-settings-button";
import LocationIcon from "../../ui/location-icon";
import ResourceSelector from "../../../state/ResourceSelector";
import ClientProperties from "../../../data/clientSpecific/ClientProperties";
import { getPowerSupplyStateIcon, getSignalStrengthIcon } from "../helpers/icon-factory";
import DeviceGroup from "../../../data/device/DeviceGroup";
import DeviceState from "../../../data/device/DeviceState";
import { DeviceStateProperties } from "../../../data/device/DeviceStateProperties";

export const TOOLTIP_DELAY_MS = 400;

export interface Props extends RouteComponentProps<RouteComponentPropsParams> {
  device: Device;
  parentGroup?: DeviceGroup;
  editMode: boolean;
}

interface State {
  deviceState: Nullable<DeviceState<DeviceStateProperties>>;
}

class DraggableDeviceItem extends Component<Props, State> implements DeviceObserver {

  public constructor(props: Props) {
    super(props);
    this.state = {
      deviceState: props.device.getState(),
    };
  }

  public componentDidMount(): void {
    this.props.device.addObserver(this);
  }

  public componentWillUnmount(): void {
    this.props.device.removeObserver(this);
  }

  private getSignalStatusElement(): Maybe<JSX.Element> {
    const icon = getSignalStrengthIcon(this.state.deviceState);

    if (icon) {
      return (
        <img
          className="status-icon"
          data-tip={this.getTooltip()}
          src={icon}
          alt="Signal"
        />
      );
    }
  }

  private getPowerSupplyStatusElement(): Maybe<JSX.Element> {
    const icon = getPowerSupplyStateIcon(this.state.deviceState);

    if (icon) {
      return (
        <img
          className="status-icon"
          data-tip={this.getTooltip()}
          src={icon}
          alt="Battery"
        />
      );
    }
  }

  private getDeviceIcon(): Maybe<JSX.Element> {
    if (this.props.device) {
      return <img src={this.props.device.getIcon()} alt="device logo" />;
    }
  }

  public onDeviceStateUpdate(device: Device): void {
    this.setState({ deviceState: device.getState() });
  }

  private getLinkRoute = (): string => {
    let baseUrl = this.props.location.pathname;

    if (this.props.match.params.id) {
      baseUrl = this.props.location.pathname.substring(0, this.props.location.pathname.lastIndexOf("/"));
    }

    if (!this.props.device) {
      return baseUrl;
    }

    if (baseUrl.lastIndexOf("/") === baseUrl.indexOf("/")) {
      return baseUrl + "/" + this.props.device.getId();
    }
    return baseUrl + this.props.device.getId();
  };

  private getTooltip = (): string => {
    let timestampAsString = "N/A";

    if (this.state.deviceState && this.state.deviceState.getStateUpdatedTimestampMillis()) {
      const timestamp = this.state.deviceState.getStateUpdatedTimestampMillis();
      timestampAsString = moment(timestamp).format(getDateTimeFormat(DateTimeFormatTarget.ShadowUpdate));
    }
    return "Last update: " + timestampAsString;
  };

  private handleDragStart = (event: React.DragEvent): void => {
    ReactTooltip.hide();

    if (this.props.parentGroup) {
      event.dataTransfer.setData("group", this.props.parentGroup.getId());
    }
    event.dataTransfer.setData("device", this.props.device.getId());
  };

  private renderStatusContainer(): Maybe<JSX.Element> {
    if (!this.props.editMode) {
      const deviceState = this.state.deviceState as Nullable<ReferenceHWState<ReferenceHWStateProperties>>;

      return (
        <div className="iot-status-container col-sm-4 col-xsm-4">
          <DeviceSettingsButton
            device={this.props.device}
            isIcon={true}
          />
          {this.getSignalStatusElement()}
          <LocationIcon
            locationStatus={deviceState?.gpsFix ?? undefined}
            updateMilliseconds={deviceState?.getStateUpdatedTimestampMillis()}
          />
          {this.getPowerSupplyStatusElement()}
        </div>
      );
    }
  }

  private handleClick = async (): Promise<void> => {
    await ResourceSelector.getInstance().setCurrentDevice(this.props.device?.getId());
  };

  public render(): JSX.Element {

    // TODO:  remove ClientProperties.getAlarmTooltipFromEntities([]) or add alarms back
    //        currently it provides some custom tool-tipping even if alerts are not enabled
    const nodeIsSelected = DraggableDeviceItem.isDeviceCurrentlySelected(this.props.device?.getId());
    const divClass = nodeIsSelected ? "tree-node-selected" : "tree-node-not-selected";
    return (
      <div
        className={divClass}
        draggable={this.props.editMode}
        onDragStart={(event: React.DragEvent): void => this.handleDragStart(event)}
      >
        <ReactTooltip delayShow={TOOLTIP_DELAY_MS}/>
        <NavLink
          to={this.getLinkRoute()}
          className="iot-item-link col-sm-8 col-xsm-8"
          isActive={(): boolean => nodeIsSelected}
          onClick={this.handleClick}
        >
          <div
            className="iot-icon col-sm-1 col-xsm-1"
            data-tip={ClientProperties.getAlarmTooltipFromEntities([])}
          >
            {this.getDeviceIcon()}
          </div>
          <div className="iot-name col-sm-10 col-xsm-10" data-tip={this.getTooltip()}>
            {getDisplayName(this.props.device)}
          </div>
        </NavLink >
        {this.renderStatusContainer()}
      </div >
    );
  }

  private static isDeviceCurrentlySelected(deviceId?: string): boolean {
    return (
      !!deviceId
      && Device.isValid(ResourceSelector.getInstance().getCurrentDevice())
      && ResourceSelector.getInstance().getCurrentDevice()?.getId() === deviceId
    );
  }
}

export default withRouter(DraggableDeviceItem);
