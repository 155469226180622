/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import DeleteIcon from "@material-ui/icons/Delete";
import { Divider, IconButton, Select, Table, TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import React, { ReactNode } from "react";
import { MeasurementPoint, MeasurementUnit } from "../../../../client/devices/HyperHW/HyperHWStateProperties";
import { $enum } from "ts-enum-util";

interface Props extends MeasurementPoint {
  onRemove: (rowKey: string) => void;
  onIdChanged: (rowKey: string, newId: string) => void;
  onNameChanged: (rowKey: string, newId: string) => void;
  onConversionMultiplierChanged: (rowKey: string, newMultiplier: number) => void;
  onUnitChanged: (rowKey: string, newUnit: MeasurementUnit) => void;
  onTareChanged: (rowKey: string, newTare: number) => void;
  rowKey: string;
  isDuplicateId: boolean;
}

export default class SettingsPageMeasurementPoint extends React.Component<Props, MeasurementPoint> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      inputId: props.inputId,
      name: props.name,
      conversionMultiplier: props.conversionMultiplier,
      unit: props.unit ? props.unit : MeasurementUnit.Celsius,
      tare: props.tare,
    };
  }

  private handleRemove = (): void => {
    this.props.onRemove(this.props.rowKey);
  };

  private handleIdChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newId = event.currentTarget.value;
    this.props.onIdChanged(this.props.rowKey, newId);
    this.setState({ inputId: newId });
  };

  private handleNameChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newName = event.currentTarget.value;
    this.props.onNameChanged(this.props.rowKey, newName);
    this.setState({ name: newName });
  };

  private handleConversionMultiplierChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newMultiplier = Number(event.currentTarget.value);
    this.props.onConversionMultiplierChanged(this.props.rowKey, newMultiplier);
    this.setState({ conversionMultiplier: newMultiplier });
  };

  private handleTareChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newTare = Number(event.currentTarget.value);
    this.props.onTareChanged(this.props.rowKey, newTare);
    this.setState({ tare: newTare });
  };

  private handleUnitChanged = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const newUnit = event.currentTarget.value as MeasurementUnit;
    this.props.onUnitChanged(this.props.rowKey, newUnit);
    this.setState({ unit: newUnit });
  };

  public render(): ReactNode {
    return (
      <>
        <Table className="setting-item">
          <TableBody>
            <TableRow>
              <TableCell>Input Id:</TableCell>
              <TableCell>
                <TextField
                  value={this.state.inputId}
                  error={this.props.isDuplicateId || this.state.inputId === ""}
                  onChange={this.handleIdChanged} />
              </TableCell>
              <TableCell>Input name:</TableCell>
              <TableCell>
                <TextField
                  value={this.state.name}
                  error={this.state.name === ""}
                  onChange={this.handleNameChanged} />
              </TableCell>
              <TableCell rowSpan={3}><IconButton onClick={this.handleRemove}><DeleteIcon /></IconButton></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Conversion multiplier:</TableCell>
              <TableCell>
                <TextField
                  type='number'
                  inputProps={{
                    step: "0.01",
                  }}
                  value={this.state.conversionMultiplier}
                  error={this.state.conversionMultiplier <= 0 || this.state.conversionMultiplier === undefined}
                  onChange={this.handleConversionMultiplierChanged} />
              </TableCell>
              <TableCell>Unit:</TableCell>
              <TableCell>
                <Select
                  value={this.state.unit}               
                  onChange={this.handleUnitChanged} >
                  {$enum(MeasurementUnit).map(key => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tare:</TableCell>
              <TableCell>
                <TextField
                  type='number'
                  inputProps={{
                    step: "0.01",
                  }}
                  value={this.state.tare}
                  error={this.state.tare === undefined}
                  onChange={this.handleTareChanged} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Divider />
      </>
    );
  }
}
