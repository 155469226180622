/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import MomentUtils from "@date-io/moment";
import Button from "@material-ui/core/Button";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import moment, { Moment } from "moment";
import React, { Component } from "react";
import { Nullable } from "../../types/aliases";

interface Props {
  startTimestamp: Nullable<number>;
  endTimestamp: Nullable<number>;
  disabled: boolean;
  onTimeRangeSelect: (startTimestamp: number, endTimestamp: number) => void;
}

interface State {
  startTimestamp: Nullable<number>;
  endTimestamp: Nullable<number>;
}

export default class TimeRangePicker extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      startTimestamp: this.props.startTimestamp,
      endTimestamp: this.props.endTimestamp,
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.startTimestamp !== prevProps.startTimestamp ||
            this.props.endTimestamp !== prevProps.endTimestamp) {
      this.setState({
        startTimestamp: this.props.startTimestamp,
        endTimestamp: this.props.endTimestamp,
      });
    }
  }

  private setStartDateTime(date: Nullable<Moment>): void {
    if (date) {
      const timestamp = date.unix() * 1000;
      this.setState({ startTimestamp: timestamp });
    }
  }

  private setEndDateTime(date: Nullable<Moment>): void {
    if (date) {
      const timestamp = date.unix() * 1000;
      this.setState({ endTimestamp: timestamp });
    }
  }

  private search = (): void => {

    if (this.state.startTimestamp && this.state.endTimestamp && this.state.endTimestamp >= this.state.startTimestamp) {
      this.props.onTimeRangeSelect(this.state.startTimestamp, this.state.endTimestamp);
    }
  };

  public render(): JSX.Element {
    // REFACTOR: Time and date formatting should be harmonized throughout the app
    // and encapsulated to a single class.
    const startDatum = moment(this.state.startTimestamp).format(("YYYY-MM-DDTHH:mm"));
    const endDatum = moment(this.state.endTimestamp).format(("YYYY-MM-DDTHH:mm"));

    return (
      <form className="time-range-picker-container">
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DateTimePicker
            autoOk={true}
            ampm={false}
            disableFuture={true}
            value={startDatum}
            onChange={(date: Nullable<Moment>): void =>
              this.setStartDateTime(date)}
            disabled={this.props.disabled}
            label="From"
            className="time-range-picker-input"
            format="DD/MM/YYYY HH:mm"
          />
          <DateTimePicker
            autoOk={true}
            ampm={false}
            disableFuture={true}
            value={endDatum}
            onChange={(date: Nullable<Moment>): void =>
              this.setEndDateTime(date)}
            disabled={this.props.disabled}
            label="To"
            className="time-range-picker-input"
            format="DD/MM/YYYY HH:mm"
          />
        </MuiPickersUtilsProvider>
        <Button
          variant="contained"
          color="primary"
          onClick={this.search}
          disabled={this.props.disabled}
          className="time-range-picker-button"
        >
          Search
        </Button>
      </form>
    );
  }
}
