/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { ChangeEvent, Component, Fragment } from "react";
import Loader from "../ui/loader";
import { Maybe } from "../../types/aliases";
import { Button, TextField, withStyles, IconButton, InputAdornment } from "@material-ui/core";
import VisibilityOnIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

export interface Props {
  loader: boolean;
  username: string;
  password: string;
  userInput: (inputId: string, value: string) => void;
  logIn: () => void;
  passwordError: boolean;
  usernameError: boolean;
  forgotPassword: () => void;
  beErrorMessage: string;
  directAccessToForgotPasswordView: () => void;
}

interface State {
  showPassword: boolean;
}

const ValidationTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
})(TextField);

export default class LoginForm extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  private renderLoader = (): Maybe<JSX.Element> => {
    if (this.props.loader) {
      return <Loader />;
    }
  };

  // This is the main Log In UI. Username/email and Password are asked to log in. 
  // It is also possible to ask for a verification code to reset a forgotten password.
  private renderInputs(): JSX.Element {
    const isEnabled = this.props.username.length > 0 && this.props.password.length > 0;
    return (
      <Fragment>
        <div className="login-fields">
          <ValidationTextField
            className="text-field"
            label="Email"
            type="email"
            name="email"
            autoComplete="email"
            margin="normal"
            variant="outlined"
            autoFocus={true}
            required={true}
            error={this.props.usernameError}
            defaultValue={this.props.username}
            onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>):
            void => this.props.userInput("username", event.currentTarget.value)}
            onKeyPress={this.handleKeyPress}
            fullWidth={true}
          />
          <ValidationTextField
            className="text-field"
            label="Password"
            type={this.state.showPassword ? "text" : "password"}
            autoComplete="current-password"
            margin="normal"
            variant="outlined"
            required={true}
            error={this.props.passwordError}
            defaultValue={this.props.password}
            onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>):
            void => this.props.userInput("password", event.currentTarget.value)}
            onKeyPress={this.handleKeyPress}
            fullWidth={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    className="visibility-icon"
                    edge="end"
                    onClick={this.togglePasswordVisibility}
                  >
                    {this.state.showPassword ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="login-buttons">
          <Button
            disabled={!isEnabled}
            variant="contained"
            color="primary"
            onClick={(): void => this.props.logIn()}
            className="button"
          >
                        Log In
          </Button>
        </div>
        {/* Input for login-content: */}
        <div className="login-text" onClick={(): void => this.props.forgotPassword()}>
              Forgot Password?
        </div>      
        <div className="login-textSmall" onClick={(): void => this.props.directAccessToForgotPasswordView()}>
              I already have a Verification Code (enter code here).
        </div>     
      </Fragment>
    );
  }

  private togglePasswordVisibility = (): void => {
    this.setState((prevState: State) => ({ showPassword: !prevState.showPassword }));
  };  

  private handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === "Enter") {
      this.props.logIn();
    }
  };

  private renderBeErrorMessage = (): Maybe<JSX.Element> => {
    if (this.props.beErrorMessage) {
      return (
        <div className="login-errortext">
          {this.props.beErrorMessage} 
        </div>
      );
    } 
  };

  public render(): JSX.Element {
    return (
      <Fragment>
        <div className="login-header" />
        {this.renderLoader()}
        {this.renderInputs()}
        {this.renderBeErrorMessage()}
      </Fragment>
    );
  }
}
