/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import Button from "@material-ui/core/Button";
import MUIDataTable, {
  Display,
  MUIDataTableColumn,
  MUIDataTableColumnDef,
  MUIDataTableMeta,
  MUIDataTableOptions,
  SelectableRows,
} from "mui-datatables";
import React, { Component, Fragment } from "react";
import { ClientProperties } from "../../data/clientSpecific/ClientProperties";
import Event, { EventState } from "../../data/clientSpecific/Event";
import Device from "../../data/device/Device";
import EventSet, { EventObserver } from "../../data/events/EventSet";
import EventsRepository from "../../data/events/EventsRepository";
import { convertStringToTimestamp, convertTimestampToString, DateTimeFormatTarget } from "../../data/utils/utils";
import Loader from "../ui/loader";
import { Maybe, Nullable } from "../../types/aliases";
import ResourceSelector, { ResourceSelectorObserver } from "../../state/ResourceSelector";

interface Props {}

interface State {
  isLoading: boolean;
  singleDeviceEventSet: Nullable<EventSet>;
  singleDeviceEvents: Nullable<Event[]>;
  settingEventStateId?: string;
  selectedDevice?: Device;
}

type EventTableCell = string | number | JSX.Element;
type EventTableRow = EventTableCell[];

class EventView extends Component<Props, State> implements EventObserver, ResourceSelectorObserver {
  
  private readonly tableColumns: MUIDataTableColumnDef[] = [
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: "false" as Display,
        viewColumns: false,
      },
    },
    {
      name: "timestamp",
      label: ClientProperties.EVENT_TABLE_LABELS.timestamp,
      options: {
        filter: true,
        sort: true,
        sortDirection: "desc",
      },
    },
    {
      name: "description",
      label: ClientProperties.EVENT_TABLE_LABELS.description,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "type",
      label: ClientProperties.EVENT_TABLE_LABELS.type,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "severity",
      label: "Severity",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "sensorName",
      label: ClientProperties.EVENT_TABLE_LABELS.sensorName,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "sensorValue",
      label: ClientProperties.EVENT_TABLE_LABELS.sensorValue,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "metadata",
      label: ClientProperties.EVENT_TABLE_LABELS.metadata,
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "eventState",
      label: ClientProperties.EVENT_TABLE_LABELS.eventState,
      options: {
        filter: true,
        sort: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        customBodyRender: (_value: any, tableMeta: MUIDataTableMeta, _updateValue: any): Maybe<JSX.Element> =>
          this.renderAcknowledgementCell(tableMeta),
      },
    },
  ];

  private static compareTimestamps(timeStringA: string, timeStringB: string): number {
    const timestampA = convertStringToTimestamp(timeStringA, DateTimeFormatTarget.StatusTable);
    const timestampB = convertStringToTimestamp(timeStringB, DateTimeFormatTarget.StatusTable);
    return timestampA - timestampB;
  }

  private readonly tableOptions: MUIDataTableOptions = {
    filterType: "checkbox",
    // TODO: Fix any type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customToolbarSelect: (_selectedRows: any, _displayData: Array<{ data: any[]; dataIndex: number }>, _setSelectedRows: any): React.ReactNode => {
      // TODO: This is not supported yet.
      return null;
    },
    onRowsDelete: (_rowsDeleted: unknown): void => {
      // TODO: This is not supported yet.
    },
    selectableRows: "none" as SelectableRows,
    selectableRowsOnClick: true,
    // TODO: Fix any type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customSort: (data: any[], colIndex: number, order: string): any => {
      // TODO: Fix any type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return data.sort((a: any, b: any) => {
        // TODO: Fix parameter assign
        // eslint-disable-next-line no-param-reassign
        a = a.data[colIndex] || "";
        // eslint-disable-next-line no-param-reassign
        b = b.data[colIndex] || "";

        const colObject = this.tableColumns[colIndex];
        let col: MUIDataTableColumn;

        if (typeof colObject === "object") {
          col = colObject;
        } else {
          return 0;
        }

        if (order === "asc") {
          if (col.name === "timestamp") {
            return EventView.compareTimestamps(a as string, b as string);
          } else {
            return a.toString().localeCompare(b, undefined, { numeric: true });
          }
        } else if (order === "desc") {
          if (col.name === "timestamp") {
            return EventView.compareTimestamps(b as string, a as string);
          } else {
            return b.toString().localeCompare(a, undefined, { numeric: true });
          }
        }
        return 0;
      });
    },
  };

  public constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      singleDeviceEventSet: null,
      singleDeviceEvents: null,
    };
  }

  public async componentDidMount(): Promise<void> {
    ResourceSelector.getInstance().addObserver(this);
  }

  public async componentDidUpdate(_prevProps: Props, prevState: State): Promise<void> {
    if (Device.isValid(this.state.selectedDevice)) {
      if (prevState.selectedDevice == null || prevState.selectedDevice.getId() !== this.state.selectedDevice!.getId()) {
        await this.fetchDeviceSpecificEvents(this.state.selectedDevice!);
      }
    }
  }

  public componentWillUnmount(): void {
    if (this.state.singleDeviceEventSet) {
      this.state.singleDeviceEventSet.removeObserver(this);
    }
    ResourceSelector.getInstance().removeObserver(this);
  }

  public async onSelectedDeviceChanged(device?: Device): Promise<void> {
    if (this.state.singleDeviceEventSet) {
      this.state.singleDeviceEventSet.removeObserver(this);
    }
    this.setState({ selectedDevice: device });

    if (device) {
      await this.fetchDeviceSpecificEvents(device);
    }
  }

  public onEventSet(eventSet: EventSet): void {
    console.log("onEventSet " + eventSet.getEvents().length);

    if (this.state.selectedDevice?.getId() === eventSet.getId()) {
      this.setState({ singleDeviceEventSet: eventSet, singleDeviceEvents: eventSet.getEvents(), settingEventStateId: undefined });
      this.forceUpdate();
    }
  }

  private async fetchDeviceSpecificEvents(device: Device): Promise<void> {
    this.setState({ isLoading: true });
    const events = await device.getEvents();

    if (events != null) {
      events.addObserver(this);
      this.setState({ isLoading: false, singleDeviceEvents: events.getEvents(), singleDeviceEventSet: events });
    } else {
      this.setState({ isLoading: false, singleDeviceEvents: null, singleDeviceEventSet: null });
    }
  }

  private getTableData(): EventTableRow[] {
    const rows: EventTableRow[] = [];

    if (this.state.singleDeviceEvents) {
      this.state.singleDeviceEvents.forEach((event: Event) => {
        if (event) {
          if (event.eventState === EventState.Active) {
            const row: EventTableRow = [];
            row.push(event.timestamp);
            // TODO: Change event updateTimestamp to be number
            row.push(convertTimestampToString(Number(event.updatedTimestamp), DateTimeFormatTarget.EventsTable));
            row.push(event.eventId ? EventsRepository.getInstance().getEventDescription(event.eventId) : "");
            row.push(event.type ?? "");
            row.push(event.severity ?? "");
            row.push(event.sensorName ?? "");
            row.push(event.sensorValue ?? "");
            row.push(event.metadata ?? "");
            rows.push(row);
          }
        }
      });
    }
    return rows;
  }

  private getEventsTable(): JSX.Element {
    if (this.state.isLoading) {
      return <Loader />;
    }
    return (
      <MUIDataTable
        title={ClientProperties.EVENT_TABLE_TITLE}
        data={this.getTableData()}
        columns={this.tableColumns}
        options={this.tableOptions}
      />
    );
  }

  private renderAcknowledgementCell(tableMeta: MUIDataTableMeta): Maybe<JSX.Element> {
    if (this.state.singleDeviceEvents == null || tableMeta == null) {
      return;
    }
    const timestamp: string = tableMeta.rowData[0];
    const events: Event[] = this.state.singleDeviceEvents.filter((event: Event) => {
      if (event) {
        return event.timestamp === timestamp;
      }
      return false;
    });

    if (events.length === 1) {
      const event: Event = events[0];

      if (event.eventState === EventState.Active) {
        return (
          <Button
            color="primary"
            variant="contained"
            onClick={
              (): void => {
                this.setState({ settingEventStateId: timestamp });

                if (this.state.singleDeviceEventSet) {
                  this.state.singleDeviceEventSet.deactivateEvent(event);
                }
              }
            }
          >
            {ClientProperties.EVENT_ACKNOWLEDGE_BUTTON_TITLE}
          </Button>
        );
      } else {
        if (this.state.settingEventStateId && this.state.settingEventStateId === timestamp) {
          return <Loader/>;
        }
      }
    }
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        {this.getEventsTable()}
      </Fragment>
    );
  }
}

export default EventView;
