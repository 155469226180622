/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import React, { Component } from "react";
import HEADER_LOGO from "../assets/SADEinnovations.png";

export default class LoginHeader extends Component {

  public render(): JSX.Element {
    return (
      <header className="header-container col-md-12">
        <div className="logo-container login col-xlg-4 col-lg-6 col-sm-8 col-xsm-8">
          <img className="header-logo-login" src={HEADER_LOGO} alt="" />
        </div>
      </header>
    );
  }
}
