/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { StatusDataRow, StatusValue } from "../../data/clientSpecific/ClientProperties";
import { convertStringToTimestamp, DateTimeFormatTarget } from "../../data/utils/utils";
import { Maybe, Nullable } from "../../types/aliases";

export type DataRow = Array<string | number | boolean | JSX.Element>;

interface Props {
  title?: string;
  header: string[];
  data: DataRow[];
  rowsPerPageOptions?: number[];
  rowsPerPageDefault?: number;
  stickyHeader?: boolean;
  showEmptyRows?: boolean;
  decimals?: number;
  onTableRowSelect?: (index: number, key?: string) => void;
  onVisibleItemsChanged?: (visibleItems: string[]) => void;
}

interface State {
  rowsPerPage: number;
  currentPage: number;
  orderByColumnIndex: number;
  order: "asc" | "desc";
  selectedRowIndex?: number;
}

const DEFAULT_ROWS_PER_PAGE_OPTIONS: number[] = [5, 10, 15, 20];

export default class DataTable extends Component<Props, State> {

  public constructor(props: Props) {
    super(props);
    this.state = {
      rowsPerPage: this.props.rowsPerPageDefault || DEFAULT_ROWS_PER_PAGE_OPTIONS[0],
      currentPage: 0,
      orderByColumnIndex: 0,
      order: "asc",
    };
  }

  public async componentDidUpdate(prevProps: Props, prevState: State): Promise<void> {
    let updateNeeded = false;

    if (prevState.rowsPerPage !== this.state.rowsPerPage
            || prevState.currentPage !== this.state.currentPage
            || prevState.orderByColumnIndex !== this.state.orderByColumnIndex
            || prevState.order !== this.state.order
            || prevProps.data.length !== this.props.data.length) {
      updateNeeded = true;
    } else if (prevProps.data !== this.props.data) {
      for (const item in prevProps.data) {
        if (!this.props.data.find((row: StatusDataRow) => row[0] !== item[0])) {
          updateNeeded = true;
          break;
        }
      }
    }

    if (updateNeeded) {
      this.changedVisibleItems();
    }
  }

  private getTableHeaderCells(): Maybe<JSX.Element[]> {
    if (this.props.header && this.props.header.length > 0) {
      return this.props.header.map((cell: string, index: number) => {
        return (
          <TableCell
            key={cell}
            sortDirection={this.state.orderByColumnIndex === index ? this.state.order : false}
            align={index !== 0 ? "right" : "left"}
            className={"table-wrapper-table-header-cell" + this.getSticky()}
          >
            <TableSortLabel
              active={this.state.orderByColumnIndex === index}
              direction={this.state.order}
              onClick={(_event: React.MouseEvent<unknown>): void => this.onSortRequest(index)}
            >
              {cell}
            </TableSortLabel>
          </TableCell>
        );
      });
    }
  }

  private static getRowKey(value: StatusValue, index: number): string {
    if (typeof value !== "object") {
      return value.toString() + "_row_" + index.toString();
    }
    return index.toString();
  }

  private isSelected(index: number): boolean {
    return index !== this.state.selectedRowIndex;
  }

  private getVisibleRows(): StatusDataRow[] {
    const { currentPage, rowsPerPage } = this.state;
    return this.sortTable(this.props.data).slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
  }

  private getTableBodyRows(): JSX.Element[] {
    if (this.props.data && this.props.data.length > 0) {
      const data = this.getVisibleRows();
      return data.map((row: StatusDataRow, index: number) => {
        return (
          <TableRow
            hover={true}
            onClick={(): void => this.setSelectedRowIndex(index, row[0].toString())}
            key={DataTable.getRowKey(row[0], index)}
            selected={this.isSelected(index)}
          >
            {this.getTableRowCells(row)}
          </TableRow>
        );
      });
    } else {
      return [(
        <TableRow
          key={"emptyline"}
        >
          <TableCell
            colSpan={6}
            align={"center"}
          >
            No data!
          </TableCell>
        </TableRow>
      )];
    }
  }

  private changedVisibleItems(): void {
    if (this.props.onVisibleItemsChanged) {
      const newVisibleItems: string[] = this.getVisibleRows().map((row: StatusDataRow, index: number) => {
        if (typeof row[0] === "string" || typeof row[0] === "number") {
          return row[0].toString();
        }
        return index.toString();
      });
      this.props.onVisibleItemsChanged(newVisibleItems);
    }
  }

  private setSelectedRowIndex = (index: number, key: string): void => {
    if (this.props.onTableRowSelect) {
      this.props.onTableRowSelect(index, key);
      this.setState({ selectedRowIndex: index });
    }
  };

  private static getCellKey(value: StatusValue, index: number): string {
    if (value == null || typeof value === "object") {
      return index.toString();
    }
    return value.toString() + "_cell_" + index.toString();
  }

  private getTableRowCells(row: StatusDataRow): Maybe<JSX.Element[]> {
    if (row.length > 0) {
      return row.map((cell: StatusValue, index: number) => {
        return (
          <TableCell
            key={DataTable.getCellKey(cell, index)}
            align={index !== 0 ? "right" : "left"}
          >
            {this.formatCellValue(cell)}
          </TableCell>
        );
      });
    }
  }

  private formatCellValue(statusValue: StatusValue): StatusValue {
    if (this.props.decimals != null && typeof statusValue === "number") {
      const roundedValue = statusValue.toFixed(this.props.decimals);
      return Number(roundedValue);
    }
    return statusValue;
  }

  private static compareTimestamps(timeStringA: string, timeStringB: string): number {
    const timestampA = convertStringToTimestamp(timeStringA, DateTimeFormatTarget.StatusTable);
    const timestampB = convertStringToTimestamp(timeStringB, DateTimeFormatTarget.StatusTable);
    return timestampA - timestampB;
  }

  private desc(a: StatusDataRow, b: StatusDataRow, orderBy: number): number {
    if (orderBy && this.props.header[orderBy] === "Timestamp") {
      // This is a special column, need to compare local time strings as epoch timestamps
      return DataTable.compareTimestamps(a[orderBy] as string, b[orderBy] as string);
    } else {
      // localeCompare can detect numbers in strings and compare them accordingly
      const aVal = String(a[orderBy]) || "";
      const bVal = String(b[orderBy]) || "";
      return (bVal).localeCompare(aVal, undefined, { numeric: true });
    }
  }

  private sortTable(tableData: StatusDataRow[]): StatusDataRow[] {
    return tableData.sort((a: StatusDataRow, b: StatusDataRow) => {
      const cmp = this.getSorting(this.state.order, this.state.orderByColumnIndex);
      const order = cmp(a, b);

      if (order !== 0) { return order; }
      return Number(a[this.state.orderByColumnIndex]) - Number(b[this.state.orderByColumnIndex]);
    });
  }

  private getSorting(order: "asc" | "desc", orderBy: number):
  (a: StatusDataRow, b: StatusDataRow) => number {
    return order === "desc" ?
      (a: StatusDataRow, b: StatusDataRow): number =>
        this.desc(a, b, orderBy) :
      (a: StatusDataRow, b: StatusDataRow): number =>
        -this.desc(a, b, orderBy);
  }

  private onSortRequest = (index: number): void => {
    this.setState((prevState: State) => ({
      order: (prevState.order === "asc" ? "desc" : "asc"),
      orderByColumnIndex: index,
    }));
  };

  private onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      rowsPerPage: Number(event.target.value),
      currentPage: 0,
    });
  };

  private onChangePage = (_event: Nullable<React.MouseEvent<HTMLButtonElement>>, page: number): void => {
    this.setState({ currentPage: page });
  };

  private getEmptyRows = (): Maybe<JSX.Element> => {
    if (this.props.showEmptyRows) {
      const { rowsPerPage, currentPage } = this.state;
      const { data } = this.props;
      const emptyRowsCount: number = rowsPerPage -
                Math.min(rowsPerPage, data.length - currentPage * rowsPerPage);

      if (emptyRowsCount > 0) {
        return (
          <TableRow style={{ height: 53 * emptyRowsCount }}>
            <TableCell colSpan={6} />
          </TableRow>
        );
      }
    }
  };

  private getTitle = (): Maybe<JSX.Element> => {
    if (this.props.title) {
      return (
        <div
          className={"table-wrapper-title" + this.getSticky()}
        >
          {this.props.title}
        </div >
      );
    }
  };

  private getSticky = (): string => {
    if (this.props.stickyHeader) {
      return " sticky";
    }
    return "";
  };

  public render(): JSX.Element {
    return (
      <Fragment>
        {this.getTitle()}
        <Table
          stickyHeader={this.props.stickyHeader}
        >
          <TableHead>
            <TableRow>
              {this.getTableHeaderCells()}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.getTableBodyRows()}
            {this.getEmptyRows()}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={this.props.rowsPerPageOptions || DEFAULT_ROWS_PER_PAGE_OPTIONS}
          rowsPerPage={this.state.rowsPerPage}
          count={this.props.data.length}
          component="div"
          page={this.state.currentPage}
          onPageChange={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          className="table-wrapper-pagination"
        />
      </Fragment>
    );
  }
}
