/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import AuthWrapper from "../auth/authWrapper";
import { Service } from "../backend/AppSyncClientProvider";
import AWSOrganization from "./AWSOrganization";
import AWSUser from "./AWSUser";
import {
  OrganizationsCreateDocument,
  OrganizationsGetDocument,
  PolicyGroupsGetDocument,
  UsersCreateDocument,
  UsersGetDocument,
} from "../../generated/gqlUsers";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import OrganizationBackend from "./OrganizationBackend";
import AWSPolicyGroup from "./AWSPolicyGroup";
import { Maybe } from "../../types/aliases";
import { EntityRelationCache, HasEntityRelations } from "../utils/EntityRelationCache";
import { throwGQLError } from "../utils/utils";
import AuthListener, { AuthEvent } from "../auth/authListener";
import AsyncCache from "../utils/AsynCache";

export interface CreateOrganizationParameters {
  name: string;
}

export interface CreateUserParameters {
  username: string;
  resendInvitation?: boolean;
}

export interface CreatePolicyGroupParameters {
  // TODO
}

// TODO:  method for cache pruning - or an actual cache
//        also, cache could be extracted with the pruning code
//        also, would graphql cache be enough here?
//        the cache should also be invalidated when the user logs out - is there a way to listen for that?
export class AWSOrganizationBackend implements OrganizationBackend {
  private readonly cache = new AsyncCache();

  // this is public so AWSEntities can reach into it
  public readonly entityPairCache = new EntityRelationCache();
  
  private authEventHandler = (event: AuthEvent): void => {
    if (event === "SignedOut") {
      this.cache.clear();
      this.entityPairCache.clear();
    }
  };
  
  private readonly authListener = new AuthListener(this.authEventHandler);

  public async getOrganization(organizationId: string): Promise<Maybe<AWSOrganization>> {
    const fetchOrganization = async (): Promise<Maybe<AWSOrganization>> => {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
      const response = await client.query(OrganizationsGetDocument, { organizationId });

      if (response.data.organizationsGet?.id) {
        return new AWSOrganization(
          this,
          {
            id: response.data.organizationsGet.id,
            name: response.data.organizationsGet.name,
            parentOrganizationId: response.data.organizationsGet.organizationId ?? undefined,
            organizationUid: response.data.organizationsGet.organizationUid,
          },
        );
      }
    };
    
    return this.cache.get(organizationId, fetchOrganization);
  }

  public async getUser(userId: string): Promise<Maybe<AWSUser>> {
    const fetchUser = async (): Promise<Maybe<AWSUser>> => {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
      const response = await client.query(UsersGetDocument, { userId });

      if (response.data.usersGet?.id) {
        return new AWSUser(
          this,
          {
            id: response.data.usersGet.id,
            username: response.data.usersGet.name,
            policies: response.data.usersGet.policies,
            homeOrganization: response.data.usersGet.organizationId,
          },
        );
      }
    };
    
    return this.cache.get(userId, fetchUser);
  }

  public async getPolicyGroup(policyGroupId: string): Promise<Maybe<AWSPolicyGroup>> {
    const fetchPolicyGroup = async (): Promise<Maybe<AWSPolicyGroup>> => {
      const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
      const response = await client.query(PolicyGroupsGetDocument, { policyGroupId });

      if (response.data.policyGroupsGet?.id) {
        return new AWSPolicyGroup(
          this,
          {
            id: response.data.policyGroupsGet.id,
            name: response.data.policyGroupsGet.name,
            organization: response.data.policyGroupsGet.organizationId,
            policies: response.data.policyGroupsGet.policies,
          },
        );
      }
    };

    return this.cache.get(policyGroupId, fetchPolicyGroup);
  }

  public async getCurrentHomeOrganization(): Promise<AWSOrganization> {
    const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();

    if (!claims) {
      throw new Error("No authenticated user");
    }
    const organization = await this.getOrganization(claims.homeOrganizationId);

    if (!organization) {
      throw new Error("Could not resolve home organization of current user");
    }
    return organization;
  }
  
  public async getCurrentUser(): Promise<Maybe<AWSUser>> {
    const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();

    if (!claims) {
      return;
    }
    return await this.getUser(claims.userId);
  }

  //
  // IOrganizationBackend implementation ends
  //

  public async createOrganization(owner: AWSOrganization, parameters: CreateOrganizationParameters): Promise<AWSOrganization> {
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
    const response = await client.mutate(
      OrganizationsCreateDocument,
      {
        payload: {
          name: parameters.name,
          parentOrganizationId: owner.getId(),
        },
      },
    );

    if (!response.data?.organizationsCreate) {
      throwGQLError(response, "Failed to create organization");
    }

    const newOrganization = new AWSOrganization(
      this,
      {
        id: response.data.organizationsCreate.id,
        name: response.data.organizationsCreate.name,
        parentOrganizationId: response.data.organizationsCreate.organizationId ?? undefined,
        organizationUid: response.data.organizationsCreate.organizationUid,
      },
    );

    this.cache.set(newOrganization.getId(), newOrganization);
    return newOrganization;
  }

  public async createUser(owner: AWSOrganization, parameters: CreateUserParameters): Promise<AWSUser> {
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
    const response = await client.mutate(
      UsersCreateDocument,
      {
        payload: {
          name: parameters.username.toLowerCase(),
          organizationId: owner.getId(),
          policies: [],
          resendInvitation: parameters.resendInvitation ?? false,
        },
      },
    );

    if (!response.data?.usersCreate) {
      throwGQLError(response, "Failed to create new user");
    }

    const newUser = new AWSUser(
      this,
      {
        id: response.data.usersCreate.id,
        username: response.data.usersCreate.name,
        policies: [],
        homeOrganization: owner,
      });

    this.cache.set(newUser.getId(), newUser);
    return newUser;
  }

  public async createPolicyGroup(_owner: AWSOrganization, _parameters: CreatePolicyGroupParameters): Promise<AWSPolicyGroup> {
    throw new Error("Not implemented");
  }

  public async cleanEntityFromCaches(id: string): Promise<void> {
    const entity = await this.cache.delete(id);

    if (entity) {
      this.entityPairCache.remove(entity as HasEntityRelations);
    }
  }
}
