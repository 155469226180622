/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Service } from "../backend/AppSyncClientProvider";
import EventSet from "./EventSet";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import AuthWrapper from "../auth/authWrapper";
import { Maybe } from "../../types/aliases";
import { EventsFeedDocument } from "../../generated/gqlEvents";

export default class EventsSubscriptionManager {

  private subscribedData: EventSet[] = [];
  private subscriptions: ZenObservable.Subscription[] = [];
  private static instance: EventsSubscriptionManager;

  public static getInstance(): EventsSubscriptionManager {
    if (!EventsSubscriptionManager.instance) {
      EventsSubscriptionManager.instance = new EventsSubscriptionManager();
    }
    return this.instance;
  }

  public subscribeEvents(eventSet: EventSet): void {
    if (this.subscribedData && this.subscribedData.indexOf(eventSet) === -1) {
      this.subscribedData.push(eventSet);

      if (this.subscribedData.length === 1) {
        // TODO: Fix any type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.subscribe().catch((reason: any) => console.error("Failed to subscribe", reason));
      }
    }
  }

  public removeSubscription(dataSet: EventSet): void {
    const observerIndex = this.subscribedData.indexOf(dataSet);

    if (observerIndex !== -1) {
      if (this.subscribedData.length === 1) {
        while (this.subscriptions.length > 0) {
          this.subscriptions.pop()?.unsubscribe();
        }
      }
      this.subscribedData.splice(observerIndex, 1);
    }
  }

  private findEventSet(deviceId: string): Maybe<EventSet> {
    return this.subscribedData.find((eventSet: EventSet) => deviceId === eventSet.getId());
  }

  private async subscribe(): Promise<void> {
    (await AuthWrapper.getCurrentAuthenticatedUserClaims())?.canSee.forEach((canSee: string) =>
      this.subscriptions.push(this.subscribeWithIdentity(canSee)));
  }

  private subscribeWithIdentity(identity: string): ZenObservable.Subscription {
    console.log("Events subs for " + identity);
    const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
    return client.subscribe(
      EventsFeedDocument,
      {
        receiver: identity,
      },
    ).subscribe({
      // TODO: Fix any type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: (error: any): void => {
        if (error.errorMessage === "AMQJS0008I Socket closed.") {
          console.log("Reconnecting socket");
          this.subscribe();
        }
        console.error(error);
      },
      next: (update): void => {
        if (update.data?.eventsFeed) {
          const eventSet = this.findEventSet(update.data.eventsFeed.deviceId);

          if (eventSet) {
            eventSet.setEvent(update.data.eventsFeed);
          }
        }
        console.log(update);
      },
    });
  }
}
