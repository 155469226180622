/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Maybe, Voidable } from "../../types/aliases";
import { MeasurementItemFieldsFragment } from "../../generated/gqlData";

export class DataUtil {
  public static parseDataFragment<TData extends Data = Data>(item: Voidable<MeasurementItemFieldsFragment>, type?: string): Maybe<TData> {
    if (item && item.data) {
      const dataItem: TData = JSON.parse(item.data);
      dataItem.deviceId = item.deviceId;
      dataItem.timestamp = Number(item.timestamp); // Here timestamp gets converted into a number
      dataItem.sessionId = item.sessionId ?? undefined;
      dataItem.type = item.type ?? type;

      if (type && dataItem.type && dataItem.type !== type) {
        console.error("Incorrect data item type");
        return;
      }
      return dataItem;
    }
    console.error("Could not parse data item, missing fields", item);
    return;
  }
}


export type DataValue = number | string | boolean | undefined;

export interface Data {
  deviceId: string;
  timestamp: number;
  sessionId?: string;
  type?: string;

  [key: string]: DataValue;
}
