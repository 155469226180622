/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { List, TextField } from "@material-ui/core";
import React from "react";
import SettingsListItem from "../settings-list-item";
import SettingsControls from "../settings-page-general/settings-controls";
import Device from "../../../../data/device/Device";
import { Attribute } from "../../../../data/device/Attribute";

interface Props {
  selectedDevice: Device;
  onCloseSettings: () => void;
}

interface State {
  attributes: Attribute[];
  changesMade: boolean;
}

const EDITABLE_ATTRIBUTE_PREFIX = "w_";

export default class SettingsPageAttributes extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      attributes: props.selectedDevice.getAttributes(),
      changesMade: false,
    };
  }

  private handleCancel = (): void => {
    this.props.onCloseSettings();
  };

  private handleSave = (): void => {
    const attributes = this.state.attributes
      .filter(item => item.key.startsWith(EDITABLE_ATTRIBUTE_PREFIX));
    this.props.selectedDevice.updateAttributes(attributes);
    this.setState({ changesMade: false });
  };

  private attributeChanged(attribute: Attribute): void {
    const newAttributes = this.state.attributes.map(item => {
      if (item.key === attribute.key) {
        return attribute;
      } else {
        return item;
      }
    });
    this.setState({ attributes: newAttributes, changesMade: true });
  }

  public render(): JSX.Element {
    return (
      <List>
        {this.state.attributes ? this.state.attributes.map(item => {
          if (item.key.startsWith(EDITABLE_ATTRIBUTE_PREFIX)) {
            return (
              <SettingsListItem key={item.key} label={item.key}>
                <TextField
                  value={item.value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                    this.attributeChanged({
                      key: item.key,
                      value: event.target.value,
                    })}
                />
              </SettingsListItem>
            );
          } else {
            return (
              <SettingsListItem
                key={item.key}
                label={item.key}
              >
                {item.value}
              </SettingsListItem>
            );
          }
        }) : <p>No attributes defined</p>}
        <SettingsControls
          changesMade={this.state.changesMade}
          actionButtonText="Save"
          onSave={this.handleSave}
          onCancel={this.handleCancel}/>
      </List>
    );
  }

}
