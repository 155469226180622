/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, Fragment, ReactNode } from "react";
import ErrorDialog from "../../../ui/error-dialog";
import FormDialog from "../../../ui/form-dialog";
import { ListItemText, TextField } from "@material-ui/core";
import Loader from "../../../ui/loader";
import DeviceGroup from "../../../../data/device/DeviceGroup";

interface Props {
  targetGroup: DeviceGroup;
  open: boolean;
  onClose: (success: boolean) => void;
}

interface State {
  loading: boolean;
  deviceId: string;
  errorMessage?: string;
}

export default class AddDevicePopup extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      deviceId: "",
      loading: false,
    };
  }

  private addDeviceToGroup = async (): Promise<void> => {
    if (!this.formIsValid()) {
      throw new Error("Invalid control flow");
    }

    this.setState({ loading: true });

    try {
      await this.props.targetGroup.addDevice(this.state.deviceId!);
      this.props.onClose(true);
    } catch (error) {
      console.error("Failed to add device", error);
      this.setState({
        errorMessage: "Failed to add device",
      });
      this.props.onClose(false);
    } finally {
      this.setState({ loading: false });
    }
  };

  private formIsValid(): boolean {
    return !!this.state.deviceId;
  }

  public render(): ReactNode {
    return (
      <Fragment>
        <ErrorDialog
          onClose={(): void => {
            this.setState({ errorMessage: undefined });
            this.props.onClose(false);
          }}
          errorMsg={this.state.errorMessage}
        />
        <FormDialog
          title="Add new device"
          acceptButtonText="Add"
          open={this.props.open}
          onAccept={this.addDeviceToGroup}
          onCancel={(): void => this.props.onClose(false)}
          disableAccept={!this.formIsValid()}
        >
          <ListItemText id="list-label-new-popup-name" primary="Device identifier" />
          <TextField
            value={this.state.deviceId}
            onChange={(event): void =>
              this.setState({ deviceId: event.currentTarget.value })}
          />
          <Loader show={this.state.loading}/>
        </FormDialog>
      </Fragment>
    );
  }
}

