/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Service } from "../backend/AppSyncClientProvider";
import ClientProperties from "../clientSpecific/ClientProperties";
import Event, { EventState, IEventMetadata } from "../clientSpecific/Event";
import { EventRepositoryListener } from "./EventRepositoryListener";
import AppSyncClientFactory from "../backend/AppSyncClientFactory";
import AuthWrapper from "../auth/authWrapper";
import { Nullable } from "../../types/aliases";
import { EventsFeedDocument, EventsListDocument, EventsMetadataListDocument } from "../../generated/gqlEvents";

export default class EventsRepository {

  private static instance: EventsRepository;

  private events: Event[] = [];
  private eventMetadata: Map<string, IEventMetadata> = new Map<string, IEventMetadata>();
  private subscriptions: ZenObservable.Subscription[] = [];
  private listeners: EventRepositoryListener[] = [];

  public static getInstance(): EventsRepository {
    if (!EventsRepository.instance) {
      EventsRepository.instance = new EventsRepository();
    }
    return EventsRepository.instance;
  }

  public async init(): Promise<void> {
    this.eventMetadata = new Map();
    await this.fetchEventMetadata();
    this.subscribe();
  }

  public uninit(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach((s: ZenObservable.Subscription) => {
        s.unsubscribe();
      });
      this.subscriptions = [];
    }

    if (this.eventMetadata) {
      this.eventMetadata.clear();
    }
  }

  public async getAllActiveEvents(): Promise<Event[]> {
    if (!this.events || this.events.length === 0) {
      const range = ClientProperties.getDefaultEventTimestampRange(ClientProperties.EVENT_AGE_DAYS);
      await this.fetchAllEvents(`${range.start}`, `${range.end}`);
    }
    return this.events.filter((e: Event) => {
      return e.eventState === EventState.Active;
    });
  }

  public getEventDescription(eventId: string): string {
    const description = this.eventMetadata.get(eventId)?.description;
    return description ?? eventId;
  }

  public async fetchAllEvents(startTimestamp: string, endTimestamp?: string): Promise<Event[]> {
    let nextToken: Nullable<string> = null;
    let events: Event[] = [];

    try {
      do {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const eventsResponse = await client.query(
          EventsListDocument,
          {
            startTimestamp,
            endTimestamp: endTimestamp != null ? endTimestamp : `${Date.now()}`,
            nextToken,
          },
        );
        // cast is required or response's type inference becomes cyclic
        nextToken = (eventsResponse.data.eventsList?.nextToken ?? null) as Nullable<string>;
        events = events.concat(eventsResponse.data.eventsList?.events ?? []);
      } while (nextToken);
      this.events = events;
      return events;
    } catch (error) {
      console.error("Error", error);
      return [];
    }
  }

  private async fetchEventMetadata(): Promise<void> {
    let nextToken: Nullable<string> = null;

    try {
      do {
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
        const metadataResponse = await client.query(
          EventsMetadataListDocument,
          {
            nextToken,
          },
        );
        // cast is required or response's type inference becomes cyclic
        nextToken = (metadataResponse.data.eventsMetadataList?.nextToken ?? null) as Nullable<string>;
        metadataResponse.data.eventsMetadataList?.eventMetadataItems.forEach((metadata) => {
          this.eventMetadata.set(metadata.eventId, metadata);
        });
      } while (nextToken);
    } catch (error) {
      console.error("Error", error);
    }
  }

  public addListener(listener: EventRepositoryListener): void {
    const listenerIndex = this.listeners.indexOf(listener);

    if (listenerIndex !== -1) {
      this.removeListenerFromIndex(listenerIndex);
    }
    this.listeners.push(listener);
    console.log("Listener added");
  }

  public removeListener(listener: EventRepositoryListener): void {
    const listenerIndex = this.listeners.indexOf(listener);

    if (listenerIndex !== -1) {
      this.removeListenerFromIndex(listenerIndex);
      console.log("Listener removed");
    }
  }

  private removeListenerFromIndex(index: number): void {
    this.listeners.splice(index, 1);
  }

  private async subscribe(): Promise<void> {
    (await AuthWrapper.getCurrentAuthenticatedUserClaims())?.canSee.forEach((canSee: string) =>
      this.subscriptions.push(this.subscribeWithIdentity(canSee)));
  }

  private subscribeWithIdentity(identity: string): ZenObservable.Subscription {
    console.log("subscribeWithIdentity " + identity);
    const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.EVENTS);
    return appSyncClient.subscribe(
      EventsFeedDocument,
      {
        receiver: identity,
      },
    ).subscribe({
      // TODO: Fix any type
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: (error: any): void => {
        if (error.errorMessage === "AMQJS0008I Socket closed.") {
          console.log("Reconnecting socket");
          this.subscriptions.push(this.subscribeWithIdentity(identity));
        }
        console.error(error);
      },
      next: (update): void => {
        if (update.data?.eventsFeed) {
          const event: Event = update.data.eventsFeed;
          const isNew = this.handleNewEvent(event);
          console.log(`Triggering ${isNew ? "onEvent" : "onEventStateChanged"}`);
          this.listeners.forEach((listener: EventRepositoryListener) => {
            if (isNew) {
              listener.onEvent(event);
            } else {
              listener.onEventStateChanged(event);
            }
          });
        }
      },
    });
  }

  private handleNewEvent(event: Event): boolean {
    const index: number = this.findEventIndex(event);

    if (index >= 0) {
      console.log(JSON.stringify(event));
      this.events[index] = event;
    } else {
      this.events.push(event);
      return true;
    }
    return false;
  }

  private findEventIndex(event: Event): number {
    return this.events.findIndex((e: Event) => {
      return (event.deviceId === e.deviceId &&
        event.eventId === e.eventId &&
        event.timestamp === e.timestamp);
    });
  }
}
