/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import { Button, Dialog, Typography } from "@material-ui/core";
import React, { Component, ReactNode } from "react";
import Loader from "./loader";

interface Props {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
  // if true, show a loading icon in place of buttons
  showLoader?: boolean;
}

export default class ConfirmationDialog extends Component<Props> {

  private renderButtons(): ReactNode {
    return (
      <div className="buttons-container">
        <Button
          variant="contained"
          color="primary"
          className="button left"
          onClick={this.props.onConfirm}
        >
          Confirm
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="button"
          onClick={this.props.onCancel}
        >
          Cancel
        </Button>
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <Dialog
        onClose={this.props.onCancel}
        open={true}
      >
        <div className="popup-inner">
          <Typography variant="h6">{this.props.title}</Typography>
          <div className="popup-body">
            <Typography variant="subtitle1">{this.props.message}?</Typography>
            {!this.props.showLoader ? this.renderButtons() : <Loader />}
          </div>
        </div>
      </Dialog>
    );
  }
}
