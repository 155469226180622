/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import Interaction from "ol/interaction/Interaction";
import Select from "ol/interaction/Select";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import Map from "ol/Map";
import Overlay from "ol/Overlay";
import OSM from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import Icon from "ol/style/Icon";
import Style from "ol/style/Style";
import Text from "ol/style/Text";
import Stroke from "ol/style/Stroke";
import View from "ol/View";
import { events } from "openlayers";
import React, { Component, Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Data } from "../../../data/data/Data";
import Device from "../../../data/device/Device";
import { DrawerState } from "../../../data/utils/utils";
import CloseIcon from "../../../assets/baseline-close-black-24px.svg";
import Marker from "../../../assets/baseline-place-24px.svg";
import StatusPopup from "./status-popup";
import { Maybe, Nullable } from "../../../types/aliases";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const proj = require("ol/proj");

const DEFAULT_CENTER: [number, number] = [25.72088, 62.24147]; // Jyväskylä
const ZOOM_MIN = 2;
const ZOOM_DEFAULT = 6;
const ZOOM_VALUE = 11;
const MARKER_ANCHOR: [number, number] = [0.5, 1];
const OVERLAY_OFFSET: [number, number] = [0, 10];

interface Props extends RouteComponentProps {
  mapData: Data[];
  drawerState: DrawerState;
  devices?: Device[];
  selectedDevice?: Device;
}

interface State {
  zoomNeeded: boolean;
  showAllLabels: boolean;
}

class StatusMap extends Component<Props, State> {
  // REFACTOR: Think of extracting map functionality behind interface so that
  // we can easily swap the map provider.
  private map!: Map;
  private popup: Nullable<Overlay> = null;
  private statusMapPointsLayer: Nullable<VectorLayer> = null;

  public constructor(props: Props) {
    super(props);
    this.state = {
      zoomNeeded: false,
      showAllLabels: false, // false: hide displayNames upon first load
    };
  }

  public componentDidMount(): void {
    this.renderMap();

    if (this.props.mapData.length > 0) {
      this.renderMarkers();
    }

    if (this.props.selectedDevice) {
      this.setState({ zoomNeeded: true });
    }
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    if (this.props.drawerState !== DrawerState.Closed) {
      // Update marker display when devices report changes:
      if (prevProps.mapData !== this.props.mapData) {
        this.renderMarkers();
      // Show or hide marker texts immediately after button press:
      } else if (prevState.showAllLabels !== this.state.showAllLabels) {
        this.renderMarkers();
      }
    }

    if (prevProps.selectedDevice !== this.props.selectedDevice) {
      this.setState({ zoomNeeded: true });
    } else if (this.state.zoomNeeded && this.props.mapData) {
      this.setState({ zoomNeeded: false });
    }

    if (this.props.drawerState !== prevProps.drawerState && this.props.drawerState !== DrawerState.Closed) {
      this.map.updateSize();
    }
  }

  private toggleDeviceNameVisibility = (): void => {
    this.setState({ showAllLabels: !this.state.showAllLabels });
    const nextMode: string = this.state.showAllLabels ? "hidden" : "visible";
    console.log("Hide/show button pressed. Device names will be ", nextMode, "on map.");
  };  

  private getIconLabelFromDeviceNameInfo(deviceNameForMarker: string): Style {
    //Check if marker labels are set visible, and show them only if so: 
    let displayText: string = deviceNameForMarker;

    if (this.state.showAllLabels) {
      displayText = deviceNameForMarker;
    } else {
      displayText = "";
    }

    return new Style({
      image: new Icon({
        anchor: MARKER_ANCHOR,
        src: Marker,
      }),
      text: new Text ({ 
        text: displayText,
        rotation: 0,  // 0: horizontal
        offsetY: 5,  // 5: slightly below marker
        offsetX: 0,  // 0: centered wrt marker
        scale: 1.05,
        stroke: new Stroke({
          color: "#000000",
        }),
      }),
    });
  }

  private renderMarkers(): void {
    const markers: Feature[] = [];
    this.props.mapData.forEach((mapData: Data) => {
      const iconFeatureNewFeature = new Feature({
        geometry: new Point(proj.fromLonLat(
          [mapData.longitude, mapData.latitude])), 
        properties: {
          deviceId: mapData.deviceId,
          timestamp: mapData.timestamp,
        },
        type: "marker",
      });

      // Remove layer to avoid multiple layers on top of each other on each update:
      if (this.statusMapPointsLayer) {
        this.map.removeLayer(this.statusMapPointsLayer);
        this.statusMapPointsLayer = null;
      }

      // Get all info of the devices with location and use it for labels:
      if (mapData && mapData.deviceId && mapData.longitude && mapData.latitude) {
        const iconFeature = iconFeatureNewFeature;
        const allInfoOfDeviceOnMap = this.props.devices?.find((deviceParam: Device) => deviceParam.getId() === mapData.deviceId);
        // Check if device has display name and if not, use deviceId instead for label text:
        const mapDeviceState = allInfoOfDeviceOnMap?.getState();
        const deviceNameInfo: string = mapDeviceState?.displayName ?? mapData.deviceId;
        // setStyle based on displayName or deviceId:
        iconFeature.setStyle(this.getIconLabelFromDeviceNameInfo(deviceNameInfo));
        markers.push(iconFeature);
      }
    });
    this.renderLayer(markers);
  }

  private renderLayer(features: Feature[]): void {
    if (this.map !== null) {
      const vectorSource = new VectorSource({
        features,
      });
      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });
      this.statusMapPointsLayer = vectorLayer;
      this.map.addLayer(vectorLayer);
    }
  }

  private renderMap(): void {
    const tileLayer = new TileLayer({
      source: new OSM(),
    });
    this.map = new Map({
      layers: [tileLayer],
      target: "status-map",
      view: new View({
        center: proj.fromLonLat(DEFAULT_CENTER),
        extent: proj.transformExtent([180, -90, -180, 90], "EPSG:4326", "EPSG:3857"),
        minZoom: ZOOM_MIN,
        zoom: ZOOM_DEFAULT,
      }),
      loadTilesWhileAnimating: true,
      loadTilesWhileInteracting: true,
    });
    const select: Interaction = new Select();
    this.map.addInteraction(select);
    select.on("select", (event: events.Event) => {
      if (event !== null) {
        const feature = event.target.getFeatures().array_[0];

        if (feature) {
          this.openPopup(feature);
        }
      }
    });
    this.initializeOverlay();
  }

  private initializeOverlay = (): void => {
    if (!this.popup) {
      const overlayElement = document.getElementById("status-popup");
      this.popup = new Overlay({
        id: "popup",
        element: overlayElement ?? undefined,
        offset: OVERLAY_OFFSET,
        positioning: "top-center",
        stopEvent: false,
      });
    }
    this.map.addOverlay(this.popup);
  };
  // TODO: Fix any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private openPopup = (feature: any): void => {
    this.props.history.push("/status/" + feature.values_.properties.deviceId);
  };

  private closePopup = (): void => {
    this.props.history.push("/status");
    this.popup?.setPosition(undefined);
  };

  private renderOverlayContent(): Maybe<JSX.Element> {
    if (this.props.selectedDevice && this.props.mapData && this.popup) {
      const statusMapData = this.props.mapData
        .find((mapData: Data) => mapData.deviceId === this.props.selectedDevice?.getId());

      if (statusMapData && statusMapData.longitude != null && statusMapData.latitude != null) {
        const coordinates = proj.fromLonLat([statusMapData.longitude, statusMapData.latitude]);
        this.popup.setPosition(coordinates);

        if (this.state.zoomNeeded) {
          const currentZoomValue = this.map.getView().getZoom();
          const targetZoomValue = currentZoomValue > ZOOM_VALUE ? currentZoomValue : ZOOM_VALUE;
          this.map.getView().animate({ center: coordinates }, { zoom: targetZoomValue });
        }
        this.popup.getElement().className = "";
        return (
          <Fragment>
            <img className="close-icon" alt="close" src={CloseIcon} onClick={this.closePopup} />
            <StatusPopup
              selectedDevice={this.props.selectedDevice}
              popupData={statusMapData}
            />
          </Fragment>
        );
      } else {
        this.popup.setPosition(undefined);
        this.popup.getElement().className = "hidden";
      }
    }
  }

  public render(): JSX.Element {
    const buttonText = this.state.showAllLabels ? "Hide Display names" : "Show Display names";
    return (
      <Fragment>
        <div id="status-map">
          <div id="status-button">
            <button onClick={this.toggleDeviceNameVisibility}> {buttonText} </button>
          </div>
          <div id="status-popup" className="hidden">
            {this.renderOverlayContent()}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(StatusMap);
