/*
* Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
*
* NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
* All dissemination, usage, modification, copying, reproduction, selling and distribution of the
* software and its intellectual and technical concepts are strictly forbidden without a valid license.
* Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
* (https://sadeinnovations.com).
*/

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TreeView from "@material-ui/lab/TreeView";
import React, { Component, Fragment, ReactNode } from "react";
import DeviceGroup from "../../data/device/DeviceGroup";
import ScrollDrag from "../ui/scroll-drag";
import ErrorDialog from "../ui/error-dialog";
import Loader from "../ui/loader";
import { Box } from "@material-ui/core";
import { Maybe } from "../../types/aliases";
import DeviceTreeLevel from "./components/device-tree-level";
import SearchFilter from "./helpers/search-filter";

interface Props {
  editMode: boolean;
  groups?: DeviceGroup[];
  searchFilter?: SearchFilter;
}

interface State {
  errorMsg?: string;
  expandedItemIds: string[];
  selectedItemId?: string;
}

class DeviceTree extends Component<Props, State> {
  private static storedExpandedItems: string[] = [];

  public constructor(props: Props) {
    super(props);
    this.state = {
      expandedItemIds: DeviceTree.storedExpandedItems,
    };
  }
  
  private handleNodeToggle = (_event: unknown, expandedNodeIds: string[]): void => {
    DeviceTree.storedExpandedItems = expandedNodeIds;
    this.setState({ expandedItemIds: expandedNodeIds });
  };

  private handleCloseErrorNote = (): void => {
    this.setState({ errorMsg: undefined });
  };

  private renderLevels(): Maybe<ReactNode> {
    if (!this.props.groups?.length) {
      return (<Box m={2}>No groups found</Box>);
    }
    return this.props.groups.map((group: DeviceGroup) => (
      <DeviceTreeLevel
        key={group.getId()}
        group={group}
        editMode={this.props.editMode}
        searchFilter={this.props.searchFilter}
      />
    ));
  }
  
  private renderTreeView(): JSX.Element {
    return (
      <TreeView
        expanded={this.state.expandedItemIds}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        onNodeToggle={this.handleNodeToggle}
      >
        {this.renderLevels()}
      </TreeView>
    );
  }

  private renderContents(): JSX.Element {
    if (this.props.editMode) {
      return (
        <ScrollDrag rootClass={"tree-list"}>
          {this.renderTreeView()}
        </ScrollDrag>
      );
    } else {
      return this.renderTreeView();
    }
  }

  public render(): JSX.Element {
    if (!this.props.groups) {
      return <Loader/>;
    }
    return (
      <Fragment>
        <ErrorDialog errorMsg={this.state.errorMsg} onClose={this.handleCloseErrorNote} />
        {this.renderContents()}
      </Fragment>
    );
  }
}

export default DeviceTree;
